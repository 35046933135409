import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgComment = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      d="M4.312 15a.693.693 0 0 0 .392-.116c.121-.072.269-.188.443-.347l2.62-2.385h4.908c.721 0 1.329-.128 1.822-.383a2.533 2.533 0 0 0 1.118-1.12c.257-.492.385-1.092.385-1.8V3.31c0-.709-.128-1.309-.385-1.8A2.533 2.533 0 0 0 14.497.39C14.004.13 13.397 0 12.675 0h-9.35c-.721 0-1.329.13-1.822.39A2.574 2.574 0 0 0 .377 1.51C.126 2.003 0 2.603 0 3.31v5.538c0 .709.126 1.309.377 1.8.257.492.632.865 1.126 1.12.493.256 1.1.384 1.822.384h.341v2.103c0 .227.056.408.167.543.111.134.271.202.48.202ZM4.145 4.02a.44.44 0 0 1-.312-.124.436.436 0 0 1-.123-.31c0-.12.04-.222.123-.304a.44.44 0 0 1 .312-.123h7.615c.121 0 .223.041.305.123a.4.4 0 0 1 .131.304c0 .12-.043.224-.13.31a.415.415 0 0 1-.306.123H4.145Zm0 2.421a.42.42 0 0 1-.436-.434c0-.115.042-.217.124-.303a.427.427 0 0 1 .312-.13h7.615c.121 0 .223.043.305.13.088.086.131.188.131.303a.408.408 0 0 1-.13.311.415.415 0 0 1-.306.123H4.145Zm0 2.429a.44.44 0 0 1-.312-.123.412.412 0 0 1-.123-.304.42.42 0 0 1 .123-.31.427.427 0 0 1 .312-.13h4.951c.121 0 .223.043.305.13.087.081.13.185.13.31a.4.4 0 0 1-.13.304.415.415 0 0 1-.305.123h-4.95Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgComment);
export default ForwardRef;
