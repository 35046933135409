// Docs: https://github.com/WorkpathHQ/workpath_web/wiki/Container-Actions-Docs
import {
  GET_GOAL_PROGRESS_OVER_TIME_REQUEST,
  GET_GOAL_PROGRESS_OVER_TIME_SUCCESS,
  GET_GOAL_PROGRESS_OVER_TIME_ERROR,
} from './constants';

export function getGoalProgressOverTimeRequest(goalId) {
  return {
    type: GET_GOAL_PROGRESS_OVER_TIME_REQUEST,
    payload: {
      goalId,
    },
  };
}

export function getGoalProgressOverTimeSuccess(response) {
  return {
    type: GET_GOAL_PROGRESS_OVER_TIME_SUCCESS,
    payload: response,
  };
}
export function getGoalProgressOverTimeError(error) {
  return {
    type: GET_GOAL_PROGRESS_OVER_TIME_ERROR,
    payload: error,
    error: true,
  };
}
