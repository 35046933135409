import {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest,
  getRequestV2,
} from '../../methods';

const getKeyResult = keyResultId => getRequestV2(`key_results/${keyResultId}`);

// Goal Key Results
const addParentKeyResult = (goalId, payload) =>
  postRequest(`goals/${goalId}/key_result_parent_relations/`, payload);
const removeParentKeyResult = (goalId, parentId) =>
  deleteRequest(`goals/${goalId}/key_result_parent_relations/${parentId}`);
const createKeyResult = (goalId, payload) =>
  postRequest(`goals/${goalId}/key_results`, payload);
const updateKeyResult = (goalId, keyResultId, payload) =>
  patchRequest(`goals/${goalId}/key_results/${keyResultId}`, payload);
const deleteKeyResult = (goalId, keyResultId) =>
  deleteRequest(`goals/${goalId}/key_results/${keyResultId}`);

const updateKeyResultPosition = (goalId, keyResultId, payload) =>
  patchRequest(`goals/${goalId}/key_results/${keyResultId}/position`, payload);

const getKeyResultTeamMemberReportAnswers = keyResultId =>
  getRequest(`key_results/${keyResultId}/answers`);

const createKeyResultStatusUpdate = payload =>
  postRequest('key_result_status_updates', payload);

const createMilestone = (keyResultId, payload) =>
  postRequest(`key_results/${keyResultId}/milestones`, payload);

const updateMilestone = (keyResultId, milestoneId, payload) =>
  patchRequest(`key_results/${keyResultId}/milestones/${milestoneId}`, payload);

const deleteMilestone = (keyResultId, milestoneId) =>
  deleteRequest(`key_results/${keyResultId}/milestones/${milestoneId}`);

const keyResults = {
  getKeyResult,
  addParentKeyResult,
  removeParentKeyResult,
  createKeyResult,
  updateKeyResult,
  deleteKeyResult,
  updateKeyResultPosition,
  getKeyResultTeamMemberReportAnswers,
  createKeyResultStatusUpdate,
  createMilestone,
  updateMilestone,
  deleteMilestone,
};

export default keyResults;
