import { defineMessages } from 'react-intl';

export default defineMessages({
  clickToUpdate: {
    id: 'baukasten.DataGrid.common.Tooltip.clickToUpdate',
    defaultMessage: 'Click to update',
  },
  showWarning: {
    id: 'baukasten.DataGrid.common.Tooltip.showWarning',
    defaultMessage:
      'This confidence level is unusually high as the progress is below 30% and less than half time is remaining. Are you still confident to reach this?',
  },
  aggregatedProgressAndCL: {
    id: 'baukasten.DataGrid.common.Tooltip.aggregatedProgressAndCL',
    defaultMessage:
      'Calculated based on the average of all Key Results and/or supporting Goals',
  },
});
