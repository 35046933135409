const AUTH_TOKEN = 'AUTH_TOKEN';

// Token
function getToken() {
  return localStorage.getItem(AUTH_TOKEN);
}

function storeToken(token) {
  localStorage.setItem(AUTH_TOKEN, token);
}

function removeToken() {
  localStorage.removeItem(AUTH_TOKEN);
}

// Login state
function isLoggedIn() {
  return !!getToken();
}

function isLoggedOut() {
  return !isLoggedIn();
}

// Router helpers
function requireAuth(nextState, replace) {
  if (!isLoggedIn()) {
    replace({
      pathname: '/login',
      state: {
        nextPathname: nextState.location.pathname + nextState.location.search,
      },
    });
  }
}

function requireNoAuth(nextState, replace) {
  if (isLoggedIn()) {
    replace('/');
  }
}

const auth = {
  getToken,
  storeToken,
  removeToken,

  isLoggedIn,
  isLoggedOut,

  requireAuth,
  requireNoAuth,
};

export default auth;
