import React from 'react';
import { formatValueHuman } from 'utils/formatValue';

import { Kpi } from 'types/KPI';
import { Locale } from 'types/Locale';
import { Team } from 'types/Team';

import { Size } from '../index';

import { SharedListItemLayout } from './components/SharedListItemLayout';

type KpiListItemProps = {
  kpi: Kpi;
  navigateTo: (path: string) => void;
  size: Size;
  showEntitySymbol: boolean;
  showOwnerAvatar: boolean;
  locale: Locale;
  padding: number;
  onClick?: () => void;
};

const KpiListItem = ({
  kpi,
  navigateTo,
  size,
  showEntitySymbol,
  showOwnerAvatar,
  locale,
  padding,
  onClick,
}: KpiListItemProps) => {
  const kpiCurrentValue =
    kpi.type.name === 'percental'
      ? kpi.kpiActual.value / 100
      : kpi.kpiActual.value;

  const details = formatValueHuman(
    locale,
    kpi.type.name,
    kpiCurrentValue,
    kpi.type.currency
  );

  const defaultOnClick = () => {
    const urlsByKind = {
      team: `/goals/overview/teams/${kpi.team?.id}?selectedKpiId=${kpi.id}#kpis`,
      organization: `/goals/overview/organization?selectedKpiId=${kpi.id}#kpis`,
    };

    navigateTo(urlsByKind[kpi.kind]);
  };

  return (
    <SharedListItemLayout
      entityType="kpi"
      showEntitySymbol={showEntitySymbol}
      showOwnerAvatar={showOwnerAvatar}
      ownerKind={kpi.kind}
      owner={kpi.team as Team}
      padding={padding}
      size={size}
      title={kpi.title}
      details={details}
      onClick={onClick || defaultOnClick}
    />
  );
};

export { KpiListItem };
