import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgKeyResult = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path d="M3 8h2.143v5H3V8zm4-3h2v8H7V5zm4-3h2v11h-2V2z" fill={_.color} />
  </svg>
);
const ForwardRef = forwardRef(SvgKeyResult);
export default ForwardRef;
