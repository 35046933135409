import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgHelpSolid = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 1c3.863 0 7 3.137 7 7s-3.137 7-7 7-7-3.137-7-7 3.137-7 7-7Zm-.906 10.043a.894.894 0 0 0 1.786 0 .894.894 0 0 0-1.786 0ZM8.1 4.081c-1.512 0-2.383 1.271-2.383 1.89 0 .275.15.71.642.71.378 0 .63-.274.642-.515.034-.24.286-.791 1.054-.791.55 0 1.054.39 1.054.87 0 .963-1.88 1.04-1.88 2.461 0 .481.333.825.745.825a.763.763 0 0 0 .768-.78c0-.77 1.81-.89 1.81-2.54 0-1.203-1.111-2.13-2.452-2.13Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgHelpSolid);
export default ForwardRef;
