import React from 'react';
import styled from 'styled-components';
import { useTransition, animated } from 'react-spring';

import { Box } from '../../../Box';

const ModalBackground = styled(animated(Box))`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: #33333380;
`;

const ModalPositionWrapper = styled(animated(Box))`
  transform-origin: center;
`;

type AnimatedModalWrapperProps = {
  isModalOpen: boolean;
  children: React.ReactNode;
  ariaLabel: string;
};

const AnimatedModalMountWrapper = ({ children, isModalOpen, ariaLabel }: AnimatedModalWrapperProps) => {
  const transitions = useTransition(isModalOpen, {
    from: { opacity: 0.8, transform: 'scale(0.80)' },
    enter: { opacity: 1, transform: 'scale(1)' },
    leave: { opacity: 0, transform: 'scale(0.8)' },
    config: {
      mass: 0.6,
      tension: 260,
      friction: 24,
    },
    reverse: isModalOpen,
  });

  return transitions(
    (styles, item) =>
      item && (
        <ModalBackground
          width="100%"
          height="100%"
          style={{
            opacity: styles.opacity,
          }}
        >
          <ModalPositionWrapper
            aria-label={ariaLabel || 'modal'}
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
            style={{
              transform: styles.transform,
            }}
          >
            {children}
          </ModalPositionWrapper>
        </ModalBackground>
      )
  );
};

export { AnimatedModalMountWrapper };
