import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgVideo = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m5.556 13.81 7.085-5.133c.412-.299.48-.845.15-1.219a.915.915 0 0 0-.15-.135L5.556 2.19c-.413-.3-1.016-.238-1.346.135a.817.817 0 0 0-.21.542v10.266c0 .479.429.867.958.867.217 0 .428-.067.598-.19Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgVideo);
export default ForwardRef;
