import { defineMessages } from 'react-intl';

export default defineMessages({
  trialExpiredNotice: {
    id: 'app.containers.NonAuthenticatedAppLayout.trialExpiredNotice',
    defaultMessage:
      'Your free trial of Workpath has expired. To continue using Workpath, please contact {email} to upgrade to one of our plans.',
  },
  subscriptionExpiredNotice: {
    id: 'app.containers.NonAuthenticatedAppLayout.subscriptionExpiredNotice',
    defaultMessage:
      'Your subscription of Workpath has expired. To continue using Workpath, please contact {email} to extend your subscription.',
  },
  help: {
    id: 'app.containers.NonAuthenticatedAppLayout.help',
    defaultMessage: 'Help',
  },
  privacy: {
    id: 'app.containers.NonAuthenticatedAppLayout.privacy',
    defaultMessage: 'Privacy Policy',
  },
  secondaryContentTitle: {
    id: 'app.containers.NonAuthenticatedAppLayout.secondaryContentTitle',
    defaultMessage: 'Draft great OKRs with ease in the new Drafting Module',
  },
  secondaryContentDescription: {
    id: 'app.containers.NonAuthenticatedAppLayout.secondaryContentDescription',
    defaultMessage:
      'Based on a proven process, key questions and phrasing suggestions the new Drafting Module assists you in drafting outcome-oriented Goals with measurable Key Results. Using the Drafting Module, some teams have reduced drafting time by 40%. Explore it by clicking "Draft Goals" on your Team Page.',
  },
  secondaryContentCTA: {
    id: 'app.containers.NonAuthenticatedAppLayout.secondaryContentCTA',
    defaultMessage: 'Learn more about the new Drafting Module',
  },
});
