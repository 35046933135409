import { Goal, Initiative, KeyResult, RowSummaryEntity } from '../types';

export const transformSelectedRow = (
  selectedEntity: RowSummaryEntity
): Goal | Initiative | KeyResult => {
  if (selectedEntity?.entityType === 'key_result') {
    return {
      entityType: 'key_result',
      id: selectedEntity.keyResultId,
      data: {
        title: {
          entityType: 'key_result_title',
          value: selectedEntity.title,
          url: '',
        },
        owner: selectedEntity.owner,
        team: {
          entityType: 'team',
          id: null,
          name: '',
          url: '',
        },
        progress: selectedEntity.progress,
        confidenceLevel: selectedEntity.confidenceLevel,
        lastUpdated: {
          entityType: 'key_result_last_updated',
          value: '',
          showWarning: false,
          permissions: { canEdit: false },
        },
        actions: {
          entityType: 'key_result_actions',
          permissions: { canEdit: false },
          entityId: selectedEntity.keyResultId,
          // this field isn't used in the rowSummary, but as it's based on
          // the DataGrid actions type I have to set it to _something_.
          initiativesFeature: false,
        },
      },
      subRows: selectedEntity.subRows,
    };
  }

  if (selectedEntity?.entityType === 'initiative') {
    return {
      entityType: 'initiative',
      id: selectedEntity.initiativeId,
      data: {
        title: {
          entityType: 'initiative_title',
          value: selectedEntity.title,
          url: '',
        },
        owner: selectedEntity.owner,
        team: {
          entityType: 'team',
          id: null,
          name: '',
          url: '',
        },
        progress: selectedEntity.progress,
        confidenceLevel: { entityType: 'initiative_confidence_level' },
        lastUpdated: {
          entityType: 'initiative_last_updated',
          value: '',
          showWarning: false,
          permissions: { canEdit: false },
        },
        actions: {
          entityType: 'initiative_actions',
          entityId: selectedEntity.initiativeId,
          // this field isn't used in the rowSummary, but as it's based on
          // the DataGrid actions type I have to set it to _something_.
          initiativesFeature: false,
        },
      },
    };
  }

  if (selectedEntity?.entityType === 'aligned_goal') {
    return {
      entityType: 'goal',
      id: selectedEntity.goalId,
      data: {
        title: {
          entityType: 'goal_title',
          value: selectedEntity.title,
          url: '',
        },
        owner: selectedEntity.owner,
        team: selectedEntity.team,
        progress: selectedEntity.progress,
        confidenceLevel: selectedEntity.confidenceLevel,
        lastUpdated: {
          entityType: 'goal_last_updated',
          value: '',
          showWarning: false,
          permissions: { canEdit: false },
        },
        actions: {
          entityType: 'goal_actions',
          permissions: { canEdit: false },
          entityId: selectedEntity.goalId,
          // this field isn't used in the rowSummary, but as it's based on
          // the DataGrid actions type I have to set it to _something_.
          initiativesFeature: false,
        },
      },
      subRows: selectedEntity.subRows,
    };
  }

  return {
    entityType: 'goal',
    id: selectedEntity.goalId,
    data: {
      title: {
        entityType: 'goal_title',
        value: selectedEntity.title,
        url: '',
      },
      owner: selectedEntity.owner,
      team: selectedEntity.team,
      progress: selectedEntity.progress,
      confidenceLevel: selectedEntity.confidenceLevel,
      lastUpdated: {
        entityType: 'goal_last_updated',
        value: '',
        showWarning: false,
        permissions: { canEdit: false },
      },
      actions: {
        entityType: 'goal_actions',
        permissions: { canEdit: false },
        entityId: selectedEntity.goalId,
        // this field isn't used in the rowSummary, but as it's based on
        // the DataGrid actions type I have to set it to _something_.
        initiativesFeature: false,
      },
    },
    subRows: selectedEntity.subRows,
  };
};
