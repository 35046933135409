import React, { ComponentProps } from 'react';

import { Tooltip } from '../Tooltip';
import { Badge } from '../Badge';
import { Box } from '../Box';
import { getBaseUnit } from '../styles/themeGetters';
import { MoreBadges } from './MoreBadges';

const Badges = ({
  badges,
  truncateAfter,
}: {
  badges: ComponentProps<typeof Badge>[];
  truncateAfter?: number;
}) => {
  const MAX_BADGE_CHARS = 14;

  const truncate = (badgeLabel: string) => {
    if (badgeLabel.length > MAX_BADGE_CHARS) {
      return `${badgeLabel.slice(0, MAX_BADGE_CHARS)}…`;
    }

    return badgeLabel;
  };

  const visibleBadges = badges.slice(0, truncateAfter);
  const overflowBadges = badges.slice(truncateAfter);

  return (
    <Box display="flex" height="22px" flexGap={getBaseUnit(2)}>
      {visibleBadges.map(badge =>
        badge.label.length > MAX_BADGE_CHARS ? (
          <Tooltip key={badge.label} tooltipContent={badge.label}>
            <Badge {...badge} label={truncate(badge.label)} />
          </Tooltip>
        ) : (
          <Badge {...badge} label={truncate(badge.label)} />
        )
      )}
      {overflowBadges.length > 0 ? (
        <MoreBadges badges={overflowBadges} />
      ) : null}
    </Box>
  );
};

export { Badges };
