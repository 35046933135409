import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgHelpOutline = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.548 8a6.452 6.452 0 1 1 12.904 0A6.452 6.452 0 0 1 1.548 8ZM8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0Zm-.035 4C6.4 4 5.5 5.295 5.5 5.925c0 .28.155.723.664.723.391 0 .652-.28.663-.525.036-.245.296-.805 1.09-.805.569 0 1.09.396 1.09.886 0 .443-.396.702-.83.986-.528.344-1.113.726-1.113 1.52 0 .49.344.84.77.84.45 0 .794-.35.794-.794 0-.318.308-.527.675-.776.536-.364 1.197-.812 1.197-1.81C10.5 4.945 9.35 4 7.965 4Zm-1.042 7.09c0 .502.414.91.924.91s.924-.408.924-.91a.918.918 0 0 0-.924-.91.918.918 0 0 0-.924.91Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgHelpOutline);
export default ForwardRef;
