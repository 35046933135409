import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgGoal = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 12.5a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgGoal);
export default ForwardRef;
