import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgFunnel = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      d="M13.686 1H2.314C1.148 1 .56 2.415 1.386 3.24L5.812 7.67v4.706c0 .413.195.802.526 1.094l1.75 1.287c.855.597 2.1.042 2.1-1.05V7.669l4.426-4.428c.825-.825.24-2.241-.928-2.241ZM8.875 7.125v6.563l-1.75-1.313v-5.25L2.312 2.312h11.376L8.875 7.125Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgFunnel);
export default ForwardRef;
