import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgPending = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      d="M14.735 6.302c.106-.08.265-.005.265.122v5.326c0 .69-.588 1.25-1.313 1.25H2.313C1.587 13 1 12.44 1 11.75V6.427c0-.13.156-.203.265-.122.613.453 1.425 1.028 4.214 2.958.577.401 1.55 1.245 2.521 1.24.976.007 1.969-.855 2.524-1.24 2.789-1.93 3.598-2.508 4.21-2.96ZM8 9.667c.634.01 1.548-.76 2.007-1.078 3.629-2.508 3.905-2.727 4.741-3.352A.613.613 0 0 0 15 4.745V4.25C15 3.56 14.412 3 13.687 3H2.313C1.587 3 1 3.56 1 4.25v.495c0 .192.093.372.252.492.836.622 1.112.844 4.741 3.352.46.317 1.373 1.088 2.007 1.078Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgPending);
export default ForwardRef;
