import React from 'react';
import styled, { keyframes } from 'styled-components';

import { getBaseUnit, getColor } from '../../styles/themeGetters';

const loadingIndicatorAnimation = keyframes`
  0% {
    transform: scale(0);
  }
  80%,
  100% {
   transform: scale(1);
  }
`;

const StyledButtonLoadingIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${getBaseUnit(1)};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

type StyledAnimatedCircle = {
  animationDelay?: number;
  circleColor: string;
  size: 's' | 'l';
};

type ButtonLoadingIndicatorProps = {
  color: 'primary' | 'danger';
  variant: 'contained' | 'outlined' | 'text';
  size?: 's' | 'l';
};

const circleColorMap = {
  primary: {
    contained: getColor('white'),
    outlined: getColor('grey'),
    text: getColor('grey'),
  },
  danger: {
    contained: getColor('white'),
    outlined: getColor('red-light'),
    text: getColor('red-light'),
  },
};

const sizeMap = {
  l: {
    width: '6px',
    height: '6px',
  },
  s: {
    width: '4px',
    height: '4px',
  },
};

const StyledAnimatedCycle = styled.div<StyledAnimatedCircle>`
  background-color: ${({ circleColor }) => circleColor};
  border-radius: 50%;
  width: ${({ size }) => sizeMap[size].width};
  height: ${({ size }) => sizeMap[size].height};
  animation: ${loadingIndicatorAnimation} 0.5s infinite ease-in-out alternate;
  animation-delay: ${({ animationDelay }) => `${animationDelay}s`};
`;

const ButtonLoadingIndicator = ({
  color,
  size,
  variant,
}: ButtonLoadingIndicatorProps) => {
  return (
    <StyledButtonLoadingIndicator>
      <StyledAnimatedCycle
        circleColor={circleColorMap[color][variant]}
        size={size}
      />
      <StyledAnimatedCycle
        animationDelay={0.2}
        circleColor={circleColorMap[color][variant]}
        size={size}
      />
      <StyledAnimatedCycle
        animationDelay={0.3}
        circleColor={circleColorMap[color][variant]}
        size={size}
      />
    </StyledButtonLoadingIndicator>
  );
};

export { ButtonLoadingIndicator };
