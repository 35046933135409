/* eslint-disable no-underscore-dangle */

const _getOptionsForNumeric = () => ({ maximumFractionDigits: 1 });

const _getHumanOptionsForNumeric = () => ({
  notation: 'compact',
  maximumFractionDigits: 2,
});

const _getOptionsForPercent = () => ({
  style: 'percent',
  maximumFractionDigits: 2,
});

const _getHumanOptionsForPercent = () => ({
  notation: 'compact',
  style: 'percent',
  maximumFractionDigits: 2,
});

const _getOptionsForCurrency = currency => ({
  style: 'currency',
  currency,
  currencyDisplay: 'narrowSymbol',
});

const _getHumanOptionsForCurrency = currency => ({
  notation: 'compact',
  style: 'currency',
  currency,
  currencyDisplay: 'narrowSymbol',
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

const formatValue = (locale, type, value, currency = 'EUR') => {
  let options = {};

  if (type === 'numeric') {
    options = _getOptionsForNumeric();
  }

  if (type === 'percental' || type === 'percentalFloat') {
    options = _getOptionsForPercent();
  }

  if (type === 'monetary') {
    options = _getOptionsForCurrency(currency);
  }

  return Intl.NumberFormat(locale, options).format(value);
};

const formatValueHuman = (locale, type, value, currency = 'EUR') => {
  let options = {};

  if (type === 'numeric') {
    options = _getHumanOptionsForNumeric();
  }

  if (type === 'percental' || type === 'percentalFloat') {
    options = _getHumanOptionsForPercent();
  }

  if (type === 'monetary') {
    options = _getHumanOptionsForCurrency(currency);
  }

  return Intl.NumberFormat(locale, options).format(value);
};

export { formatValue, formatValueHuman };
