import { createSelector } from 'reselect';

/**
 * Direct selector to the ToastNotificationContainer state domain
 */
const selectToastNotificationContainerDomain = state => state.toastNotification;

/**
 * Other specific selectors
 */
const selectNotifications = () =>
  createSelector(
    selectToastNotificationContainerDomain,
    substate => substate.notifications
  );

export { selectToastNotificationContainerDomain, selectNotifications };
