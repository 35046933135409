import React from 'react';

import { Team } from 'types/Team';
import { User } from 'types/User';

import { TeamAvatar } from '../TeamAvatar';
import { UserAvatar } from '../UserAvatar';

/**
 * This component is a wrapper around UserAvatar and TeamAvatar.
 * It is used to display either a user or a team avatar. It will
 * display a user avatar if the user prop is passed, and a team
 * avatar if the team prop is passed.
 * @param user The user to display the avatar for.
 * @param team The team to display the avatar for.
 * @param size The size of the avatar.
 * @param onClick The function to call when the avatar is clicked.
 * @returns The rendered component.
 * @example
 * <UserOrTeamAvatar user={user} size="l" onClick={handleClick} />
 * <UserOrTeamAvatar team={team} size="l" onClick={handleClick} />
 */

export type UserOrTeamAvatarProps = {
  user?: User;
  team?: Team;
  size: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
  onClick?: () => void;
  hasPresence?: boolean;
};

const UserOrTeamAvatar = ({
  user,
  team,
  size,
  onClick,
  hasPresence,
}: UserOrTeamAvatarProps) => {
  if (team) {
    return <TeamAvatar team={team} size={size} onClick={onClick} />;
  }

  return (
    <UserAvatar
      user={user}
      hasPresence={hasPresence}
      size={size}
      onClick={onClick}
    />
  );
};

export { UserOrTeamAvatar };
