import { CurrentUser } from 'types/CurrentUser';
import { UserFeatureFlagName } from 'types/UserFeatureFlags';
import { accessUserFeatureFlags, Awaitable } from './util';
import { useCurrentUser } from './baseState/useCurrentUser';

type AwaitableIsViewer = Awaitable<boolean>;
type AwaitableUserFeatureFlag = Awaitable<boolean>;

const useCurrentUserAwaitableWithTransform: <TData>(
  transform: (t: CurrentUser) => TData
) => Awaitable<TData> = transform => {
  const currentUser = useCurrentUser();
  if (currentUser) {
    return { loading: false, data: transform(currentUser) };
  }
  return { loading: true };
};

export const useIsCurrentUserViewerAwaitable = (): AwaitableIsViewer =>
  useCurrentUserAwaitableWithTransform(u => u.viewer);

export const useIsCurrentUserAnalystAwaitable = (): AwaitableIsViewer =>
  useCurrentUserAwaitableWithTransform(u => u.analyst);

export const useHasUserFeatureAwaitable = (
  ff: UserFeatureFlagName
): AwaitableUserFeatureFlag =>
  useCurrentUserAwaitableWithTransform(u =>
    accessUserFeatureFlags(u).includes(ff)
  );

export const useIsCurrentUserViewer = (): boolean => {
  const awaitable = useIsCurrentUserViewerAwaitable();
  return awaitable.loading === false && awaitable.data;
};

export const useIsCurrentUserRegularUser = (): boolean => {
  const awaitable = useIsCurrentUserViewerAwaitable();
  return awaitable.loading === false && !awaitable.data;
};

export const useIsCurrentUserAnalyst = () => {
  const awaitable = useIsCurrentUserAnalystAwaitable();
  return awaitable.loading === false && awaitable.data;
};

export const useHasUserFeature = (ff: UserFeatureFlagName) => {
  const awaitable = useHasUserFeatureAwaitable(ff);
  return awaitable.loading === false && awaitable.data;
};
