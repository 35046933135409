import { defineMessages } from 'react-intl';

export default defineMessages({
  info: {
    id: 'app.containers.ToastNotificationContainer.info',
    defaultMessage: 'Info',
  },
  success: {
    id: 'app.containers.ToastNotificationContainer.success',
    defaultMessage: 'Success',
  },
  warning: {
    id: 'app.containers.ToastNotificationContainer.warning',
    defaultMessage: 'Warning',
  },
  error: {
    id: 'app.containers.ToastNotificationContainer.error',
    defaultMessage: 'Error',
  },
});
