import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgReportIssueOutline = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.24 13.175v.193l1.563-1.39.455-.403H12.605c.54 0 .885-.097 1.102-.21.226-.119.376-.27.485-.472.114-.214.208-.542.208-1.048V4.336c0-.508-.095-.831-.206-1.037l-.003-.006a1.1 1.1 0 0 0-.487-.478l-.005-.003c-.214-.114-.556-.212-1.094-.212h-9.21c-.535 0-.881.097-1.104.213a1.109 1.109 0 0 0-.482.48l-.003.006c-.11.206-.206.53-.206 1.037v5.51c0 .5.093.828.205 1.042.118.212.271.364.487.48.225.116.557.207 1.052.207h1.897v1.6Zm7.365 0c.727 0 1.341-.13 1.842-.39.506-.266.89-.644 1.156-1.137.265-.497.397-1.098.397-1.803V4.336c0-.705-.132-1.303-.397-1.796a2.698 2.698 0 0 0-1.156-1.142c-.5-.265-1.115-.398-1.842-.398h-9.21c-.727 0-1.344.133-1.85.398-.5.265-.883.646-1.148 1.142C.132 3.033 0 3.631 0 4.336v5.51c0 .704.132 1.305.397 1.802.27.493.653.871 1.149 1.136.5.26 1.1.39 1.798.39h.297v2.06c0 .231.057.416.173.553.12.142.291.213.513.213.15 0 .289-.038.419-.114.13-.07.286-.189.47-.354l2.65-2.357h4.739ZM7.827 3h.29c.468 0 .842.523.827.912l-.117 3.134c-.015.372-.38.666-.826.666-.447 0-.812-.294-.827-.666L7 3.912c-.016-.39.358-.912.826-.912ZM7 9.778c0 .556.448 1.008 1 1.008s1-.452 1-1.008C9 9.22 8.552 8.77 8 8.77s-1 .45-1 1.008Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgReportIssueOutline);
export default ForwardRef;
