import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgUpdates = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      d="M3 6h2.143v7H3V6Zm4-3h2v10H7V3Zm4 5.714h2V13h-2V8.714Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgUpdates);
export default ForwardRef;
