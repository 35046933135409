import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { Theme } from 'baukasten';

import { hideText } from 'utils/style/helpers';

const darkBg = 'rgba(0, 0, 0, 0.5)';
const lightBg = 'rgba(255, 255, 255, 0.5)';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  border-radius: 50%;
  display: inline-block;

  width: 1em;
  height: 1em;

  border-top: 0.11em solid ${props => (props.dark ? darkBg : lightBg)};
  border-right: 0.11em solid ${props => (props.dark ? darkBg : lightBg)};
  border-bottom: 0.11em solid ${props => (props.dark ? darkBg : lightBg)};
  border-left: 0.11em solid
    ${props => (props.dark ? Theme.getColor('navy') : '#fff')};

  animation: ${spin} 1.1s infinite linear;
  ${hideText}
`;

Spinner.propTypes = {
  dark: PropTypes.bool,
};

export default Spinner;
