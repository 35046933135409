const colorsPrimary = {
  'navy-dark': '#1C1E31',
  navy: '#363953',
};

const colorsSecondary = {
  'yellow-dark': '#EFC92D',
  yellow: '#FADA51',
  'yellow-light': '#F7E9C1',
  'blue-dark': '#1A78CA',
  blue: '#48BCFE',
  'blue-light': '#C9E3FB',
  'pink-dark': '#BF0246',
  pink: '#D81159',
  'pink-light': '#FBD8E2',
};

const colorsAccent = {
  'orange-dark': '#CC6600',
  orange: '#F5954D',
  'orange-light': '#FFEBD6',
  'red-dark': '#C6331D',
  red: '#D9513C',
  'red-light': '#FFCAC1',
  'green-dark': '#4D7D6C',
  green: '#669C89',
  'green-light': '#D9ECE5',
  'lilac-dark': '#684DF5',
  lilac: '#9683FA',
  'lilac-light': '#E5E0FF',
};

const colorsGreyscale = {
  black: '#000000',
  'grey-dark': '#838895',
  grey: '#C2C5CE',
  'grey-light-100': '#E8EBF2',
  'grey-light-50': '#F3F4F7',
  'grey-light-25': '#F9F9FB',
  white: '#FFFFFF',
};

const colors = {
  ...colorsPrimary,
  ...colorsSecondary,
  ...colorsAccent,
  ...colorsGreyscale,
};

const elevations = {
  1: '1px 1px 2px rgba(64, 64, 64, 0.2)',
  2: '2px 2px 4px rgba(64, 64, 64, 0.2)',
  3: '4px 4px 8px rgba(64, 64, 64, 0.2)',
  4: '6px 6px 12px rgba(64, 64, 64, 0.2)',
  5: '12px 12px 24px rgba(64, 64, 64, 0.2)',
};

const fontSizes = {
  10: '10px',
  12: '12px',
  14: '14px',
  16: '16px',
  20: '20px',
  24: '24px',
  32: '32px',
  40: '40px',
};

const lineHeights = {
  10: '16px',
  12: '16px',
  14: '20px',
  16: '24px',
  20: '24px',
  24: '32px',
  32: '40px',
  40: '56px',
};

const fontWeights = {
  regular: 400,
  semibold: 600,
  bold: 700,
};

const fontFamilies = {
  lato:
    '"Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
};

const baseUnit = 4;

const themes = {
  name: 'default',
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  elevations,
  baseUnit,
};

type ElevationKeys = keyof typeof elevations;
type ColorKeys = keyof typeof colors;
type FontSizeKeys = keyof typeof fontSizes;
type LineHeightKeys = keyof typeof lineHeights;
type FontWeightKeys = keyof typeof fontWeights;
type FontFamilyKeys = keyof typeof fontFamilies;

export {
  themes,
  ColorKeys,
  FontSizeKeys,
  LineHeightKeys,
  FontWeightKeys,
  FontFamilyKeys,
  ElevationKeys,
};
