import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgInitiative = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <circle cx={8} cy={8} r={3} fill={_.color} />
  </svg>
);
const ForwardRef = forwardRef(SvgInitiative);
export default ForwardRef;
