import { createContext } from 'react';

type BaukastenContextValues = {
  navigateTo?: (url: string) => void;
};

const initialState: BaukastenContextValues = {};

/**
 * This context is for setting configs and functions from outside to Baukasten.
 * By setting these values we can have easy access to those values across the package.
 * i.e this will be used for passing navigate function. This function is necessary for Link to handle internal navigation
 */

export const BaukastenContext = createContext<BaukastenContextValues>(
  initialState
);
