import { DEFAULT_LOCALE } from '../constants';
import { appLocales } from '../../../i18n';

function localeFromBrowser() {
  const locales = browserLocales();

  /* eslint-disable no-plusplus */
  for (let index = 0; index < locales.length; ++index) {
    if (appLocales.includes(locales[index])) {
      return locales[index];
    }
  }

  return DEFAULT_LOCALE;
}

function browserLocales() {
  const found = [];
  if (typeof navigator !== 'undefined') {
    if (navigator.languages) {
      // chrome only; not an array, so can't use .push.apply instead of iterating
      for (let i = 0; i < navigator.languages.length; i++) {
        found.push(navigator.languages[i]);
      }
    }
    if (navigator.userLanguage) {
      found.push(navigator.userLanguage);
    }
    if (navigator.language) {
      found.push(navigator.language);
    }
  }

  return found.map(locale => locale.substr(0, 2));
}

export default localeFromBrowser;
