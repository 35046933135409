import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgLogin = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.829 0A2.834 2.834 0 0 0 0 2.839V13.16A2.834 2.834 0 0 0 2.829 16H9v-1.548H2.829c-.71 0-1.286-.578-1.286-1.29V2.838c0-.713.575-1.29 1.286-1.29H9V0H2.829Zm3.916 8.388h6.536l-2.097 2.2a.727.727 0 1 0 1.052 1.003L16 7.643l-3.764-3.948a.727.727 0 0 0-1.052 1.003l2.097 2.2H6.745a.745.745 0 0 0 0 1.49Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgLogin);
export default ForwardRef;
