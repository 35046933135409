import {
  getRequest,
  postRequest,
  // patchRequest,
  // deleteRequest,
} from '../../methods';

const getRecommendedActions = () => getRequest(`recommended_actions`);

const getRecommendedActionsSnooze = (recommendedActionId, days) =>
  postRequest(
    `recommended_actions/${recommendedActionId}/snooze?days=${days}`,
    {}
  );

const getRecommendedActionsSkip = recommendedActionId =>
  postRequest(`recommended_actions/${recommendedActionId}/skip`, {});

const recommendedActions = {
  getRecommendedActions,
  getRecommendedActionsSnooze,
  getRecommendedActionsSkip,
};

export default recommendedActions;
