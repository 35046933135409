import React from 'react';
import { InputControl } from '../components/InputControl';
import { MultiselectProps } from './types';
import { RawMultiSelect } from './RawMultiSelect';

function Multiselect({
  error,
  helpText,
  label,
  condensed,
  ...props
}: MultiselectProps) {
  return (
    <InputControl
      condensed={condensed}
      error={error}
      helpText={helpText}
      label={label}
    >
      <RawMultiSelect {...props} />
    </InputControl>
  );
}
export { Multiselect };
