import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgArrowLeft = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      d="M7.471 13.471a.748.748 0 0 0 1.058-1.057L4.872 8.75h8.378a.75.75 0 0 0 0-1.5H4.872l3.66-3.653a.753.753 0 1 0-1.064-1.065L2 8l5.471 5.471Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgArrowLeft);
export default ForwardRef;
