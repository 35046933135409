import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgSend = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      d="M1.267 14.73a.768.768 0 0 0 .648.268c.26-.014.55-.1.867-.257l11.274-5.677c.153-.079.288-.16.405-.242.121-.078.223-.164.306-.258A.838.838 0 0 0 15 8c0-.2-.078-.389-.233-.564a1.557 1.557 0 0 0-.306-.258 3.526 3.526 0 0 0-.405-.242L2.72 1.233A2.032 2.032 0 0 0 1.915 1a.768.768 0 0 0-.648.27c-.22.247-.303.51-.253.79.054.275.16.582.32.919l1.791 3.784c.105.225.212.389.32.489.11.097.266.15.467.161l10.111.387c.057 0 .1.02.129.06.035.039.054.085.057.139a.186.186 0 0 1-.048.14.176.176 0 0 1-.138.059l-10.111.43a.708.708 0 0 0-.453.156c-.108.093-.22.254-.334.484L1.367 12.96a4.18 4.18 0 0 0-.343.957c-.06.29.02.56.243.812Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgSend);
export default ForwardRef;
