import { AvatarWithId, User } from './types';

export const transformUserToAvatar: (user: User) => AvatarWithId = user => ({
  id: user.id,
  fallbackText: `${user.firstName} ${user.lastName}`,
  image: user.avatarUrlMedium,
  type: 'person',
  hasPresence: user.hasPresence,
  tooltipText: user.tooltipText,
  status: user.deactivated ? 'archived' : undefined,
});
