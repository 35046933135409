import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgSearch = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      d="M1 6.718c0 .791.149 1.534.447 2.227a5.85 5.85 0 0 0 3.083 3.048c.7.296 1.446.443 2.24.443a5.57 5.57 0 0 0 1.687-.253 6.039 6.039 0 0 0 1.489-.71l3.254 3.225a.96.96 0 0 0 .347.225 1.124 1.124 0 0 0 .95-.063.967.967 0 0 0 .368-.373c.09-.159.135-.337.135-.534 0-.14-.026-.274-.078-.4a.864.864 0 0 0-.22-.33l-3.232-3.218c.335-.458.598-.967.787-1.524a5.33 5.33 0 0 0 .29-1.763 5.44 5.44 0 0 0-.453-2.213 5.732 5.732 0 0 0-1.24-1.826A5.73 5.73 0 0 0 6.77 1a5.704 5.704 0 0 0-4.076 1.679 5.827 5.827 0 0 0-1.247 1.826A5.514 5.514 0 0 0 1 6.718Zm1.51 0c0-.58.109-1.126.326-1.637a4.292 4.292 0 0 1 2.282-2.255 4.159 4.159 0 0 1 1.652-.33c.59 0 1.144.11 1.659.33.515.216.966.518 1.354.907.392.388.7.838.921 1.348.222.51.333 1.056.333 1.637 0 .585-.11 1.133-.333 1.644a4.292 4.292 0 0 1-2.275 2.255c-.515.22-1.068.33-1.659.33-.586 0-1.136-.11-1.652-.33a4.395 4.395 0 0 1-1.36-.907 4.292 4.292 0 0 1-.922-1.348 4.151 4.151 0 0 1-.326-1.644Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgSearch);
export default ForwardRef;
