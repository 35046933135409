import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgDelete = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      d="M3.714 12.556A1.44 1.44 0 0 0 5.143 14h5.714a1.44 1.44 0 0 0 1.429-1.444V3.889H3.714v8.667Zm1.429-7.223h5.714v7.223H5.143V5.333Zm5.357-3.61L9.786 1H6.214l-.714.722H3v1.445h10V1.722h-2.5Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgDelete);
export default ForwardRef;
