import React from 'react';
import { ColumnConfig } from '../../types';

import { OwnerRenderer } from '../OwnerRenderer';
import { TeamRenderer } from '../TeamRenderer';
import { ConfidenceLevelRenderer } from '../ConfidenceLevelRenderer';
import { LastUpdateRenderer } from '../LastUpdateRenderer';
import { ProgressIndicatorRenderer } from '../ProgressIndicatorRenderer';
import { TitleRenderer } from '../TitleRenderer';
import { ActionsRenderer } from '../ActionsRenderer';
import { TD } from '../../../Table';
import { Skeleton } from '../../../Skeleton';

export const DynamicLoadingRenderer = ({ col }: { col: ColumnConfig }) => {
  switch (col.key) {
    case 'expander':
      return (
        <TD pl={16} pr={16} width={28}>
          <Skeleton width="18px" height="18px" />
        </TD>
      );
    case 'title':
      return <TitleRenderer config={col} loadingState="loading" />;
    case 'owner':
      return <OwnerRenderer loadingState="loading" config={col} />;

    case 'team':
      return <TeamRenderer config={col} loadingState="loading" />;

    case 'progress':
      return <ProgressIndicatorRenderer config={col} loadingState="loading" />;
    case 'confidenceLevel':
      return <ConfidenceLevelRenderer config={col} loadingState="loading" />;
    case 'lastUpdated':
      return <LastUpdateRenderer config={col} loadingState="loading" />;
    case 'actions':
      return <ActionsRenderer config={col} loadingState="loading" />;
    default: {
      // this is to make a missing Renderer a type error. see
      // https://www.typescriptlang.org/docs/handbook/2/narrowing.html#exhaustiveness-checking
      // for how this works.
      const _exhaustiveCheck: never = col;
      return _exhaustiveCheck;
    }
  }
};
