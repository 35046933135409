import React from 'react';
import { Box, IconBox, Icons, Text, Theme } from 'baukasten';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import WorkpathLogo from 'components/WorkpathLogo';
import Link from 'components/Link';

import messages from '../messages';

const IconWrapper = styled(IconBox)`
  display: inline-block;
  vertical-align: middle;
`;

export const SecondaryContent = () => {
  const { formatMessage } = useIntl();

  return (
    <Box
      pl={16}
      pr={16}
      pt={12}
      pb={12}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      style={{ flexGrow: 1 }}
    >
      <Box mb={8}>
        <Link to="/">
          <WorkpathLogo color="white" />
        </Link>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        style={{ flexGrow: 1 }}
      >
        <Text color="white" fontSize={32}>
          {formatMessage(messages.secondaryContentTitle)}
        </Text>

        <Box mt={8} display="flex" justifyContent="center">
          <img
            src="https://workpath-platform-static.s3.eu-central-1.amazonaws.com/product-marketing/feature-promo-drafting-module.png"
            alt="schematic of the drafting module"
            style={{ maxWidth: '100%', maxHeight: '275px' }}
          />
        </Box>

        <Text color="white" fontSize={16} mt={8}>
          {formatMessage(messages.secondaryContentDescription)}
        </Text>

        <Link
          href="https://workpath.zendesk.com/hc/en-us/articles/6194382343068"
          target="_blank"
          aria-label="open link to further information"
          color={Theme.getColor('yellow')}
        >
          <Text color="yellow" fontSize={16} fontWeight="bold" mt={4} as="div">
            {formatMessage(messages.secondaryContentCTA)}
            <IconWrapper>
              <Icons.ExternalLink color="yellow" />
            </IconWrapper>
          </Text>
        </Link>
      </Box>
    </Box>
  );
};

export default SecondaryContent;
