import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';

import { getColor, getElevations, getBaseUnit } from '../styles/themeGetters';

const StyledBox = styled.div`
  box-sizing: border-box;
  margin: 0;
  margin-top: ${({ mt }) => getBaseUnit(mt)};
  margin-bottom: ${({ mb }) => getBaseUnit(mb)};
  margin-right: ${({ mr }) => getBaseUnit(mr)};
  margin-left: ${({ ml }) => getBaseUnit(ml)};

  /* Style */
  background-color: ${({ backgroundColor }) => getColor(backgroundColor)};
  border-top: ${({ borderPosition }) =>
    borderPosition && borderPosition.includes('top') && '1px solid'};
  border-bottom: ${({ borderPosition }) =>
    borderPosition && borderPosition.includes('bottom') && '1px solid'};
  border-left: ${({ borderPosition }) =>
    borderPosition && borderPosition.includes('left') && '1px solid'};
  border-right: ${({ borderPosition }) =>
    borderPosition && borderPosition.includes('right') && '1px solid'};
  border: ${({ borderPosition }) =>
    borderPosition && borderPosition.includes('all') && '1px solid'};

  border-color: ${({ borderPosition, borderColor }) =>
    borderPosition && getColor(borderColor)};

  border-radius: ${({ round }) => round && '4px'};
  /* Dimensions */
  height: ${({ height }) => height};
  min-height: ${({ minHeight }) => minHeight};

  width: ${({ width }) => width};
  /* max-width: 100%; */

  /* Flex Layout  */
  display: ${({ display }) => display};
  flex-direction: ${({ flexDirection }) => flexDirection};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  gap: ${({ flexGap }) => flexGap};

  /* Multiline Behavior */
  flex-wrap: ${({ flexWrap }) => flexWrap};
  align-content: ${({ flexAlignContent }) => flexAlignContent};

  /* Children Flex Layout */
  flex: ${({ flex }) => flex};
  align-self: ${({ alignSelf }) => alignSelf};

  /* Elevations */
  box-shadow: ${({ elevation }) => getElevations(elevation)};

  padding-top: ${({ pt }) => getBaseUnit(pt)};
  padding-right: ${({ pr }) => getBaseUnit(pr)};
  padding-bottom: ${({ pb }) => getBaseUnit(pb)};
  padding-left: ${({ pl }) => getBaseUnit(pl)};

  /* Grid */
  grid-area: ${({ gridArea }) => gridArea};
`;

const Box = ({
  alignItems,
  alignSelf,
  as,
  borderColor,
  borderPosition,
  children,
  backgroundColor,
  display,
  elevation,
  flex,
  flexAlignContent,
  flexDirection,
  flexWrap,
  flexGap,
  height,
  justifyContent,
  round,
  width,
  pt,
  pr,
  pb,
  pl,
  mt,
  mb,
  mr,
  ml,
  gridArea,
  ...rest
}) => (
  <StyledBox
    alignItems={alignItems}
    alignSelf={alignSelf}
    as={as}
    borderColor={borderColor}
    borderPosition={borderPosition}
    backgroundColor={backgroundColor}
    display={display}
    elevation={elevation}
    flex={flex}
    flexAlignContent={flexAlignContent}
    flexDirection={flexDirection}
    flexWrap={flexWrap}
    flexGap={flexGap}
    height={height}
    justifyContent={justifyContent}
    round={round}
    mt={mt}
    mb={mb}
    mr={mr}
    ml={ml}
    pt={pt}
    pr={pr}
    pb={pb}
    pl={pl}
    width={width}
    gridArea={gridArea}
    {...rest}
  >
    {children}
  </StyledBox>
);

Box.propTypes = {
  alignItems: PropTypes.string,
  alignSelf: PropTypes.string,
  as: PropTypes.string,
  borderColor: PropTypes.string,
  children: PropTypes.node.isRequired,
  backgroundColor: PropTypes.string,
  display: PropTypes.string,
  elevation: PropTypes.oneOf([1, 2, 3, 4, 5]),
  flex: PropTypes.string,
  flexAlignContent: PropTypes.string,
  flexDirection: PropTypes.string,
  flexWrap: PropTypes.string,
  flexGap: PropTypes.string,
  height: PropTypes.string,
  justifyContent: PropTypes.string,
  mt: PropTypes.number,
  mb: PropTypes.number,
  mr: PropTypes.number,
  ml: PropTypes.number,
  pt: PropTypes.number,
  pr: PropTypes.number,
  pb: PropTypes.number,
  pl: PropTypes.number,
  round: PropTypes.bool,
  width: PropTypes.string,
  gridArea: PropTypes.string,
  borderPosition: PropTypes.arrayOf(
    PropTypes.oneOf(['top', 'right', 'left', 'bottom', 'all'])
  ),
};

Box.defaultProps = {
  mt: 0,
  mr: 0,
  mb: 0,
  ml: 0,
  pt: 0,
  pr: 0,
  pb: 0,
  pl: 0,
  borderColor: 'gyscale-1000',
};

export { Box };
