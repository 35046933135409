import { ComponentProps } from 'react';
import styled, { css } from 'styled-components';
import { Box } from '../../Box';
import { getColor, getBaseUnit } from '../../styles/themeGetters';

type HoverBoxProps = {
  applyHoverEffect: boolean;
  pt?: number;
  pb?: number;
  pr?: number;
  pl?: number;
} & ComponentProps<typeof Box>;

export const HoverBox = styled(Box)<HoverBoxProps>`
  padding-top: ${({ pt }) => `${getBaseUnit(pt)}`};
  padding-bottom: ${({ pb }) => `${getBaseUnit(pb)}`};
  padding-left: ${({ pl }) => `${getBaseUnit(pl)}`};
  padding-right: ${({ pr }) => `${getBaseUnit(pr)}`};
  border-radius: 4px;

  ${props =>
    props.applyHoverEffect &&
    css`
      &:hover {
        background-color: ${getColor('grey-light-50')};
      }
    `}
`;

HoverBox.defaultProps = {
  pt: 2,
  pb: 2,
  pr: 4,
  pl: 4,
};
