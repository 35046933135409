import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgSolidChevronLeft = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.963 4.127 5.24 7.549a.542.542 0 0 0-.1.812c.03.033.063.064.1.09l4.723 3.422c.275.2.677.16.897-.09.09-.102.14-.23.14-.361V4.578c0-.32-.286-.578-.638-.578a.681.681 0 0 0-.4.127Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgSolidChevronLeft);
export default ForwardRef;
