import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Box, Text } from 'baukasten';

import { getSubdomain } from 'utils/browser';
import { errorMessage } from 'utils/form';
import NonAuthenticatedAppLayout from 'containers/NonAuthenticatedAppLayout';
import Alert from 'components/Alert';
import Link from 'components/Link';

import messages from './messages';

const ErrorState = ({ getAccountError, getProfileError, javascriptError }) => {
  let error;
  const errorHelp = (
    <FormattedMessage
      {...messages.errorHelp}
      values={{
        reloading: (
          <Link href={window.location.href}>
            <FormattedMessage {...messages.errorHelpReloading} />
          </Link>
        ),
        email: (
          <Link href="mailto:support@workpath.com">support@workpath.com</Link>
        ),
      }}
    />
  );

  if (javascriptError || getAccountError || getProfileError) {
    let message;

    if (javascriptError) {
      message = messages.javascriptError;
    } else if (getAccountError) {
      message = messages.accountError;
    } else if (getProfileError) {
      message = messages.profileError;
    }

    error = (
      <>
        <Text fontSize={16} color="navy">
          <FormattedMessage {...message} />
        </Text>

        <Text fontSize={16} color="navy" mt={4}>
          {errorHelp}
        </Text>

        <Box mt={2}>
          <Alert kind="danger">
            {errorMessage(
              javascriptError || getAccountError || getProfileError
            )}
          </Alert>

          <Box mt={2}>
            <Text fontSize={12} color="grey-dark">
              <FormattedMessage
                {...messages.debugInformation}
                values={{
                  subdomain: getSubdomain(),
                  userAgent: navigator.userAgent,
                }}
              />
            </Text>
          </Box>
        </Box>
      </>
    );
  }

  return (
    <NonAuthenticatedAppLayout hideSecondaryContent>
      <Text fontSize={32} fontWeight="bold" color="navy">
        <FormattedMessage {...messages.heading} />
      </Text>

      <Box mt={6}>{error}</Box>
    </NonAuthenticatedAppLayout>
  );
};

ErrorState.propTypes = {
  getAccountError: PropTypes.object,
  getProfileError: PropTypes.object,
  javascriptError: PropTypes.object,
};

export default ErrorState;
