const noop = () => {};
const pendoService = () => window.pendo || noop;

const openPendoViewerModal = () => {
  // checking if the Agent is loaded and initialised
  if (pendoService()?.isReady()) {
    // manually triggering the Viewer User modal using its ID
    pendoService().showGuideById('gO20YKvSIMgx-q0RCOnY9XvqXeI');
  }
};

export { pendoService, openPendoViewerModal };
