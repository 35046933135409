import React, { ComponentProps, useRef, useState } from 'react';

import styled from 'styled-components';

import { Badge } from 'baukasten';
import { useClickOutside } from '../utils/useClickOutside';
import { Menu } from '../Menu';
import { getColor } from '../styles/themeGetters';

const MoreBadgesContainer = styled.div`
  position: relative;
  color: ${getColor('blue-dark')};
  cursor: pointer;
  font-size: 14px;
  margin-top: 2px;
`;

const MenuContainer = styled.div`
  position: absolute;
  left: -135px;
  top: 25px;
`;

const BadgesCountWrapper = styled.div`
  user-select: none;
`;

const BadgesCount = ({ badgesCount }: { badgesCount: number }) => (
  <div>+{badgesCount} more</div>
);

const MoreBadges = ({ badges }: { badges: ComponentProps<typeof Badge>[] }) => {
  const anchorEl = useRef(null);
  const menuRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useClickOutside({
    targetRef: menuRef,
    prioritizeAnchor: true,
    onClickOutside: () => setIsMenuOpen(false),
  });

  if (!badges.length) {
    return null;
  }

  return (
    <MoreBadgesContainer ref={menuRef}>
      <BadgesCountWrapper
        ref={anchorEl}
        onClick={() => setIsMenuOpen(isMenuOpen => !isMenuOpen)}
      >
        <BadgesCount badgesCount={badges.length} />
      </BadgesCountWrapper>

      <MenuContainer>
        <Menu
          open={isMenuOpen}
          anchorEl={anchorEl}
          onClose={() => setIsMenuOpen(false)}
        >
          {badges.map(badge => (
            <Menu.Item
              key={badge.label}
              aria-label="cell action menu item"
              onSelect={() => setIsMenuOpen(false)}
              color="primary"
            >
              {badge.label}
            </Menu.Item>
          ))}
        </Menu>
      </MenuContainer>
    </MoreBadgesContainer>
  );
};

export { MoreBadges };
