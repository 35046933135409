import styled from 'styled-components';
import { colors } from 'utils/style';
import AlertLink from 'components/AlertLink';

const kinds = {
  success: {
    alertBackgroundColor: colors.stateSuccessBg,
    alertBorderColor: colors.stateSuccessBorder,
    alertColor: colors.stateSuccessText,
    linkColor: colors.stateSuccessText,
  },
  warning: {
    alertBackgroundColor: colors.stateWarningBg,
    alertBorderColor: colors.stateWarningBorder,
    alertColor: colors.stateWarningText,
    linkColor: colors.stateWarningText,
  },
  danger: {
    alertBackgroundColor: colors.stateDangerBg,
    alertBorderColor: colors.stateDangerBorder,
    alertColor: colors.stateDangerText,
    linkColor: colors.stateDangerText,
  },
  info: {
    alertBackgroundColor: colors.stateInfoBg,
    alertBorderColor: colors.stateInfoBorder,
    alertColor: colors.stateInfoText,
    linkColor: colors.stateInfoText,
  },
};

const Wrapper = styled.div`
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;

  font-size: 14px;
  line-height: 1.5;

  background-color: ${({ kind }) => kinds[kind].alertBackgroundColor};
  border-color: ${({ kind }) => kinds[kind].alertBorderColor};
  color: ${({ kind }) => kinds[kind].alertColor};

  ${AlertLink} {
    color: ${({ kind }) => kinds[kind].linkColor};
  }
`;

export default Wrapper;
