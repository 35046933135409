import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgFormatItalic = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      d="M3.772 15h6.1c.232 0 .419-.065.56-.196a.711.711 0 0 0 .222-.548.684.684 0 0 0-.212-.529c-.142-.13-.328-.196-.56-.196H7.72L10.066 2.47h2.162a.78.78 0 0 0 .55-.196.711.711 0 0 0 .222-.549.684.684 0 0 0-.212-.528.78.78 0 0 0-.55-.196h-6.13a.78.78 0 0 0-.55.196.684.684 0 0 0-.212.528c0 .229.07.412.212.549.148.13.335.196.56.196H8.28L5.934 13.532H3.763a.78.78 0 0 0-.55.195.684.684 0 0 0-.213.529c0 .228.07.411.212.548.148.13.335.196.56.196Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgFormatItalic);
export default ForwardRef;
