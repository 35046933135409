import React, { ReactNode } from 'react';
import { createClient, fetchExchange, Provider } from 'urql';
import { urlForGraphQL } from 'utils/api/lib/url';
import { fetchWithHeaders } from 'utils/datagrid/client/fetchWithHeaders';

type GraphQLClientProviderProps = {
  children: ReactNode;
};

export const GraphQLClientProvider = ({
  children,
}: GraphQLClientProviderProps) => {
  const client = createClient({
    url: urlForGraphQL(),
    fetch: fetchWithHeaders,
    exchanges: [fetchExchange],
  });
  return <Provider value={client}>{children}</Provider>;
};

export const internals = {
  fetchWithHeaders,
};
