import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgPlus = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.228 1.778A.78.78 0 0 1 8.006 1c.43 0 .778.348.777.777L8.78 7.222h5.442A.78.78 0 0 1 15 8c0 .43-.347.778-.777.778H8.778l-.004 5.444a.78.78 0 0 1-.778.778.776.776 0 0 1-.777-.777l.004-5.445H1.779A.78.78 0 0 1 1 8c0-.43.347-.778.777-.778h5.447l.004-5.444Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgPlus);
export default ForwardRef;
