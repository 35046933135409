import React, { ReactNode, useContext } from 'react';

import { TabContext } from '../context/TabContext';

type TabPanelProps = {
  // currentIndex is necessary for Tab but it's passed implicitely
  // via cloneElement from Tabs.tsx. In order to prevent TS errors
  // because of missing explicit prop I set currentIndex to optional
  currentIndex?: number;
} & (
  | {
      // either children and no content
      children: ReactNode;
      content?: never;
    }
  | {
      // or content and no children
      content: () => ReactNode;
      children?: never;
    }
);

const TabPanel = ({ currentIndex, ...props }: TabPanelProps) => {
  const { selectedTab } = useContext(TabContext);
  const isSelected = selectedTab === currentIndex;

  const content = props.children || (isSelected ? props.content() : null);
  return (
    <div
      id={`tabpanel-${currentIndex}`}
      // inline style because for that level of complexity styled components is overkill.
      style={{ display: isSelected ? 'block' : 'none' }}
      role="tabpanel"
      tabIndex={0}
    >
      {content}
    </div>
  );
};

// Necessary for `Tabs` to check for presence of TabPanel
TabPanel.displayName = 'TabPanel';

export { TabPanel };
