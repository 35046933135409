import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/Link';

const TruncatedLink = ({ linkComponent, children, ...rest }) => {
  const Component = linkComponent || Link;
  let linkText = children;

  if (linkText.length > 35) {
    linkText = `${linkText.substr(0, 20)}...${linkText.substr(
      linkText.length - 10,
      linkText.length
    )}`;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Component {...rest}>{linkText}</Component>;
};

TruncatedLink.propTypes = {
  children: PropTypes.string.isRequired,
  linkComponent: PropTypes.func,
};

export default TruncatedLink;
