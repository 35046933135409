import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgOrganization = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      d="M10.957 15c.108-.175.19-.363.244-.565a2.19 2.19 0 0 0 .089-.63V4.23h3.589c.344 0 .616.11.815.328.204.215.306.504.306.867v8.379c0 .363-.102.652-.306.867-.2.219-.471.328-.816.328h-3.921Zm1.876-7.447c0 .158.079.237.237.237H14.2c.158 0 .238-.079.238-.237v-1.05c0-.153-.08-.23-.238-.23H13.07c-.158 0-.237.077-.237.23v1.05Zm0 2.588c0 .153.079.23.237.23H14.2c.158 0 .238-.077.238-.23V9.09c0-.154-.08-.23-.238-.23H13.07c-.158 0-.237.076-.237.23v1.05Zm0 2.587c0 .153.079.23.237.23H14.2c.158 0 .238-.077.238-.23v-1.05c0-.158-.08-.237-.238-.237H13.07c-.158 0-.237.079-.237.236v1.051ZM0 13.805V2.195c0-.368.1-.659.3-.873C.502 1.107.78 1 1.127 1H9.23c.35 0 .623.107.823.322.204.214.305.505.305.873v11.61c0 .363-.101.652-.305.867-.2.219-.474.328-.823.328H1.128c-.349 0-.625-.11-.829-.328-.2-.215-.299-.504-.299-.867Zm2.828.138h4.764v-2.134a.466.466 0 0 0-.143-.361.5.5 0 0 0-.373-.138H3.344a.515.515 0 0 0-.374.137.466.466 0 0 0-.142.362v2.134Zm-.225-9.417c0 .189.093.283.279.283h1.36c.194 0 .292-.094.292-.283V3.252c0-.188-.098-.282-.293-.282h-1.36c-.185 0-.278.094-.278.282v1.274Zm3.283 0c0 .189.095.283.286.283h1.352c.195 0 .292-.094.292-.283V3.252c0-.188-.097-.282-.292-.282H6.172c-.19 0-.286.094-.286.282v1.274Zm-3.283 2.68c0 .188.093.282.279.282h1.36c.194 0 .292-.094.292-.283V5.932c0-.189-.098-.283-.293-.283h-1.36c-.185 0-.278.094-.278.283v1.273Zm3.283 0c0 .188.095.282.286.282h1.352c.195 0 .292-.094.292-.283V5.932c0-.189-.097-.283-.292-.283H6.172c-.19 0-.286.094-.286.283v1.273ZM2.603 9.884c0 .188.093.282.279.282h1.36c.194 0 .292-.094.292-.282V8.61c0-.189-.098-.283-.293-.283h-1.36c-.185 0-.278.094-.278.283v1.274Zm3.283 0c0 .188.095.282.286.282h1.352c.195 0 .292-.094.292-.282V8.61c0-.189-.097-.283-.292-.283H6.172c-.19 0-.286.094-.286.283v1.274Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgOrganization);
export default ForwardRef;
