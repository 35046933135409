import {
  themes,
  ColorKeys,
  FontSizeKeys,
  FontFamilyKeys,
  LineHeightKeys,
  FontWeightKeys,
  ElevationKeys,
} from './themes';

const getColor = (color: ColorKeys): string => themes.colors[color];
const getFontFamily = (fontFamily: FontFamilyKeys): string =>
  themes.fontFamilies[fontFamily];
const getFontSize = (fontSize: FontSizeKeys): string =>
  themes.fontSizes[fontSize];
const getLineHeight = (lineHeight: LineHeightKeys): string =>
  themes.lineHeights[lineHeight];
const getFontWeight = (fontWeight: FontWeightKeys): number =>
  themes.fontWeights[fontWeight];
const getBaseUnit = (multiplier: number): string =>
  `${themes.baseUnit * multiplier}px`;
const getElevations = (elevation: ElevationKeys) =>
  themes.elevations[elevation];

export {
  getColor,
  getFontSize,
  getLineHeight,
  getBaseUnit,
  getFontWeight,
  getFontFamily,
  getElevations,
};
