// this util helps find the correct API endpoint for searching KPIs
const getSearchKpisEndpoint = (kind, teamId, query) => {
  if (!kind) {
    return `kpis/search?query=${query}`;
  }
  if (kind && kind === 'organization') {
    return `kpis/search?kind=organization${query ? `&query=${query}` : ''}`;
  }
  return `kpis/search?kind=team&team_id=${teamId}${
    query ? `&query=${query}` : ''
  }`;
};

export { getSearchKpisEndpoint };
