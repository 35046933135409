import React, {
  ComponentProps,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { Tooltip } from '../Tooltip';
import { Text } from '../Text';

type WrapperProps = ComponentProps<typeof Text> &
  Pick<TruncateProps, 'maxWidth' | 'maxLines'>;

const TruncatedText = styled(Text)<WrapperProps>`
  -webkit-line-clamp: ${({ maxLines }) => maxLines || null};
  -webkit-box-orient: ${({ maxLines }) => (maxLines ? 'vertical' : null)};
  display: ${({ maxLines }) => (maxLines ? '-webkit-box' : 'block')};
  -webkit-line-clamp: ${({ maxLines }) => maxLines || null};
  -webkit-box-orient: ${({ maxLines }) => (maxLines ? 'vertical' : null)};
  white-space: ${({ maxLines }) => (maxLines ? null : 'nowrap')};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TooltipTarget = styled.span``;

type TruncateProps = {
  tooltipPlacement?: ComponentProps<typeof Tooltip>['placement'];
  tooltipMaxWidth?: string;
  maxWidth?: string;
  maxLines?: number;
  textProps?: Partial<ComponentProps<typeof Text>>;
  text: string;
};

/**
 * The Truncate component can be used to automatically truncate a given `text` depending on a given `maxWidth`.
 *
 * If the text would overflow the specified space, it is shortened with ellipses (…) and on hover a tooltip is shown
 * displaying the full text.
 *
 * To render multiple lines of text and truncate after that, pass the desired number of lines as `maxLines`.
 *
 * To control the tooltips placement, use `toolTipPlacement` just as for the Tooltip Component
 *
 * To control the look of the truncateable text, you can pass `textProps` - they will be applied to the rendered text.
 *
 * Set a `tooltipMaxWidth` to control the width of text rendered in the tooltip.
 */
const Truncate = ({
  maxWidth,
  text,
  tooltipPlacement = 'top',
  maxLines,
  textProps = {},
  tooltipMaxWidth,
}: TruncateProps) => {
  const ref = useRef(null);
  const [overflows, setOverflows] = useState(false);
  useLayoutEffect(() => {
    const elem = ref.current;
    if (elem) {
      if (maxLines) {
        setOverflows(elem.offsetHeight < elem.scrollHeight);
      } else {
        setOverflows(elem.offsetWidth < elem.scrollWidth);
      }
    }
  }, [ref, maxLines, text]);

  return (
    <Tooltip
      // if set to `undefined`, default tooltip behaviour applies
      show={overflows ? undefined : false}
      placement={tooltipPlacement}
      tooltipContent={text}
      maxWidth={tooltipMaxWidth}
    >
      <TooltipTarget>
        <TruncatedText
          maxWidth={maxWidth}
          maxLines={maxLines}
          ref={ref}
          {...textProps}
        >
          {text}
        </TruncatedText>
      </TooltipTarget>
    </Tooltip>
  );
};

export { Truncate };
