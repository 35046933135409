import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import styled, { css } from 'styled-components';

import {
  getFontSize,
  getLineHeight,
  getFontFamily,
  getColor,
  getFontWeight,
  getBaseUnit,
} from '../styles/themeGetters';
import { ColorKeys, FontSizeKeys, FontWeightKeys } from '../styles/themes';

type Color = ColorKeys | 'inherit';
type FontSize = FontSizeKeys | 'inherit';
type HTMLTags = keyof JSX.IntrinsicElements;
type TextProps = {
  as?: HTMLTags;
  children: React.ReactNode;
  color?: Color;
  display?: string;
  fontSize?: FontSize;
  fontWeight?: FontWeightKeys;
  maxWidth?: string;
  minWidth?: string;
  mb?: number;
  ml?: number;
  mr?: number;
  mt?: number;
  textAlign?: 'left' | 'center' | 'right';
  truncated?: boolean;
  role?: string;
};

type StyledTextProps = TextProps & {
  $color?: Color;
  $display?: string;
  $fontSize?: FontSize;
  $fontWeight?: FontWeightKeys;
};

const truncatedCss = css`
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const StyledText = styled.p<StyledTextProps>`
  color: ${({ $color }) =>
    $color === 'inherit' ? 'inherit' : getColor($color)};
  display: ${({ $display }) => $display};
  line-height: ${({ $fontSize }) =>
    $fontSize === 'inherit' ? 'inherit' : getLineHeight($fontSize)};
  font-family: ${getFontFamily('lato')};
  font-size: ${({ $fontSize }) =>
    $fontSize === 'inherit' ? 'inherit' : getFontSize($fontSize)};
  font-weight: ${({ $fontWeight }) => getFontWeight($fontWeight)};
  margin-bottom: ${({ mb }) => getBaseUnit(mb)};
  margin-left: ${({ ml }) => getBaseUnit(ml)};
  margin-right: ${({ mr }) => getBaseUnit(mr)};
  margin-top: ${({ mt }) => getBaseUnit(mt)};
  max-width: ${({ maxWidth }) => maxWidth};
  min-width: ${({ minWidth }) => minWidth};
  text-align: ${({ textAlign }) => textAlign};
  ${({ truncated }) => truncated && truncatedCss};
  /* Debug style */
  /* outline: 1px solid red; */
`;

const InnerText: ForwardRefRenderFunction<HTMLParagraphElement, TextProps> = (
  {
    as = 'p',
    children,
    color = 'black',
    display = '',
    fontSize = 16,
    fontWeight = 'regular',
    maxWidth = '',
    minWidth = '',
    mb = 0,
    ml = 0,
    mr = 0,
    mt = 0,
    textAlign = 'left',
    truncated = false,
    ...rest
  },
  ref
) => (
  <StyledText
    $color={color}
    $fontSize={fontSize}
    $fontWeight={fontWeight}
    textAlign={textAlign}
    mb={mb}
    mr={mr}
    ml={ml}
    mt={mt}
    as={as}
    $display={display}
    maxWidth={maxWidth}
    minWidth={minWidth}
    truncated={truncated}
    {...rest}
    ref={ref}
  >
    {children}
  </StyledText>
);

const Text = forwardRef(InnerText);

export { Text };
