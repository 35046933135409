import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  getBaseUnit,
  getFontSize,
  getFontFamily,
  getColor,
} from '../styles/themeGetters';
import { SvgIcon } from '../SvgIcon';

const sizes = {
  small: {
    height: 7,
    padding: 2,
    fontSize: 2,
  },
  regular: {
    height: 9,
    padding: 2,
    fontSize: 2,
  },
  large: {
    height: 11,
    padding: 3,
    fontSize: 3,
  },
};

const variants = {
  primary: {
    default: {
      border: 'navy',
      backgroundColor: 'navy',
      color: 'gyscale-0',
    },
    hover: {
      border: 'navy-dark',
      backgroundColor: 'navy-dark',
      color: 'gyscale-0',
    },
    active: {
      border: 'navy-dark',
      backgroundColor: 'navy-dark',
      color: 'gyscale-0',
    },
    disabled: {
      border: 'navy',
      backgroundColor: 'navy',
      color: 'grey-dark',
    },
    loading: {
      border: 'navy',
      backgroundColor: 'navy',
      color: 'grey-dark',
    },
  },
  primaryInverted: {
    default: {
      border: 'navy',
      backgroundColor: 'gyscale-0',
      color: 'navy',
    },
    hover: {
      border: 'navy-dark',
      backgroundColor: 'grey-light-100',
      color: 'navy-dark',
    },
    active: {
      border: 'navy-dark',
      backgroundColor: 'gyscale-0',
      color: 'navy-dark',
    },
    disabled: {
      border: 'navy',
      backgroundColor: 'gyscale-0',
      color: 'grey-dark',
    },
    loading: {
      border: 'navy',
      backgroundColor: 'gyscale-0',
      color: 'navy',
    },
  },
  secondary: {
    default: {
      border: 'gyscale-200',
      backgroundColor: 'gyscale-100',
      color: 'gyscale-900',
    },
    hover: {
      border: 'gyscale-300',
      backgroundColor: 'gyscale-200',
      color: 'gyscale-900',
    },
    active: {
      border: 'gyscale-500',
      backgroundColor: 'gyscale-400',
      color: 'gyscale-900',
    },
    disabled: {
      border: 'gyscale-200',
      backgroundColor: 'gyscale-100',
      color: 'gyscale-300',
    },
    loading: {
      border: 'gyscale-300',
      backgroundColor: 'gyscale-200',
      color: 'gyscale-600',
    },
  },
  secondaryInverted: {
    default: {
      border: 'gyscale-700',
      backgroundColor: 'gyscale-0',
      color: 'gyscale-700',
    },
    hover: {
      border: 'gyscale-800',
      backgroundColor: 'gyscale-0',
      color: 'gyscale-800',
    },
    active: {
      border: 'gyscale-900',
      backgroundColor: 'gyscale-100',
      color: 'gyscale-900',
    },
    disabled: {
      border: 'gyscale-200',
      backgroundColor: 'gyscale-100',
      color: 'gyscale-300',
    },
    loading: {
      border: 'gyscale-700',
      backgroundColor: 'gyscale-0',
      color: 'gyscale-700',
    },
  },
  danger: {
    default: {
      border: 'dan-600',
      backgroundColor: 'dan-500',
      color: 'gyscale-0',
    },
    hover: {
      border: 'dan-700',
      backgroundColor: 'dan-600',
      color: 'gyscale-0',
    },
    active: {
      border: 'dan-800',
      backgroundColor: 'dan-700',
      color: 'gyscale-0',
    },
    disabled: {
      border: 'dan-300',
      backgroundColor: 'dan-200',
      color: 'gyscale-0',
    },
    loading: {
      border: 'dan-500',
      backgroundColor: 'dan-400',
      color: 'gyscale-0',
    },
  },
  dangerInverted: {
    default: {
      border: 'dan-500',
      backgroundColor: 'gyscale-0',
      color: 'dan-500',
    },
    hover: {
      border: 'dan-600',
      backgroundColor: 'gyscale-0',
      color: 'dan-600',
    },
    active: {
      border: 'dan-700',
      backgroundColor: 'dan-100',
      color: 'dan-700',
    },
    disabled: {
      border: 'gyscale-200',
      backgroundColor: 'gyscale-100',
      color: 'dan-300',
    },
    loading: {
      border: 'dan-500',
      backgroundColor: 'gyscale-0',
      color: 'dan-500',
    },
  },
  link: {
    default: {
      border: 'navy',
      backgroundColor: 'navy',
      color: 'gyscale-0',
    },
    hover: {
      border: 'navy-dark',
      backgroundColor: 'navy-dark',
      color: 'gyscale-0',
    },
    active: {
      border: 'navy-dark',
      backgroundColor: 'navy-dark',
      color: 'gyscale-0',
    },
    disabled: {
      border: 'grey',
      backgroundColor: 'grey',
      color: 'gyscale-900',
    },
    loading: {
      border: 'navy',
      backgroundColor: 'navy',
      color: 'gyscale-0',
    },
  },
  linkInverted: {
    default: {
      border: 'navy',
      backgroundColor: 'gyscale-0',
      color: 'navy',
    },
    hover: {
      border: 'navy-dark',
      backgroundColor: 'gyscale-0',
      color: 'navy-dark',
    },
    active: {
      border: 'navy-dark',
      backgroundColor: 'navy-dark',
      color: 'navy-dark',
    },
    disabled: {
      border: 'navy-dark',
      backgroundColor: 'navy-dark',
      color: 'gyscale-0',
    },
    loading: {
      border: 'navy',
      backgroundColor: 'navy',
      color: 'navy',
    },
  },
};

const StyledButton = styled.button`
  /* Base style */
  margin-bottom: 0;
  cursor: pointer;
  white-space: nowrap;
  border: none;
  user-select: none;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: ${getFontFamily('lato')};
  transition: all 0.1s ease-in-out;
  transition-property: background-color, border;
  border: 1px solid;
  position: relative;
  margin-bottom: ${({ mb }) => mb && getBaseUnit(mb)};
  margin-left: ${({ ml }) => ml && getBaseUnit(ml)};
  margin-right: ${({ mr }) => mr && getBaseUnit(mr)};
  margin-top: ${({ mt }) => mt && getBaseUnit(mt)};
  width: ${({ width }) => width};

  /* Click handling  */
  pointer-events: ${({ disabled, isLoading }) =>
    (disabled || isLoading) && 'none'};

  /* Size */
  height: ${({ size }) => getBaseUnit(sizes[size].height)};
  padding: 0 ${({ size }) => getBaseUnit(sizes[size].padding)};
  font-size: ${({ size }) => getFontSize(sizes[size].fontSize)};

  /* Styling */
  background-color: ${({ variant }) =>
    getColor(variants[variant].default.backgroundColor)};
  border-color: ${({ variant }) => getColor(variants[variant].default.border)};
  color: ${({ variant }) => getColor(variants[variant].default.color)};

  &:hover {
    background-color: ${({ variant }) =>
      getColor(variants[variant].hover.backgroundColor)};
    border-color: ${({ variant }) => getColor(variants[variant].hover.border)};
    color: ${({ variant }) => getColor(variants[variant].hover.color)};
  }

  &:active {
    background-color: ${({ variant }) =>
      getColor(variants[variant].active.backgroundColor)};
    border-color: ${({ variant }) => getColor(variants[variant].active.border)};
    color: ${({ variant }) => getColor(variants[variant].active.color)};
  }

  &:disabled {
    background-color: ${({ variant }) =>
      getColor(variants[variant].disabled.backgroundColor)};
    border-color: ${({ variant }) =>
      getColor(variants[variant].disabled.border)};
    color: ${({ variant }) => getColor(variants[variant].disabled.color)};
  }

  /* Style Overwrite for no border case */
  &&& {
    border-color: ${({ noBorder }) => noBorder && 'transparent'};
    background-color: ${({ noBorder }) => noBorder && 'transparent'};
  }

  /* Style Overwrite for loading case */
  && {
    background-color: ${({ variant, loading }) =>
      loading && getColor(variants[variant].loading.backgroundColor)};
    border-color: ${({ variant, loading }) =>
      loading && getColor(variants[variant].loading.border)};
    color: ${({ variant, loading }) =>
      loading && getColor(variants[variant].loading.color)};
  }

  /* Style Overwrite for active case */
  && {
    background-color: ${({ variant, active }) =>
      active && getColor(variants[variant].active.backgroundColor)};
    border-color: ${({ variant, active }) =>
      active && getColor(variants[variant].active.border)};
    color: ${({ variant, active }) =>
      active && getColor(variants[variant].active.color)};
  }

  &:focus {
    outline: none;
  }
`;

const ButtonSpacing = styled.i`
  width: ${({ size }) => getBaseUnit(sizes[size].padding)};
  display: inline-block;
`;

const ThrobberWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -8px;
  margin-left: -7px;
`;

const OpacityWrapper = styled.div`
  opacity: ${({ hide }) => hide && 0};
`;

const Button = ({
  children,
  size,
  variant,
  disabled,
  outline,
  noBorder,
  iconLeft,
  iconRight,
  loading,
  active,
  mt,
  mr,
  mb,
  ml,
  width,
  ...rest
}) => {
  const inverted = outline || noBorder;

  return (
    <StyledButton
      type="button"
      size={size}
      variant={`${variant}${inverted ? 'Inverted' : ''}`}
      disabled={disabled || loading}
      noBorder={noBorder}
      isLoading={loading}
      active={active}
      mt={mt}
      mr={mr}
      mb={mb}
      ml={ml}
      width={width}
      {...rest}
    >
      <>
        <OpacityWrapper hide={loading}>
          {iconLeft && <SvgIcon icon={iconLeft} color="inherit" />}
          {iconLeft && children && <ButtonSpacing size={size} />}
          {children}
          {iconRight && children && <ButtonSpacing size={size} />}
          {iconRight && <SvgIcon icon={iconRight} color="inherit" />}
        </OpacityWrapper>
        {loading && (
          <ThrobberWrapper>
            <SvgIcon icon="circle-notch" color="inherit" spin />
          </ThrobberWrapper>
        )}
      </>
    </StyledButton>
  );
};

Button.propTypes = {
  size: PropTypes.oneOf(['small', 'regular', 'large']),
  variant: PropTypes.oneOf(['primary', 'secondary', 'danger', 'link']),
  outline: PropTypes.bool,
  noBorder: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  children: PropTypes.node,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  width: PropTypes.string,
  active: PropTypes.bool,
  mt: PropTypes.number,
  mr: PropTypes.number,
  mb: PropTypes.number,
  ml: PropTypes.number,
};

Button.defaultProps = {
  size: 'regular',
  variant: 'primary',
  loading: false,
  mt: 0,
  mr: 0,
  mb: 0,
  ml: 0,
};

export { Button };
