import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgEdit = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <g clipPath="url(#Edit_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.405.512a1.747 1.747 0 0 0-2.47 0L4.106 6.34a2.745 2.745 0 0 0-.766 1.49l-.325 1.95a1.048 1.048 0 0 0 1.206 1.205l1.95-.325a2.745 2.745 0 0 0 1.489-.766l5.828-5.829a1.747 1.747 0 0 0 0-2.47L12.405.512ZM10.993 1.57a.25.25 0 0 1 .353 0l1.084 1.084a.25.25 0 0 1 0 .353L6.6 8.835c-.184.184-.42.306-.677.349l-1.33.221.222-1.33c.043-.256.165-.492.349-.676l5.828-5.829ZM.75 13a.75.75 0 0 0 0 1.5h11a.75.75 0 0 0 0-1.5h-11Z"
        fill={_.color}
      />
    </g>
    <defs>
      <clipPath id="Edit_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgEdit);
export default ForwardRef;
