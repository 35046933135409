import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgCalendar = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 0H3v2a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V5a3 3 0 0 0-3-3V0h-2v2H5V0ZM1.5 6v7A1.5 1.5 0 0 0 3 14.5h10a1.5 1.5 0 0 0 1.5-1.5V6h-13ZM3 8h2v2H3V8Zm2 3H3v2h2v-2Zm2-3h2v2H7V8Zm2 3H7v2h2v-2Zm2-3h2v2h-2V8Zm2 3h-2v2h2v-2Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCalendar);
export default ForwardRef;
