import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgInfoSolid = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      d="M8 1C4.13 1 1 4.13 1 8s3.13 7 7 7 7-3.13 7-7-3.13-7-7-7Zm.843 9.838A.844.844 0 0 1 8 11.68a.844.844 0 0 1-.843-.843V7.57a.844.844 0 0 1 1.686 0v3.268ZM8 5.988a.844.844 0 0 1-.843-.843.844.844 0 0 1 1.686 0A.844.844 0 0 1 8 5.988Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgInfoSolid);
export default ForwardRef;
