import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgInfoOutline = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.548 8a6.452 6.452 0 1 1 12.904 0A6.452 6.452 0 0 1 1.548 8ZM8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0Zm0 12c.551 0 1-.449 1-1V7.878c0-.551-.449-1-1-1-.551 0-1 .449-1 1V11c0 .551.449 1 1 1ZM7 5c0 .551.449 1 1 1 .551 0 1-.449 1-1 0-.551-.449-1-1-1-.551 0-1 .449-1 1Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgInfoOutline);
export default ForwardRef;
