import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import { Theme } from 'baukasten';

import { Button } from 'Button';

import LocaleOptions from './components/LocaleOptions';
import LocaleOption from './components/LocaleOption';

import messages from './messages';

import { appLocales } from '../../i18n';
import { changeLocale } from '../App/actions';
import { selectLocale } from '../App/selectors';

export class LocaleToggle extends React.PureComponent {
  render() {
    const locales = appLocales.map(locale => {
      const style = {
        fontWeight: this.props.locale === locale ? 'bold' : 'normal',
        color: Theme.getColor('navy'),
        fontSize: '16px',
      };

      /* eslint-disable jsx-a11y/anchor-is-valid */
      return (
        <LocaleOption key={locale}>
          <Button
            variant="link"
            noBorder
            onClick={() => this.props.onLocaleSelect(locale)}
            style={style}
          >
            <FormattedMessage {...messages[locale]} />
          </Button>
        </LocaleOption>
      );
    });

    return <LocaleOptions>{locales}</LocaleOptions>;
  }
}

LocaleToggle.propTypes = {
  // mapDispatchToProps
  onLocaleSelect: PropTypes.func.isRequired,

  // mapStateToProps
  locale: PropTypes.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  locale: selectLocale,
});

export function mapDispatchToProps(dispatch) {
  return {
    onLocaleSelect: locale => dispatch(changeLocale(locale)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LocaleToggle);
