import React from 'react';
import styled from 'styled-components';
import { ColorKeys } from '../../styles/themes';
import { Box } from '../../Box';
import { Text } from '../../Text';
import { getBaseUnit } from '../../styles/themeGetters';
import { Tooltip } from '../../Tooltip';

type Data = {
  value: number;
  tooltip: {
    label: string;
    value: string;
  };
};

type HorizontalStackedBarProps = {
  data: Data[];
  colors?: ColorKeys[];
};

type SegmentsData = {
  data: Data;
  color: ColorKeys;
}[];

type WrapperProps = {
  templateColumns: string;
};

const defaultColors: ColorKeys[] = [
  'navy-dark',
  'navy',
  'blue-dark',
  'blue',
  'grey-light-100',
];

const Wrapper = styled(Box)<WrapperProps>`
  display: grid;
  grid-gap: 1px;
  border-radius: ${getBaseUnit(1)};
  overflow: hidden;
  grid-template-columns: ${({ templateColumns }) => templateColumns};
`;

const HorizontalStackedBar = ({
  data,
  colors = defaultColors,
}: HorizontalStackedBarProps) => {
  // Build an array with data and colors and remove the zero values
  const segmentsData: SegmentsData = data
    .map((el, idx) => ({ data: el, color: colors[idx % colors.length] }))
    .filter(el => el.data.value !== 0);

  // Build a grid template columns string
  const templateColumns = segmentsData
    .map(el => `${el.data.value}fr`)
    .join(' ');

  return (
    <Wrapper templateColumns={templateColumns}>
      {segmentsData.map(element => (
        <Tooltip
          placement="top"
          tooltipContent={
            <Box>
              <Text color="white" fontSize={12} textAlign="center">
                {element.data.tooltip.label}
              </Text>
              <Text color="white" fontSize={12} textAlign="center">
                {element.data.tooltip.value}
              </Text>
            </Box>
          }
          key={element.data.tooltip.label}
        >
          <Box
            cursor="pointer"
            backgroundColor={element.color}
            height="20px"
            width="100%"
          />
        </Tooltip>
      ))}
    </Wrapper>
  );
};

export { HorizontalStackedBar };
