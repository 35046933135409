import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useSelect } from 'downshift';
import { InputField } from '../components/InputField';
import { Menu } from '../../Menu';
import { ToggleButton } from '../components/ToggleButton';
import { InputFieldClearButton } from '../components/InputFieldClearButton';
import { SelectedItem } from './renderers/SelectedItemRenderer';
import { MenuItemRenderer } from './renderers/MenuItemRenderer';
import { Box } from '../../Box';
import { Item, Entity } from './types';

// a temporary fix for the Menu-related scrolling issue
// TODO: remove once the Menu includes a fix (https://workpathhq.atlassian.net/browse/DRA-587)
const StyledWrapper = styled.span`
  position: relative;
`;

function itemToString(item) {
  return item ? item.title : '';
}

type Props = {
  placeholder?: string;
  hasError?: boolean;
  disabled?: boolean;
  onChange: (value: Item['value'] | null) => void;
  items: Entity[];
  initialValue: Item['value'];
  name?: string;
  maxHeight?: string;
  maxTextWidth?: string;
  hideClearButton?: boolean;
  value?: Item['value'];
};

function RawSelect({
  placeholder,
  hasError,
  onChange,
  disabled,
  items,
  initialValue,
  name,
  maxHeight,
  maxTextWidth,
  hideClearButton,
  value,
}: Props) {
  const anchorEl = React.useRef(null);

  const memoizedItems = useMemo(() => {
    return Object.fromEntries(items.map(item => [item.value, item]));
  }, [items]);

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    openMenu,
    selectItem,
    selectedItem,
  } = useSelect({
    items,
    itemToString,
    onSelectedItemChange: ({ selectedItem: newSelectedItem }) => {
      if (newSelectedItem?.entityType !== 'group') {
        onChange(newSelectedItem ? newSelectedItem.value : null);
      }
    },
    initialSelectedItem: memoizedItems[initialValue],
  });

  useEffect(
    () => {
      selectItem(memoizedItems[value] ?? memoizedItems[initialValue]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- [RFC 032]
    [value]
  );

  return (
    <>
      <InputField
        hasError={hasError}
        disabled={disabled}
        ref={anchorEl}
        paddingRight="6px"
        isFocused={isOpen}
      >
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box width="calc(100% - 48px)">
            <SelectedItem
              item={selectedItem}
              isOpen={isOpen}
              placeholder={placeholder}
              openMenu={openMenu}
              disabled={disabled}
              maxTextWidth={maxTextWidth}
            />
          </Box>
          <Box display="flex" justify-content="center">
            {selectedItem && !hideClearButton ? (
              <InputFieldClearButton
                onClick={() => {
                  selectItem(null);
                }}
                disabled={disabled}
                align="center"
                tooltipContent="Clear"
              />
            ) : null}
            <ToggleButton
              getToggleButtonProps={getToggleButtonProps}
              isOpen={isOpen}
              disabled={disabled}
              align="center"
              name={name}
            />
          </Box>
        </Box>
      </InputField>
      <StyledWrapper {...getMenuProps()}>
        {isOpen && (
          <Menu
            anchorEl={anchorEl}
            open={isOpen}
            menuOffset={4}
            fullWidth
            maxHeight={maxHeight}
          >
            {items.map((item, index) => {
              return (
                <MenuItemRenderer
                  item={item}
                  getItemProps={getItemProps}
                  key={item.id}
                  idx={index}
                />
              );
            })}
          </Menu>
        )}
      </StyledWrapper>
    </>
  );
}

export { RawSelect };
