import { getRequest } from '../../methods';

const getCheckIn = checkInId => getRequest(`report_intervals/${checkInId}`);
const getCheckInTeamMemberReports = checkInId =>
  getRequest(`report_intervals/${checkInId}/team_member_reports`);
const getCheckInStatusUpdates = checkInId =>
  getRequest(`report_intervals/${checkInId}/key_results/changed`);

const getCheckInTeamGoals = reportIntervalId =>
  getRequest(`users/me/report_intervals/${reportIntervalId}/goals`);

const checkIns = {
  getCheckIn,
  getCheckInTeamMemberReports,
  getCheckInStatusUpdates,
  getCheckInTeamGoals,
};

export default checkIns;
