import { getRequest, patchRequest } from '../../methods';

const getInvitation = token => getRequest(`invitations/${token}`);
const acceptInvitation = (token, payload) =>
  patchRequest(`invitations/${token}/accept`, payload);

const invitations = {
  getInvitation,
  acceptInvitation,
};

export default invitations;
