import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Box, Text } from 'baukasten';

import Spinner from 'components/Spinner';

import messages from './messages';

const Wrapper = styled.div`
  position: absolute;
  top: calc(50% - 10px);
  text-align: center;
  width: 100%;
  font-size: 14px;
  opacity: 0.65;
`;

const AppLoadingState = () => (
  <Wrapper>
    <Spinner dark />

    <Box mt={2} display="flex" justifyContent="center">
      <Text fontSize={16} color="navy">
        <FormattedMessage {...messages.loadingText} />
      </Text>
    </Box>
  </Wrapper>
);

export default AppLoadingState;
