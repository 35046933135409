import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgSort = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.214 5.468 3.49 2.221A.654.654 0 0 1 3.997 2c.199 0 .37.073.513.22l3.276 3.247A.67.67 0 0 1 8 5.954c0 .197-.065.36-.195.485a.67.67 0 0 1-.487.183.631.631 0 0 1-.475-.195l-1.345-1.35-.864-.97.052 1.342v7.882a.64.64 0 0 1-.195.48.672.672 0 0 1-.488.189.689.689 0 0 1-.5-.19.651.651 0 0 1-.189-.479V5.45l.052-1.343-.87.971-1.34 1.35a.653.653 0 0 1-.48.195.67.67 0 0 1-.488-.183A.659.659 0 0 1 0 5.954a.67.67 0 0 1 .214-.486Zm15.578 5.058-3.278 3.26A.683.683 0 0 1 12 14a.694.694 0 0 1-.507-.214l-3.278-3.26A.656.656 0 0 1 8 10.04c0-.198.063-.357.189-.48a.694.694 0 0 1 .494-.182c.186 0 .345.063.475.19l1.34 1.348.871.978-.052-1.343V3.668c0-.193.065-.353.195-.479A.673.673 0 0 1 12 3c.2 0 .364.063.494.19.13.125.195.285.195.478v6.883l-.052 1.343.872-.978 1.34-1.349a.643.643 0 0 1 .475-.19c.199 0 .362.062.487.184a.637.637 0 0 1 .189.479c0 .185-.07.347-.208.486Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgSort);
export default ForwardRef;
