import { getRequestV2, postRequestV2 } from '../../methods';

const getPlans = () => getRequestV2('planner/plans');

const getTasks = planId => getRequestV2(`planner/plans/${planId}/tasks`);

const linkTasks = (planId, payload) =>
  postRequestV2(`planner/plans/${planId}/tasks`, payload);

const plans = {
  getPlans,
  getTasks,
  linkTasks,
};

export default plans;
