import React from 'react';

import { Box } from '../../../Box';

type DrawerSubHeaderProps = {
  children: React.ReactNode;
};

const DrawerSubHeader = ({ children }: DrawerSubHeaderProps) => (
  <Box width="100%" pr={8} pb={8} pl={8}>
    {children}
  </Box>
);

export { DrawerSubHeader };
