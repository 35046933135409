import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgExternalLink = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      d="M5 3a.773.773 0 0 0 0 1.547l5.352.005-6.125 6.124a.775.775 0 1 0 1.097 1.097l6.124-6.125-.005 5.346a.778.778 0 1 0 1.557 0V3H5Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgExternalLink);
export default ForwardRef;
