import { css } from 'styled-components';

import colors from '../colors';

const primary = css`
  background: transparent;
  border: 1px solid ${colors.buttonPrimary};
  color: ${colors.buttonPrimary};

  &:hover,
  &:active {
    background-color: ${colors.buttonPrimaryHover};
    border-color: ${colors.buttonPrimaryHover};
    color: ${colors.buttonText};
  }
`;

const secondary = css`
  background: transparent;
  border: 1px solid rgba(192, 192, 192, 0.8);
  color: ${colors.text};

  &:hover,
  &:active {
    background-color: ${colors.buttonSecondaryHover};
    color: ${colors.text};
  }
`;

const success = css`
  background: transparent;
  border: 1px solid ${colors.buttonSuccess};
  color: ${colors.buttonSuccess};

  &:hover,
  &:active {
    background-color: ${colors.buttonSuccessHover};
    border-color: ${colors.buttonSuccessHover};
    color: ${colors.buttonText};
  }
`;

const danger = css`
  background: transparent;
  border: 1px solid ${colors.buttonDanger};
  color: ${colors.buttonDanger};

  &:hover,
  &:active {
    background-color: ${colors.buttonDangerHover};
    border-color: ${colors.buttonDangerHover};
    color: ${colors.buttonText};
  }
`;

const variants = {
  primary,
  secondary,
  success,
  danger,
};

export default variants;
