import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { library } from '@fortawesome/fontawesome-svg-core/';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons/faAngleDown';
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons/faAngleRight';
import { faArchive } from '@fortawesome/pro-solid-svg-icons/faArchive';
import { faArrowCircleUp } from '@fortawesome/pro-solid-svg-icons/faArrowCircleUp';
import { faBullseye } from '@fortawesome/pro-solid-svg-icons/faBullseye';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import { faChartNetwork } from '@fortawesome/pro-solid-svg-icons/faChartNetwork';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons/faCircleNotch';
import { faClock } from '@fortawesome/pro-solid-svg-icons/faClock';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';
import { faEyeSlash } from '@fortawesome/pro-solid-svg-icons/faEyeSlash';
import { faFilter } from '@fortawesome/pro-solid-svg-icons/faFilter';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import { faJira } from '@fortawesome/free-brands-svg-icons/faJira';
import { faPen } from '@fortawesome/pro-solid-svg-icons/faPen';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { faUserAlt } from '@fortawesome/pro-solid-svg-icons/faUserAlt';
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser';
import { faUsersMedical } from '@fortawesome/pro-solid-svg-icons/faUsersMedical';
import { faLineHeight } from '@fortawesome/pro-solid-svg-icons/faLineHeight';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle';
import { faFileSignature } from '@fortawesome/pro-solid-svg-icons/faFileSignature';
import { faBell } from '@fortawesome/pro-solid-svg-icons/faBell';
import { faChartBar } from '@fortawesome/pro-solid-svg-icons/faChartBar';
import { faCommentCheck } from '@fortawesome/pro-solid-svg-icons/faCommentCheck';
import { faClone } from '@fortawesome/pro-solid-svg-icons/faClone';
import { faCog } from '@fortawesome/pro-solid-svg-icons/faCog';
import { faChartLine } from '@fortawesome/pro-solid-svg-icons/faChartLine';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner';
import { faDownload } from '@fortawesome/pro-solid-svg-icons/faDownload';
import { faFolderTimes } from '@fortawesome/pro-solid-svg-icons/faFolderTimes';
import { faCalendar } from '@fortawesome/pro-solid-svg-icons/faCalendar';
import { faCalendarAlt } from '@fortawesome/pro-solid-svg-icons/faCalendarAlt';
import { faSyncAlt } from '@fortawesome/pro-solid-svg-icons/faSyncAlt';
import { faEllipsisH } from '@fortawesome/pro-solid-svg-icons/faEllipsisH';
import { faBars } from '@fortawesome/pro-solid-svg-icons/faBars';
import { faPuzzlePiece } from '@fortawesome/pro-solid-svg-icons/faPuzzlePiece';
import { faHandshakeAlt } from '@fortawesome/pro-solid-svg-icons/faHandshakeAlt';
import { faHandshakeAltSlash } from '@fortawesome/pro-solid-svg-icons/faHandshakeAltSlash';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft';
import { faSlidersH } from '@fortawesome/pro-solid-svg-icons/faSlidersH';
import { faCommentAltDots } from '@fortawesome/pro-solid-svg-icons/faCommentAltDots';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons/faArrowLeft';
import { faCommentAltExclamation } from '@fortawesome/pro-solid-svg-icons/faCommentAltExclamation';

import { getColor, getBaseUnit } from '../styles/themeGetters';

library.add(
  faAngleDown,
  faAngleRight,
  faArchive,
  faArrowCircleUp,
  faArrowLeft,
  faBars,
  faBell,
  faBullseye,
  faCalendar,
  faCalendarAlt,
  faCaretDown,
  faChartBar,
  faChartLine,
  faChartNetwork,
  faCheck,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCircleNotch,
  faClock,
  faClone,
  faCog,
  faCommentAltDots,
  faCommentCheck,
  faDownload,
  faEllipsisH,
  faEnvelope,
  faExclamationTriangle,
  faExclamationCircle,
  faEyeSlash,
  faFileSignature,
  faFilter,
  faFolderTimes,
  faHandshakeAlt,
  faHandshakeAltSlash,
  faInfoCircle,
  faJira,
  faLineHeight,
  faPen,
  faPlusCircle,
  faPuzzlePiece,
  faSpinner,
  faSyncAlt,
  faTimes,
  faUser,
  faUserAlt,
  faUsersMedical,
  faSlidersH,
  faArrowLeft,
  faCommentAltExclamation
);

const iconPropTypes = [
  'angle-down',
  'angle-right',
  'archive',
  'arrow-circle-up',
  'arrow-left',
  'bars',
  'bell',
  'bullseye',
  'calendar-alt',
  'calendar',
  'caret-down',
  'chart-bar',
  'chart-line',
  'chart-network',
  'check-circle',
  'check',
  'chevron-left',
  'chevron-right',
  'circle-notch',
  'circle',
  'clock',
  'clone',
  'cog',
  'comment-alt-dots',
  'comment-alt-exclamation',
  'comment-check',
  'download',
  'ellipsis-h',
  'envelope',
  'exclamation-triangle',
  'exclamation-circle',
  'eye-slash',
  'file-signature',
  'filter',
  'folder-times',
  'handshake-alt',
  'handshake-alt-slash',
  'info-circle',
  'jira',
  'line-height',
  'pen',
  'plus-circle',
  'puzzle-piece',
  'spinner',
  'sync-alt',
  'times',
  'user-alt',
  'user',
  'users-medical',
  'sliders-h',
];

const StyledSvgIcon = styled(FontAwesomeIcon)`
  color: ${({ color }) => getColor(color)};
  margin-bottom: ${({ mb }) => mb && getBaseUnit(mb)};
  margin-left: ${({ ml }) => ml && getBaseUnit(ml)};
  margin-right: ${({ mr }) => mr && getBaseUnit(mr)};
  margin-top: ${({ mt }) => mt && getBaseUnit(mt)};
`;

const SvgIcon = ({
  icon,
  color,
  prefix,
  mr,
  ml,
  mb,
  mt,
  ariaLabel,
  ...rest
}) => (
  <StyledSvgIcon
    role="img"
    aria-label={ariaLabel || icon}
    icon={[prefix, icon]}
    color={color}
    mb={mb}
    ml={ml}
    mr={mr}
    mt={mt}
    {...rest}
  />
);

SvgIcon.propTypes = {
  icon: PropTypes.oneOf(iconPropTypes).isRequired,
  color: PropTypes.string,
  prefix: PropTypes.string,
  mb: PropTypes.number,
  ml: PropTypes.number,
  mr: PropTypes.number,
  mt: PropTypes.number,
  ariaLabel: PropTypes.string,
};

SvgIcon.defaultProps = {
  color: 'gyscale-900',
  prefix: 'fas',
};

export { iconPropTypes, SvgIcon };
