import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgAlert = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14ZM7.797 4h.28c.45 0 .812.523.797.912l-.112 3.134c-.015.372-.367.666-.797.666-.43 0-.783-.294-.797-.666L7 4.912c-.015-.39.346-.912.797-.912ZM7 10.778c0 .556.432 1.008.964 1.008s.964-.452.964-1.008c0-.557-.432-1.008-.964-1.008S7 10.22 7 10.778Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgAlert);
export default ForwardRef;
