import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box } from '../Box';
import { Text } from '../Text';
import { SvgIcon } from '../SvgIcon';
import {
  getGridSpacing,
  getTransformationDuration,
} from '../styles/themeGetters';

const variants = {
  info: {
    borderColor: 'info-300',
    backgroundColor: 'info-50',
    color: 'info-900',
  },
  success: {
    borderColor: 'suc-300',
    backgroundColor: 'suc-50',
    color: 'suc-900',
  },
  error: {
    borderColor: 'dan-300',
    backgroundColor: 'dan-50',
    color: 'dan-900',
  },
  warning: {
    borderColor: 'war-300',
    backgroundColor: 'war-50',
    color: 'war-900',
  },
};

// Curstor, transition and transfrom and margin bottom are not part of the Box specs!
const ToastBox = styled(Box)`
  margin-bottom: ${getGridSpacing(3)};
`;

const CloseBox = styled(Box)`
  cursor: pointer;
  transition: ${getTransformationDuration('default')} ease-in-out;

  &:hover {
    transform: scale(1.2);
  }
`;

const Toast = ({
  variant,
  label,
  children,
  handleClose,
  dismissable,
  ...rest
}) => (
  <ToastBox
    borderPosition={['all']}
    borderColor={variants[variant].borderColor}
    backgroundColor={variants[variant].backgroundColor}
    dismissable={dismissable}
    pt={4}
    pr={4}
    pb={4}
    pl={4}
    elevation={3}
    round
    display="flex"
    alignItems="flex-start"
    width="432px"
    {...rest}
  >
    <Box
      height="24px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      mr={4}
    >
      <SvgIcon icon="info-circle" color={variants[variant].color} />
    </Box>
    <Box display="flex" flex="1 1 auto" mr={4}>
      <Text mb={0} color={variants[variant].color}>
        <strong>{label}: </strong>
        {children}
      </Text>
    </Box>
    {dismissable && (
      <CloseBox
        height="24px"
        width="24px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        onClick={handleClose}
      >
        <SvgIcon icon="times" color={variants[variant].color} />
      </CloseBox>
    )}
  </ToastBox>
);

Toast.propTypes = {
  variant: PropTypes.oneOf(['info', 'error', 'success', 'warning']).isRequired,
  children: PropTypes.node,
  label: PropTypes.string.isRequired,
  dismissable: PropTypes.bool,
  handleClose: PropTypes.func,
};

Toast.defaultProps = {
  dismissable: true,
};

export { Toast };
