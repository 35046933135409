import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgStar = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      d="M3.068 14.873c.149.113.317.15.504.113.188-.033.392-.127.613-.283l3.811-2.75 3.82 2.75c.22.156.424.25.612.283.187.038.355 0 .504-.113a.645.645 0 0 0 .267-.438c.033-.184.004-.403-.087-.658L11.606 9.38l3.848-2.714c.226-.156.382-.316.468-.48a.65.65 0 0 0-.295-.89c-.168-.086-.392-.126-.67-.122l-4.72.029L8.803.785C8.717.525 8.606.33 8.472.198A.641.641 0 0 0 7.996 0a.657.657 0 0 0-.475.198c-.13.132-.238.327-.324.587L5.763 5.203l-4.72-.029c-.278-.004-.502.036-.67.12a.65.65 0 0 0-.295.891c.086.165.242.325.468.48L4.394 9.38l-1.506 4.397c-.091.255-.123.474-.094.658.034.183.125.33.274.438Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgStar);
export default ForwardRef;
