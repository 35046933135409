const getColor = color => props => props.theme.colors[color];
const getFontFamily = fontFamily => props =>
  props.theme.fontFamilies[fontFamily];
const getFontSize = fontSize => props => props.theme.fontSizes[fontSize];
const getLineHeight = lineHeight => props =>
  props.theme.lineHeights[lineHeight];
const getFontWeight = fontWeight => props =>
  props.theme.fontWeights[fontWeight];
const getBaseUnit = multiplier => props =>
  `${props.theme.baseUnit * multiplier}px`;
const getGridSpacing = stack => props => props.theme.gridSpacings[stack];
const getElevations = elevation => props => props.theme.elevations[elevation];
const getTransformationDuration = duration => props =>
  props.theme.transformationDurations[duration];
const getZIndex = element => props => props.theme.zIndex[element];

export {
  getColor,
  getFontSize,
  getLineHeight,
  getBaseUnit,
  getFontWeight,
  getFontFamily,
  getElevations,
  getGridSpacing,
  getTransformationDuration,
  getZIndex,
};
