import React, { Ref, forwardRef } from 'react';
import styled from 'styled-components';

import { getColor, getFontSize, getFontWeight } from '../styles/themeGetters';
import { FontSizeKeys } from '../styles/themes';
import { Tooltip } from '../Tooltip';
import { getUserInitials } from './utils/getUserInitials';
import { getTeamInitial } from './utils/getTeamInitial';
import { IconBox, Icons } from '../Icons';

type SizeProp = {
  diameter: string;
  fontSize: FontSizeKeys;
  presenceIconSize: string;
  statusIconSize: string;
};

type SizeProps = {
  xs: SizeProp;
  s: SizeProp;
  m: SizeProp;
  l: SizeProp;
  xl: SizeProp;
  xxl: SizeProp;
};

const SIZES: SizeProps = {
  xs: {
    diameter: '20px',
    fontSize: 10,
    presenceIconSize: '4px',
    statusIconSize: '12px',
  },
  s: {
    diameter: '28px',
    fontSize: 12,
    presenceIconSize: '8px',
    statusIconSize: '16px',
  },
  m: {
    diameter: '36px',
    fontSize: 14,
    presenceIconSize: '12px',
    statusIconSize: '24px',
  },
  l: {
    diameter: '42px',
    fontSize: 14,
    presenceIconSize: '12px',
    statusIconSize: '24px',
  },
  xl: {
    diameter: '64px',
    fontSize: 16,
    presenceIconSize: '16px',
    statusIconSize: '24px',
  },
  xxl: {
    diameter: '140px',
    fontSize: 24,
    presenceIconSize: '48px',
    statusIconSize: '48px',
  },
};

const BORDER_RADIUS = {
  xs: {
    person: '50%',
    team: '6px',
    businessUnit: '6px',
    organization: '6px',
  },
  s: {
    person: '50%',
    team: '6px',
    businessUnit: '6px',
    organization: '6px',
  },
  m: {
    person: '50%',
    team: '10px',
    businessUnit: '10px',
    organization: '10px',
  },
  l: {
    person: '50%',
    team: '12px',
    businessUnit: '12px',
    organization: '12px',
  },
  xl: {
    person: '50%',
    team: '16px',
    businessUnit: '16px',
    organization: '16px',
  },
  xxl: {
    person: '50%',
    team: '16px',
    businessUnit: '16px',
    organization: '16px',
  },
};

type StyledAvatarProps = {
  image?: string;
  onClick?: () => void;
  type: 'person' | 'team' | 'businessUnit' | 'organization';
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
  status?: 'archived' | 'pending';
};

const colorsByType = {
  businessUnit: getColor('blue-light'),
  organization: getColor('yellow-light'),
};

const StyledAvatar = styled.span<StyledAvatarProps>`
  box-sizing: border-box;
  align-items: center;
  ${({ image, status }) =>
    image && !status && `background-image: url(${image})`};
  ${({ image, status }) =>
    image &&
    status &&
    `background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${image})`};
  background-size: ${({ image }) => image && 'cover'};
  border-radius: ${({ type, size }) => BORDER_RADIUS[size][type]};
  border: 1px solid ${({ type }) => colorsByType[type] ?? '#e9eaec'};
  ${({ onClick }) => onClick && 'cursor: pointer'};
  display: inline-flex;
  justify-content: center;
  vertical-align: middle;
  height: ${({ size }) => SIZES[size].diameter};
  width: ${({ size }) => SIZES[size].diameter};
  font-size: ${({ size }) => getFontSize(SIZES[size].fontSize)};
  font-weight: ${getFontWeight('bold')};
  background-color: ${({ type }) =>
    colorsByType[type] ?? getColor('grey-light-100')};
  color: ${getColor('navy')};
  text-transform: uppercase;
  position: relative;
`;

const PresenceIconBox = styled(IconBox)`
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: white;
  border-radius: 50%;
`;

type AvatarProps = {
  fallbackText: string;
  hasPresence?: boolean;
  children?: React.ReactNode;
  tooltipText?: string;
} & StyledAvatarProps;

const InnerAvatar = (
  {
    fallbackText,
    hasPresence = false,
    image,
    onClick,
    size = 'm',
    type,
    status,
    children,
    tooltipText,
  }: AvatarProps,
  ref: Ref<HTMLSpanElement>
) => (
  <span ref={ref}>
    <Tooltip tooltipContent={tooltipText || fallbackText}>
      <StyledAvatar
        aria-label="avatar"
        size={size}
        image={!children && image}
        onClick={onClick}
        type={type}
        status={status}
      >
        {!children && !status && !image && (
          <>
            {(type === 'person' && getUserInitials(fallbackText)) ||
              (type === 'team' && getTeamInitial(fallbackText))}
          </>
        )}
        {hasPresence && (
          <PresenceIconBox>
            <Icons.Done size={SIZES[size].presenceIconSize} noPadding />
          </PresenceIconBox>
        )}
        {!children && status === 'archived' && (
          <Icons.Archived
            color={image ? 'white' : 'navy'}
            size={SIZES[size].statusIconSize}
          />
        )}
        {!children && status === 'pending' && (
          <Icons.Pending
            color={image ? 'white' : 'navy'}
            size={SIZES[size].statusIconSize}
          />
        )}
        {!children && type === 'businessUnit' && (
          <Icons.BusinessUnit color="navy" size={SIZES[size].statusIconSize} />
        )}
        {!children && type === 'organization' && (
          <Icons.Organization color="navy" size={SIZES[size].statusIconSize} />
        )}
        {children}
      </StyledAvatar>
    </Tooltip>
  </span>
);

const Avatar = forwardRef(InnerAvatar);
export { Avatar };
