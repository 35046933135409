import { getRequest } from '../../methods';

const getGoalProgress = goalId => getRequest(`over_time_charts/goal/${goalId}`);
const getKeyResultProgress = goalId =>
  getRequest(`over_time_charts/key_result/${goalId}`);

const overTimeCharts = {
  getGoalProgress,
  getKeyResultProgress,
};

export default overTimeCharts;
