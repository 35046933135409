import styled from 'styled-components';

const LocaleOption = styled.li`
  margin-left: 0.5rem;
  float: left;

  &:first-child {
    margin-left: 0;
  }
`;

export default LocaleOption;
