import { Entity, Item } from '../types';

const flattenItems: (nestedItems: Entity[]) => Item[] = nestedItems =>
  nestedItems.reduce((prevValue, currentValue) => {
    switch (currentValue.entityType) {
      case 'group': {
        // This is the case when items belong to a group
        // => items: [{entityType: 'group' , items:[] }]
        const { items, ...group } = currentValue;
        return items
          ? [...prevValue, { ...group }, ...items.map(item => ({ ...item }))]
          : [...prevValue, { ...group }];
      }
      case 'person':
      case 'team':
      case 'goal':
      case 'label':
      case 'separator':
      case 'lazyItem':
        // This is the case when item doesn't belong to a group
        return [...prevValue, currentValue];

      default: {
        const _exhaustiveCheck: never = currentValue;
        return _exhaustiveCheck;
      }
    }
  }, []);

const getFilteredItems: (items: Entity[], inputValue: string) => Entity[] = (
  items,
  inputValue
) => {
  if (!inputValue) return items;

  return items.filter(item => {
    switch (item.entityType) {
      case 'separator':
        return true;
      case 'group':
      case 'person':
      case 'team':
      case 'label':
        return item.title.toLowerCase().startsWith(inputValue.toLowerCase());
      case 'goal':
        return item.title.toLowerCase().includes(inputValue.toLowerCase());
      case 'lazyItem':
        // fixme extend this for lazyitems
        return true;

      default: {
        const _exhaustiveCheck: never = item;
        return _exhaustiveCheck;
      }
    }
  });
};

export { flattenItems, getFilteredItems };
