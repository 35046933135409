import styled from 'styled-components';
import { getBaseUnit, getColor } from '../../styles/themeGetters';

export const TBody = styled.tbody<TBodyProps>`
  background: ${getColor('white')};
  border-bottom-left-radius: ${getBaseUnit(2)};
  border-bottom-right-radius: ${getBaseUnit(2)};
  box-shadow: ${({ noShadow }) =>
    !noShadow && `0 0 0 1px ${getColor('grey-light-100')}`};

  tr {
    height: 72px;
  }
`;

type TBodyProps = {
  noShadow?: boolean;
};

TBody.defaultProps = {
  noShadow: false,
};
