import React from 'react';
import styled, { css } from 'styled-components';
import { getBaseUnit, getColor } from '../../../styles/themeGetters';
import { Box } from '../../../Box';
import { Text } from '../../../Text';
import { FontWeightKeys } from '../../../styles/themes';

type ItemProps = {
  $clickable: boolean;
  $disabled: boolean;
  $suffix: React.ReactNode;
};

const hoverStyles = css<ItemProps>`
  &:hover {
    background-color: ${getColor('grey-light-50')};
    cursor: ${props => (props.$suffix ? 'initial' : 'pointer')};
  }
`;

const disabledStyles = css`
  &:hover {
    background-color: ${getColor('grey-light-50')};
    cursor: not-allowed;
  }
`;

const Item = styled.li<ItemProps>`
  padding: 6px 12px 6px 16px;
  display: flex;
  justify-content: space-between;

  transition: background-color 0.2s ease-in-out;

  ${props => props.$clickable && hoverStyles};
  ${props => props.$disabled && disabledStyles};
`;

const Content = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

type Props = {
  avatar?: React.ReactNode;
  label: string;
  suffix?: React.ReactNode;
  suffixWidth?: string;
  fontWeight?: FontWeightKeys;
  clickable?: boolean;
  disabled?: boolean;
};

const MultiselectMenuItem = ({
  avatar,
  label,
  fontWeight = 'regular',
  suffix,
  clickable = true,
  disabled = false,
  suffixWidth,
}: Props) => {
  return (
    <Item $clickable={clickable} $suffix={suffix} $disabled={disabled}>
      <Content>
        {avatar}
        <Text fontWeight={fontWeight} color={disabled ? 'grey' : 'black'}>
          {label}
        </Text>
      </Content>
      <Box height={getBaseUnit(6)} width={suffixWidth || getBaseUnit(6)}>
        {suffix}
      </Box>
    </Item>
  );
};

export { MultiselectMenuItem };
