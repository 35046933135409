import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgDragIndicator = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.333 5.667a1.333 1.333 0 1 0 0-2.667 1.333 1.333 0 0 0 0 2.667Zm0 4a1.333 1.333 0 1 0 0-2.667 1.333 1.333 0 0 0 0 2.667Zm1.334 2.666a1.333 1.333 0 1 1-2.667 0 1.333 1.333 0 0 1 2.667 0Zm4.666-6.666a1.333 1.333 0 1 0 0-2.667 1.333 1.333 0 0 0 0 2.667Zm1.334 2.666a1.333 1.333 0 1 1-2.667 0 1.333 1.333 0 0 1 2.667 0Zm-1.334 5.334a1.333 1.333 0 1 0 0-2.667 1.333 1.333 0 0 0 0 2.667Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgDragIndicator);
export default ForwardRef;
