import { getRequest, getFileRequest } from '../../methods';
import { buildQueryString } from '../../lib/query';

const searchUsers = (query, filter = '') =>
  filter
    ? getRequest(
        `users/search?filter=${filter}&query=${encodeURIComponent(query)}`
      )
    : getRequest(`users/search?query=${encodeURIComponent(query)}`);

const searchTeams = query =>
  getRequest(`teams/search?query=${encodeURIComponent(query)}`);
const searchCollaborators = (query, options) =>
  getRequest(`collaborators/search?${buildQueryString({ query, ...options })}`);
const searchGoals = query => getRequest(`goals/search?${query}`);

const requestExport = (query, format) =>
  getFileRequest(`goals/export?${query}&file_format=${format}`);

const checkExport = (query, format) =>
  getRequest(`goals/check_export?${query}&file_format=${format}`);

const downloadExport = key => getRequest(`goals/download_export?key=${key}`);

const search = {
  searchUsers,
  searchTeams,
  searchCollaborators,
  searchGoals,

  requestExport,
  checkExport,
  downloadExport,
};

export default search;
