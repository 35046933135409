import { css } from 'styled-components';

const small = css`
  line-height: 1.7rem;
  font-size: 0.75rem;
`;

const regular = css`
  line-height: 2.5rem;
`;

const buttonSizes = {
  small,
  regular,
};

export default buttonSizes;
