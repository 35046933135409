import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgExport = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.471 6.471a.748.748 0 0 1 0 1.058L8 13 2.532 7.532a.753.753 0 1 1 1.065-1.064l3.653 3.66V1.75a.75.75 0 0 1 1.5 0v8.377l3.664-3.656a.748.748 0 0 1 1.057 0ZM2.75 13.5a.75.75 0 0 0 0 1.5h10.5a.75.75 0 0 0 0-1.5H2.75Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgExport);
export default ForwardRef;
