import React from 'react';

import { Box } from '../../../Box';

type DrawerBodyProps = {
  children: React.ReactNode;
};

const DrawerBody = ({ children }: DrawerBodyProps) => (
  <Box width="100%" height="100%" pr={8} pb={8} pl={8} overflow="auto">
    {children}
  </Box>
);

export { DrawerBody };
