import React from 'react';

import { AvatarGroupProps } from './types';
import { HorizontalOverlappingAvatars } from './HorizontalOverlappingAvatars';
import { transformUserToAvatar } from './transformUserToAvatar';

/**
 * Render a list of `users` as an AvatarGroup.
 *
 * Avatars will be rendered next to and slightly overlapping each other.
 * When the list has more than `truncateAfter` elements, the list will be truncated,
 * and a (+N) - placeholder is shown that `onClick` will show a
 * Menu listing containing the remaining avatars.
 *
 * If `navigateTo` is set via `BaukastenContext`,
 * clicking on an Avatar will navigate to that user's profile page.
 */
const AvatarGroup = ({ users, truncateAfter }: AvatarGroupProps) => {
  const userAvatars = users.map(transformUserToAvatar);

  return (
    <HorizontalOverlappingAvatars
      avatars={userAvatars}
      truncateAfter={truncateAfter}
    />
  );
};

export { AvatarGroup };
