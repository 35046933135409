import { camelize } from 'humps';

export function hasAttributeError(error, attribute) {
  if (!validateErrorPresence(error)) return false;
  if (error.response.status !== 422) return false;

  return error.body.errors.some(err => attribute === err.attribute);
}

export function hasOnlyAllowedAttributeErrors(error, allowedAttributes) {
  if (!validateErrorPresence(error)) return false;
  if (error.response.status !== 422) return false;

  return error.body.errors.every(err =>
    allowedAttributes.includes(err.attribute)
  );
}

export function errorMessage(error) {
  if (!validateErrorPresence(error)) return error.message;

  return error.body.errors[0].message;
}

export function attributeErrorMessages(error) {
  if (!validateErrorPresence(error)) return {};

  const result = {};
  error.body.errors.forEach(err => {
    result[camelize(err.attribute)] = err.message;
  });

  return result;
}

// private
function validateErrorPresence(error) {
  if (!error) return false;
  if (!error.body) return false;
  if (!error.body.errors) return false;
  return true;
}
