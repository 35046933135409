import { css } from 'styled-components';
import colors from './colors';
import shadows from './shadows';

export const hideText = css`
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
`;

export function breakOverflowingText() {
  return css`
    word-break: break-word;
    hyphens: auto;
  `;
}

export function backgroundImage(img, img2x) {
  return css`
    background-image: url(${img});

    @media only screen and (min-resolution: 2dppx) {
      background-image: url(${img2x});
      background-size: 100%;
    }
  `;
}

export const formLabel = css`
  display: block;
  margin-bottom: 4px;
  color: #333333;
  font-size: 14px;
`;

export const formControl = css`
  display: inline-block;
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 0.25rem;
  background-color: ${colors.inputBackground};
  border: 1px solid ${colors.inputBorder};
  border-radius: 0.25rem;
  color: ${colors.inputText};
  font-family: inherit;

  line-height: normal;
  outline: 0;

  transition: box-shadow 0.25s ease-out, border-color 0.25s ease-out;

  ${props =>
    props.size === 'small' &&
    `
    padding: 7px 10px;
    font-size: 12px;
  `}
  ${props =>
    props.usage === 'conversation' &&
    `
    padding:5px 10px;
    font-size: 16px;
  `}
  
  &:hover {
    border-color: ${colors.inputBorderHoverFocus};
  }

  &:active,
  &:focus {
    border-color: ${colors.inputBorderHoverFocus};
    box-shadow: ${shadows.input};
  }

  &::-moz-placeholder {
    color: ${colors.inputPlaceholderText};
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: ${colors.inputPlaceholderText};
    opacity: 1;
  }

  &::-webkit-input-placeholder {
    color: ${colors.inputPlaceholderText};
    opacity: 1;
  }

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    background-color: ${colors.inputBackgroundDisabled};

    &:hover,
    &:focus,
    &:active {
      border-color: ${colors.inputBorder};
      box-shadow: none;
    }
  }

  ${props =>
    props.invalid &&
    `
    border-color: ${colors.inputBorderInvalid};

    &:hover,
    &:focus,
    &:active {
      border-color: ${colors.inputBorderInvalid};
    }
  `};
`;

export const clickable = css`
  transition: all 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);

  cursor: pointer;

  &:hover {
    background-color: ${colors.focusBackgroundLight};
  }

  &:active {
    background-color: ${colors.activeBackgroundLight};
  }
`;

export const clickableIcon = css`
  cursor: pointer;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
`;

const helpers = {
  hideText,
  breakOverflowingText,
  backgroundImage,
  formControl,
  formLabel,
  clickable,
  clickableIcon,
};

export default helpers;
