import React from 'react';
import getWrappedDisplayName from './getWrappedDisplayName';

const withProps = overwriteProps => WrappedComponent => {
  const WithProps = props => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <WrappedComponent {...props} {...overwriteProps} />
  );

  WithProps.displayName = getWrappedDisplayName(WrappedComponent, 'WithProps');
  return WithProps;
};

export default withProps;
