import React from 'react';
import { InputControl } from '../components/InputControl';
import { InputField } from '../components/InputField';
import { InputFieldBody } from '../components/InputFieldBody';
import { Text } from '../../Text';
import { CommonProps } from '../types';
import { Box } from '../../Box';

type Props = {
  prefixText?: string;
  suffixText?: string;
} & CommonProps;

const InputNumber = (props: Props) => {
  const {
    name,
    placeholder,
    error,
    disabled,
    helpText,
    label,
    prefixText,
    validations = {},
    formHooks: { register },
    condensed,
  } = props;

  return (
    <InputControl
      error={error}
      helpText={helpText}
      label={label}
      htmlFor={name}
      condensed={condensed}
    >
      <InputField hasError={Boolean(error)} disabled={disabled}>
        {prefixText && (
          <Box alignSelf="center">
            <Text color="grey-dark" mr={2}>
              {prefixText}
            </Text>
          </Box>
        )}
        <InputFieldBody
          disabled={disabled}
          placeholder={placeholder}
          register={register}
          name={name}
          validations={validations}
          type="number"
        />
      </InputField>
    </InputControl>
  );
};

export { InputNumber };
