import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgLongTermGoal = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 18 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <circle cx={6.3} cy={6.3} r={5.3} stroke={_.color} strokeWidth={2} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 11.994A6.3 6.3 0 0 0 9 .606a6.3 6.3 0 0 0 0 11.388Z"
      fill={_.color}
    />
    <circle cx={11.7} cy={6.3} r={5.3} stroke={_.color} strokeWidth={2} />
  </svg>
);
const ForwardRef = forwardRef(SvgLongTermGoal);
export default ForwardRef;
