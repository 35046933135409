import styled from 'styled-components';
import { getColor } from '../../styles/themeGetters';

type THProps = {
  pl?: string | number;
};

export const TH = styled.th<THProps>`
  color: ${getColor('black')};
  padding: 0;
  padding-left: ${({ pl }) => (pl ? `${pl}px` : '16px')};
`;
