import {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest,
} from '../../methods';

// Initiatives
const createInitiative = (goalId, payload) =>
  postRequest(`goals/${goalId}/initiatives`, payload);
const updateInitiative = (initiativeId, payload) =>
  patchRequest(`initiatives/${initiativeId}`, payload);
const deleteInitiative = initiativeId =>
  deleteRequest(`initiatives/${initiativeId}`);
const getInitiative = initiativeId => getRequest(`initiatives/${initiativeId}`);

const updateInitiativePosition = (initiativeId, payload) =>
  patchRequest(`initiatives/${initiativeId}/position`, payload);

const createInitiativeStatusUpdate = (initiativeId, payload) =>
  patchRequest(`initiatives/${initiativeId}/update_status`, payload);

const initiatives = {
  createInitiative,
  updateInitiative,
  deleteInitiative,
  getInitiative,

  updateInitiativePosition,

  createInitiativeStatusUpdate,
};

export default initiatives;
