import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgCollapseAll = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <rect y={3} width={8} height={1.5} rx={0.75} fill={_.color} />
    <rect y={7} width={8} height={1.5} rx={0.75} fill={_.color} />
    <rect y={11} width={8} height={1.5} rx={0.75} fill={_.color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.193 9.79a.768.768 0 0 1 0-1.02l2.341-2.56a.62.62 0 0 1 .932 0l2.341 2.56a.768.768 0 0 1 0 1.02.62.62 0 0 1-.931 0L13 7.737 11.124 9.79a.62.62 0 0 1-.931 0Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCollapseAll);
export default ForwardRef;
