import { Theme } from 'baukasten';

const colors = {};

// Goal Colors
colors.personalGoal = Theme.getColor('blue');
colors.teamGoal = Theme.getColor('lilac');
colors.organizationGoal = '#5FC4D2';
colors.draftGoal = Theme.getColor('grey');
colors.greyDark = Theme.getColor('grey-dark');
colors.businessUnitRoot = '#C9E3FB';

// Product Scopes
colors.checkIns = '#eb5e8a';

// Background
colors.lightBg = '#fcfcfc';
colors.whiteBg = '#ffffff';

// Text
colors.darkerText = '#444';
colors.darkText = '#575757';
colors.text = '#212529';
colors.lightText = '#51575d';
colors.lighterText = '#8a9293';
colors.lightestText = '#ffffff';

// Icons
colors.icon = '#a2a5a9';
colors.lighterIcon = '#c5c7c9';

// States
colors.focusBackground = '#e6f3ff';
colors.activeBackground = '#f3f3ff';

colors.focusBackgroundLight = '#f6f6f6';
colors.activeBackgroundLight = '#f0f0f0';

// Divider
colors.dividerLight = '#e0e0e0';
colors.divider = '#f3f3f3';
colors.dividerDark = '#eaeaea';

// Links
colors.linkColor = '#2684ff';

// Mentions
colors.mentionLinkText = '#575f67';
colors.mentionLinkBackground = '#e6f3ff';
colors.mentionLinkTextHover = '#677584';
colors.mentionLinkBackgroundHover = '#edf5fd';
colors.mentionLinkTextActive = '#222';
colors.mentionLinkBackgroundActive = '#455261';

// Tags
colors.roleTagText = colors.lightText;
colors.roleTagBorder = colors.lighterText;

// Buttons
colors.buttonText = '#fafafa';

colors.buttonPrimary = '#64aad2';
colors.buttonPrimaryHover = '#3d94c7';
colors.buttonPrimaryInvertedHover = colors.buttonPrimary;
colors.buttonPrimaryInvertedFocus = colors.buttonPrimaryHover;

colors.buttonSecondary = '#f6f6f6';
colors.buttonSecondaryHover = '#fbfbfb';
colors.buttonSecondaryBorder = '#eaeaea';
colors.buttonSecondaryText = '#555459';

colors.buttonSuccess = Theme.getColor('navy');
colors.buttonSuccessHover = Theme.getColor('navy-dark');
colors.buttonSuccessInvertedHover = colors.buttonSuccess;
colors.buttonSuccessInvertedFocus = colors.buttonSuccessHover;

colors.buttonDanger = Theme.getColor('red');
colors.buttonDangerHover = Theme.getColor('red-dark');
colors.buttonDangerInvertedHover = colors.buttonDanger;
colors.buttonDangerInvertedFocus = colors.buttonDangerHover;

// Inputs
colors.inputText = '#656565';
colors.inputPlaceholderText = colors.lighterText;
colors.inputBorder = '#eaeaea';
colors.inputBorderHoverFocus = '#338bff';
colors.inputBorderInvalid = '#d9534f';
colors.inputBackground = '#fff';
colors.inputBackgroundDisabled = '#f5f5f5';
colors.inputCheckBoxBackground = 'rgba(51, 139, 255, 0.7)';

// ProgressCircle
colors.progressFg = '#3199FA';
colors.progressBg = '#CFCFCF';
colors.progressDone = colors.buttonSuccess;

// State
colors.stateInfoText = '#455a64';
colors.stateInfoBg = '#cfd8dc';
colors.stateInfoBorder = '#C8D3D7';

colors.stateSuccessText = colors.buttonSuccess;
colors.stateSuccessBg = '#b9f6ca';
colors.stateSuccessBorder = '#9DF0C1';

colors.stateWarningText = '#996600';
colors.stateWarningBg = '#ffe57f';
colors.stateWarningBorder = '#FFDF5E';

colors.stateDangerText = colors.buttonDanger;
colors.stateDangerBg = '#ff9e80';
colors.stateDangerBorder = '#FE9272';

// Sub Nav Bar
colors.subNavBarGoalsBg = '#E8EBF2';
colors.subNavBarGoalsGradient =
  'linear-gradient(-224deg, rgba(55, 91, 209, 0.08), rgba(237, 30, 121, 0.08))';

colors.subNavBarGoalsDraftBg = '#4669a4';
colors.subNavBarGoalsDraftGradient =
  'repeating-linear-gradient(135deg,transparent,transparent 5px,rgb(160 160 160 / 5%) 5px,rgb(113 113 113 / 5%) 10px)';

colors.subNavBarCheckInsBg = colors.checkIns;
colors.subNavBarCheckInsGradient =
  'linear-gradient(-224deg, rgba(231, 53, 109, 0.08), rgba(235, 94, 138, 0.08))';

colors.subNavBarAnalyticsBg = '#4E5768';
colors.subNavBarAnalyticsGradient =
  'linear-gradient(-224deg, rgba(55, 91, 209, 0.08), rgba(237, 30, 121, 0.08))';

colors.subNavDivider = '#8292C9';

// Team Member Reports
colors.teamMemberReportsAnswerHoverBg = '#fafafa';
colors.teamMemberReportsAnswerFocusBg = '#fff';

colors.teamMemberReportsDuplicatedAnswerHoverBg = '#fafafa';
colors.teamMemberReportsDuplicatedAnswerFocusBg = '#fafafa';

// Key Results Connector
colors.keyResultsConnectorIconConnected = colors.stateSuccessText;
colors.keyResultsConnectorIconDisconnected = colors.lighterText;

colors.keyResultsConnectorCircleIconConnected = '#fff';
colors.keyResultsConnectorCircleIconDisconnected =
  colors.keyResultsConnectorIconDisconnected;

colors.keyResultsConnectorCircleBackgroundColorConnected =
  colors.keyResultsConnectorIconConnected;

colors.keyResultsConnectorCircleBorderConnected =
  colors.keyResultsConnectorIconConnected;
colors.keyResultsConnectorCircleBorderDisconnected = colors.lighterText;

export default colors;
