import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgUser = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      d="M2.491 15H13.51c.464 0 .828-.098 1.093-.294.265-.191.398-.46.398-.806 0-.49-.163-1.011-.489-1.564-.326-.552-.795-1.066-1.408-1.54-.607-.48-1.342-.87-2.204-1.17-.861-.304-1.83-.457-2.907-.457-1.072 0-2.038.153-2.9.457-.861.3-1.599.69-2.212 1.17-.607.474-1.074.988-1.4 1.54C1.16 12.89 1 13.41 1 13.9c0 .346.133.615.398.806.265.196.63.294 1.093.294ZM8 7.799a3.18 3.18 0 0 0 1.665-.457 3.453 3.453 0 0 0 1.218-1.231 3.38 3.38 0 0 0 .464-1.758 3.2 3.2 0 0 0-.464-1.704 3.348 3.348 0 0 0-1.218-1.208A3.264 3.264 0 0 0 8 1c-.596 0-1.149.15-1.657.45-.508.299-.917.704-1.226 1.215a3.245 3.245 0 0 0-.455 1.703c0 .646.151 1.226.455 1.743.31.516.715.926 1.218 1.23A3.18 3.18 0 0 0 8 7.8Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgUser);
export default ForwardRef;
