import React from 'react';

import { Box } from '../../../Box';
import { Button } from '../../../Button';
import { Icons } from '../../../Icons';
import { Text } from '../../../Text';

type DrawerHeaderProps = {
  onDrawerClose: () => void;
  title: string | JSX.Element;
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
};

const DrawerHeader = ({
  onDrawerClose,
  title,
  leftContent,
  rightContent,
}: DrawerHeaderProps) => {
  const titleContent =
    typeof title === 'string' ? (
      <Text fontSize={24} fontWeight="bold" truncated>
        {title}
      </Text>
    ) : (
      title
    );

  return (
    <Box pt={6} pr={8} pb={6} pl={8}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pb={6}
      >
        <Box display="flex" flex="1" justifyContent="space-between" mr={1}>
          <Box>{leftContent}</Box>
          <Box>{rightContent}</Box>
        </Box>
        <Button
          size="s"
          variant="text"
          onClick={onDrawerClose}
          startIcon={Icons.Close}
          aria-label="close drawer"
        />
      </Box>
      {titleContent}
    </Box>
  );
};

export { DrawerHeader };
