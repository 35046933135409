import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgSolidChevronUp = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.873 9.963 8.451 5.24a.542.542 0 0 0-.812-.1.612.612 0 0 0-.09.1L4.127 9.963a.684.684 0 0 0 .09.897c.102.09.23.14.361.14h6.844c.32 0 .578-.286.578-.638a.681.681 0 0 0-.127-.4Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgSolidChevronUp);
export default ForwardRef;
