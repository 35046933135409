import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgExecutePhase = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm6-3.45a.75.75 0 0 0 0 1.5h7a.75.75 0 0 0 0-1.5h-7Zm0 7.7a.75.75 0 0 0 0 1.5h7a.75.75 0 0 0 0-1.5h-7ZM7 17.4a1.4 1.4 0 1 0 0-2.8 1.4 1.4 0 0 0 0 2.8ZM7 19a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgExecutePhase);
export default ForwardRef;
