import React from 'react';
import { InputControl } from '../components/InputControl';
import { InputField } from '../components/InputField';
import { InputFieldBody } from '../components/InputFieldBody';
import { CommonProps } from '../types';

const InputDateTime = ({
  name,
  label,
  helpText,
  error,
  disabled,
  validations,
  min,
  max,
  step,
  type,
  formHooks: { register },
  condensed,
}: CommonProps & {
  min?: string;
  max?: string;
  step?: string;
  type: 'time' | 'date';
}) => {
  return (
    <InputControl
      error={error}
      helpText={helpText}
      label={label}
      htmlFor={name}
      condensed={condensed}
    >
      <InputField hasError={Boolean(error)} disabled={disabled}>
        <InputFieldBody
          disabled={disabled}
          register={register}
          name={name}
          validations={validations}
          min={min}
          max={max}
          step={step}
          type={type}
        />
      </InputField>
    </InputControl>
  );
};

export { InputDateTime };
