import React, { useState } from 'react';

import {
  SegmentedControlSlide,
  SegmentedRadioControl,
  SegmentedControlGroup,
  SegmentedControlLabel,
  SegmentedControlContainer,
} from './SegmentedControl.styles';
import { SegmentedControlProps } from './types';

export const RawSegmentedControl = ({
  disabled = false,
  onChange,
  items = [],
  initialSelectedId,
}: SegmentedControlProps) => {
  const [selectedSegmentId, setSelectedSegmentId] = useState(initialSelectedId);

  const handleSelectSegment = (segmentId: string): void => {
    setSelectedSegmentId(segmentId);
    onChange(segmentId);
  };

  const slidePosition = items.findIndex(item => item.id === selectedSegmentId);

  if (!items.length) {
    return null;
  }

  return (
    <SegmentedControlContainer>
      <SegmentedControlGroup>
        {items.map(({ id, label }, index) => (
          <div key={id}>
            <SegmentedRadioControl
              disabled={disabled}
              type="radio"
              id={label}
              name="segments"
              value={label}
            />
            <SegmentedControlLabel
              onClick={() => {
                handleSelectSegment(id);
              }}
              htmlFor={label}
            >
              <span>{label}</span>
              {/*
                SegmentedControlSlide placed inside SegmentedControlLabel to inherit the same width from the label, and index === 0 is a small hack that will render only one slide
               */}
              {selectedSegmentId && index === 0 && (
                <SegmentedControlSlide position={slidePosition} />
              )}
            </SegmentedControlLabel>
          </div>
        ))}
      </SegmentedControlGroup>
    </SegmentedControlContainer>
  );
};
