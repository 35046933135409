import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgSendMe = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.509 15H3.49c-.464 0-.828-.098-1.093-.294-.265-.191-.398-.46-.398-.806 0-.49.16-1.011.48-1.564.326-.552.793-1.066 1.4-1.54.613-.48 1.35-.87 2.212-1.17.862-.304 1.828-.457 2.9-.457 1.077 0 2.046.153 2.907.457.862.3 1.597.69 2.204 1.17.613.474 1.082.988 1.408 1.54.326.553.489 1.074.489 1.564 0 .346-.133.615-.398.806-.265.196-.63.294-1.093.294Zm-3.844-7.658A3.18 3.18 0 0 1 9 7.799a3.18 3.18 0 0 1-1.665-.457A3.545 3.545 0 0 1 6.117 6.11a3.37 3.37 0 0 1-.455-1.743c0-.63.151-1.197.455-1.703.31-.511.718-.916 1.226-1.216C7.851 1.15 8.403 1 9 1c.602 0 1.157.147 1.665.441.508.295.914.697 1.218 1.208a3.2 3.2 0 0 1 .464 1.704 3.38 3.38 0 0 1-.464 1.758c-.304.516-.71.926-1.218 1.23Zm-8.882.793L0 8.15V6.884l1.79-.029.012-1.421a.411.411 0 0 1 .103-.307.416.416 0 0 1 .272-.125c.11-.01.221.02.337.09l3.26 1.98c.1.06.167.143.2.25a.5.5 0 0 1 .002.315.438.438 0 0 1-.201.252l-3.29 2.016a.56.56 0 0 1-.339.094.407.407 0 0 1-.27-.122c-.07-.073-.106-.177-.105-.31l.012-1.432Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgSendMe);
export default ForwardRef;
