// Docs: https://github.com/WorkpathHQ/workpath_web/wiki/Container-Reducer-Docs
import {
  GET_GOAL_PROGRESS_OVER_TIME_REQUEST,
  GET_GOAL_PROGRESS_OVER_TIME_SUCCESS,
  GET_GOAL_PROGRESS_OVER_TIME_ERROR,
} from './constants';

export const initialState = {
  getGoalProgressOverTimePending: true,
  goalProgressOverTime: {},
  goal: {
    title: '',
    permissions: { updateProgress: false },
    keyResultsCount: 0,
  },
  getGoalProgressOverTimeError: undefined,
  graphThresholds: {},
  feedEntries: [],
};

const goalProgressOverTimeModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GOAL_PROGRESS_OVER_TIME_REQUEST:
      return { ...state, getGoalProgressOverTimePending: true };
    case GET_GOAL_PROGRESS_OVER_TIME_SUCCESS:
      return {
        ...state,
        getGoalProgressOverTimePending: false,
        progress: action.payload.progress,
        confidenceLevel: action.payload.confidenceLevel,
        goal: action.payload.goal,
        feedEntries: action.payload.feedEntries,
      };
    case GET_GOAL_PROGRESS_OVER_TIME_ERROR:
      return {
        ...state,
        getGoalProgressOverTimePending: false,
        getGoalProgressOverTimeError: action.payload.error,
      };

    default:
      return state;
  }
};

export default goalProgressOverTimeModalReducer;
