import auth from 'utils/auth';
import { getSubdomain } from 'utils/browser';

export default function headers() {
  const requestHeaders = new Headers({
    'Content-Type': 'application/json',
    'X-Account': getSubdomain(),
  });

  if (auth.isLoggedIn) {
    const token = auth.getToken();
    requestHeaders.append('Authorization', `Token token="${token}"`);
  }

  return requestHeaders;
}
