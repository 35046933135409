import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgDanger = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.843 12.185 9.002 1.605a1.132 1.132 0 0 0-2.004 0l-5.841 10.58C.71 12.992 1.268 14 2.158 14h11.683c.891 0 1.448-1.008 1.002-1.815ZM8.076 4.288h-.28c-.45 0-.811.522-.796.912l.167 3.134c.015.371.367.666.797.666.43 0 .783-.295.797-.666L8.873 5.2c.015-.39-.346-.912-.797-.912Zm-.112 7.785c-.532 0-.964-.45-.964-1.008 0-.556.432-1.008.964-1.008s.964.451.964 1.008c0 .557-.432 1.008-.964 1.008Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgDanger);
export default ForwardRef;
