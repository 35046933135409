import {
  SEARCH_PROFILE_REQUEST,
  SEARCH_PROFILE_SUCCESS,
  SEARCH_PROFILE_ERROR,
  RESET_STATE,
} from './constants';

export const initialState = {};

function profileInputReducer(state = initialState, action) {
  switch (action.type) {
    case SEARCH_PROFILE_REQUEST:
      return {
        ...state,
        searchProfilePending: true,
      };
    case SEARCH_PROFILE_SUCCESS:
      return {
        ...state,
        searchProfilePending: false,
        profiles: action.response.results,
      };
    case SEARCH_PROFILE_ERROR:
      return {
        ...state,
        searchProfilePending: false,
        searchProfileError: action.error,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

export default profileInputReducer;
