import { JSONContent } from '@tiptap/react';

import { escapeCurlyBraces } from 'utils/sanitizer';

type TextNode = {
  type: 'text';
  text: string;
};

type MentionNode = {
  type: 'mention';
  attrs: {
    id: string;
  };
};

type ParagraphNode = {
  type: 'paragraph';
  content: Array<TextNode | MentionNode>;
};

type Node = TextNode | MentionNode | ParagraphNode;

const transformTextNode = (node: TextNode): string => node.text;

const transformMentionNode = (node: MentionNode): string =>
  node.attrs ? `{{user_mention|user_id:${node.attrs.id}}}` : '';

const transformNodeContent = (nodeContent: Array<Node>): string => {
  return nodeContent
    .map(childNode => {
      switch (childNode.type) {
        case 'text':
          return escapeCurlyBraces(transformTextNode(childNode as TextNode));
        case 'mention':
          return transformMentionNode(childNode as MentionNode);
        default:
          return '';
      }
    })
    .join('');
};

/**
 * Transforms a MentionsTextArea JSON string output into a compatible format for the backend to parse mentions.
 *
 * Output example: {{user_mention|user_id:123}}
 *
 * Usage example:
 *   const editorContent: JSONContent = editor.getJSON();
 *   transformOutput(editorContent);
 */
export const transformTipTapMentionsForServer = (
  mentionsTextAreaOutput: JSONContent
): string => {
  const { content } = mentionsTextAreaOutput;

  return content
    .filter((node: Node) => node.type === 'paragraph')
    .map(
      (node: ParagraphNode) =>
        node.content && transformNodeContent(node.content)
    )
    .join('');
};
