import { FilterFunctionProps, FilterIdentifier } from './types';
import { makeFilter } from './makeFilter';
import { matchTeam } from './matchTeam';
import { matchAllTalkingPoints } from './matchAllTalkingPoints';
import { matchAchievements } from './matchAchievements';
import { matchAtRisk } from './matchAtRisk';
import { Row } from '../../types';
import { matchUnresolvedNotes } from './matchUnresolvedNotes';
import { matchAtRecentlyUpdated } from './matchAtRecentlyUpdated';

type MakeFilterRows = (
  selectedFilter: FilterIdentifier,
  props: FilterFunctionProps
) => (rows: Row[]) => Row[];

export const makeFilterRows: MakeFilterRows = (selectedFilter, props) => {
  switch (selectedFilter) {
    case 'allItems':
      return rows => rows;
    case 'noItems':
      return () => [];
    case 'myTeam':
      return makeFilter(matchTeam, props);
    case 'allTalkingPoints':
      return makeFilter(matchAllTalkingPoints, props);
    case 'achievements':
      return makeFilter(matchAchievements, props);
    case 'atRisk':
      return makeFilter(matchAtRisk, props);
    case 'recentlyUpdated':
      return makeFilter(matchAtRecentlyUpdated, props);
    case 'atRiskForNotes':
      // reusing the atRisk matcher here, but forcing talking points to be empty so they don't interfere
      // to be removed later
      return makeFilter(matchAtRisk, { ...props, talkingPoints: [] });
    case 'unresolvedNotes':
      return makeFilter(matchUnresolvedNotes, props);
    default: {
      // this is to make a missing filter a type error. see
      // https://www.typescriptlang.org/docs/handbook/2/narrowing.html#exhaustiveness-checking
      // for how this works.
      // eslint-disable-next-line no-underscore-dangle
      const _exhaustiveCheck: never = selectedFilter;
      return _exhaustiveCheck;
    }
  }
};
