import { getEnvConfig } from 'utils/getEnvConfig';

export default function urlForEndpoint(endpoint) {
  return `${getEnvConfig('API_BASE')}/v1/${endpoint}`;
}

function urlForEndpointV2(endpoint) {
  return `${getEnvConfig('API_BASE')}/v2/${endpoint}`;
}

function urlForGraphQL() {
  return `${getEnvConfig('API_BASE')}/graphql`;
}

export { urlForEndpointV2, urlForGraphQL };
