import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgInitiativeJira = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      stroke={_.color}
      strokeWidth={3}
      d="M6.121 7.95 8.243 5.83l2.12 2.121-2.12 2.121z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgInitiativeJira);
export default ForwardRef;
