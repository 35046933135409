import { format, parseISO } from "date-fns";

type RelativeTimeFormatUnit =
  | 'year'
  | 'quarter'
  | 'month'
  | 'week'
  | 'day'
  | 'hour'
  | 'minute'
  | 'second';

export type RelativeDate = {
  unit: RelativeTimeFormatUnit;
  value: number;
};

/**
 * @typedef {Object} RelativeDateTime
 * @property {number} value - Value for relative datetime
 * @property {RelativeTimeFormatUnit} unit - unit for relative datetime, i.e. 'second'
 */
/**
 * Automatically calculate value and units for Intl.RelativeTimeFormat
 * INTL, eg:
 *  Intl.RelativeTimeFormat(locale, { numeric: "auto" }).format(
 *  value: getRelativeDateUnits(date).value,
 *   unit: getRelativeDateUnits(date).unit
 *  );
 *
 * @param {string} date
 * @returns {RelativeDate}
 */
export const getRelativeDateUnits = (date: string): RelativeDate => {
  const THRESHOLDS = {
    SECONDS: 60,
    MINUTES: 60,
    HOURS: 24,
    DAYS: 14,
    WEEKS: 8,
    MONTHS: 24,
  };
  const MS_PER_SECOND = 1000;
  const SECS_PER_MIN = 60;
  const SECS_PER_HOUR = SECS_PER_MIN * 60;
  const SECS_PER_DAY = SECS_PER_HOUR * 24;
  const SECS_PER_WEEK = SECS_PER_DAY * 7;
  const SECS_PER_MONTH = SECS_PER_WEEK * 4.35; // average of 30.45 days
  const SECS_PER_YEAR = SECS_PER_MONTH * 12;

  const fromDate = new Date(date);
  const toDate = new Date(Date.now());
  const diff = fromDate.getTime() - toDate.getTime();

  const seconds = diff / MS_PER_SECOND;

  if (Math.abs(seconds) < THRESHOLDS.SECONDS) {
    return {
      value: Math.round(seconds),
      unit: 'second',
    };
  }

  const minutes = seconds / SECS_PER_MIN;
  if (Math.abs(minutes) < THRESHOLDS.MINUTES) {
    return {
      value: Math.round(minutes),
      unit: 'minute',
    };
  }

  const hours = seconds / SECS_PER_HOUR;
  if (Math.abs(hours) < THRESHOLDS.HOURS) {
    return {
      value: Math.round(hours),
      unit: 'hour',
    };
  }

  const days = seconds / SECS_PER_DAY;
  if (Math.abs(days) < THRESHOLDS.DAYS) {
    return {
      value: Math.round(days),
      unit: 'day',
    };
  }

  const weeks = seconds / SECS_PER_WEEK;
  if (Math.abs(weeks) < THRESHOLDS.WEEKS) {
    return {
      value: Math.round(weeks),
      unit: 'week',
    };
  }

  const months = seconds / SECS_PER_MONTH;
  if (Math.abs(months) < THRESHOLDS.MONTHS) {
    return {
      value: Math.round(months),
      unit: 'month',
    };
  }

  const years = seconds / SECS_PER_YEAR;
  return {
    value: Math.round(years),
    unit: 'year',
  };
};

/**
 * Return Date String for with yyyy-MM-dd format
 * @param {Date} date
 * @returns {string} yyyy-MM-dd, i.e. 2020-12-24
 */
export const formatDate = (date: string): string => {
  const parsedDate = parseISO(date);

  return format(parsedDate, 'yyyy-MM-dd');
};
