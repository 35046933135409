import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgFeedback = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.746 15.886a.819.819 0 0 1-.42.114c-.22 0-.392-.071-.512-.213-.116-.137-.173-.322-.173-.554v-2.058h-.297c-.698 0-1.297-.13-1.798-.39a2.772 2.772 0 0 1-1.149-1.137C.132 11.151 0 10.55 0 9.845V4.336c0-.705.132-1.303.397-1.796a2.707 2.707 0 0 1 1.149-1.142C2.05 1.133 2.668 1 3.395 1h9.21c.727 0 1.341.133 1.842.398.506.265.89.646 1.156 1.142.265.493.397 1.091.397 1.796v5.51c0 .704-.132 1.305-.397 1.802-.265.493-.65.871-1.156 1.136-.5.26-1.115.39-1.842.39H7.866l-2.65 2.357c-.184.166-.34.284-.47.355ZM4 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm4 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm5-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgFeedback);
export default ForwardRef;
