/**
 * Transforms a JSON mention into an HTML <span> element compatible with the Tiptap editor for rendering.
 *
 * This function takes a string that contains user mentions in a specific JSON format
 * and replaces each mention with an HTML span element.
 *
 * Example:
 * Input: 'Hello {{user_mention|{"id": 1, "first_name":"John","last_name":"Doe"}}}'
 * Output: 'Hello <span data-type="mention" data-id="1" data-label="John Doe"></span>'
 *
 * @param {string} JSONMention - The string containing user mentions in JSON format.
 * @returns {string} The input string with all JSON mentions replaced by HTML span elements.
 */
export const transformJSONMentionToHTML = (JSONMention?: string): string => {
  if (!JSONMention) {
    return '';
  }

  return JSONMention.replace(/{{user_mention\|({.*?})}}/g, (origin, match) => {
    try {
      const userDetails = JSON.parse(match);
      const fullName = `${userDetails.first_name} ${userDetails.last_name}`;

      return `<span data-type="mention" data-id="${userDetails.id}" data-label="${fullName}"></span>`;
    } catch (error) {
      return origin;
    }
  });
};
