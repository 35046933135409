// https://tinytip.co/tips/ts-switch-assert-unreachable/
// in switch statements, use this for the default block. this makes a missing case a typeError.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const assertUnreachable = (_value: never): never => {
  throw new Error('Statement should be unreachable');
};

export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object
    ? RecursivePartial<T[P]>
    : T[P];
};

export type ApiRequestStatus = 'idle' | 'loading' | 'success' | 'error';
