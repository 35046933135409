import {
  deleteRequestV2,
  getRequestV2,
  patchRequestV2,
  postRequestV2,
} from '../../methods';

const getAllNotesByResourceId = (resource, resourceId) =>
  getRequestV2(`notes?resource_type=${resource}&resource_id=${resourceId}`);

const getNoteById = noteId => getRequestV2(`notes/${noteId}`);

const createNote = payload => postRequestV2(`notes`, payload);

const editNoteById = (id, payload) => patchRequestV2(`notes/${id}`, payload);

const deleteNoteById = id => deleteRequestV2(`notes/${id}`);

const notes = {
  getAllNotesByResourceId,
  getNoteById,
  createNote,
  editNoteById,
  deleteNoteById,
};

export default notes;
