import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'baukasten.DataGrid.renderers.DynamicHeaderRenderer.title',
    defaultMessage: 'Title',
  },
  owner: {
    id: 'baukasten.DataGrid.renderers.DynamicHeaderRenderer.owner',
    defaultMessage: 'Owner',
  },
  team: {
    id: 'baukasten.DataGrid.renderers.DynamicHeaderRenderer.team',
    defaultMessage: 'Team',
  },
  progress: {
    id: 'baukasten.DataGrid.renderers.DynamicHeaderRenderer.progress',
    defaultMessage: 'Progress',
  },
  confidenceLevel: {
    id: 'baukasten.DataGrid.renderers.DynamicHeaderRenderer.confidenceLevel',
    defaultMessage: 'CL',
  },
  confidenceLevelTooltip: {
    id:
      'baukasten.DataGrid.renderers.DynamicHeaderRenderer.confidenceLevelTooltip',
    defaultMessage: 'Confidence level',
  },
  lastUpdated: {
    id: 'baukasten.DataGrid.renderers.DynamicHeaderRenderer.lastUpdated',
    defaultMessage: 'Updated',
  },
  actions: {
    id: 'baukasten.DataGrid.renderers.DynamicHeaderRenderer.actions',
    defaultMessage: 'Actions',
  },
  CollapseAll: {
    id: 'baukasten.DataGrid.renderers.DynamicHeaderRenderer.CollapseAll',
    defaultMessage: 'Collapse',
  },
  ExpandAll: {
    id: 'baukasten.DataGrid.renderers.DynamicHeaderRenderer.ExpandAll',
    defaultMessage: 'Expand',
  },
});
