import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  createErrorToast,
  createSuccessToast,
  clearToasts,
} from 'containers/ToastNotificationContainer/actions';
import { errorMessage } from 'utils/form';
import { intlService } from 'utils/intlService';
import api from 'utils/api';

// TODO: Please don't forget to remove pages/SettingsTeamsPage/constants and pages/SettingsTeamsPage/actions when
// refactoring NestedTeamList to react-query
import {
  ARCHIVE_TEAM_SUCCESS as SETTINGS_TEAMS_PAGE_ARCHIVE_TEAM_SUCCESS,
  RESTORE_TEAM_SUCCESS as SETTINGS_TEAMS_PAGE_RESTORE_TEAM_SUCCESS,
} from 'pages/SettingsTeamsPage/constants';

import messages from './messages';
import { ARCHIVE_TEAM_REQUEST, SEARCH_TEAMS_REQUEST } from './constants';
import {
  searchTeamsSuccess,
  searchTeamsError,
  archiveTeamSuccess,
  archiveTeamError,
  searchTeamsRequest,
} from './actions';

export function* searchTeams({ query = '', teamId }) {
  try {
    let response;

    if (teamId) {
      response = yield call(api.settingsSearchPotentialParents, teamId, query);
    } else {
      response = yield call(api.settingsSearchTeams, query);
    }

    yield put(searchTeamsSuccess(response));
  } catch (err) {
    yield put(searchTeamsError(errorMessage(err)));
  }
}

export function* archiveTeam({ teamId }) {
  yield put(clearToasts());
  try {
    yield call(api.settingsArchiveTeam, teamId);
    yield put(archiveTeamSuccess());
    yield put(
      createSuccessToast(
        intlService().formatMessage(messages.toastArchivedTeamSuccessfully)
      )
    );
    yield put(searchTeamsRequest(''));
  } catch (err) {
    yield put(archiveTeamError(err));
    yield put(createErrorToast(errorMessage(err)));
  }
}

export function* watcher() {
  yield all([
    takeLatest(ARCHIVE_TEAM_REQUEST, archiveTeam),
    takeLatest(SEARCH_TEAMS_REQUEST, searchTeams),
    takeLatest(SETTINGS_TEAMS_PAGE_ARCHIVE_TEAM_SUCCESS, searchTeams),
    takeLatest(SETTINGS_TEAMS_PAGE_RESTORE_TEAM_SUCCESS, searchTeams),
  ]);
}

export default [watcher];
