import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgLockClosed = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <g clipPath="url(#LockClosed_svg__a)">
      <path
        d="M3.857 3.938V5.25h4.286V3.937C8.143 2.73 7.183 1.75 6 1.75c-1.184 0-2.143.979-2.143 2.188ZM2.143 5.25V3.937C2.143 1.764 3.87 0 6 0c2.13 0 3.857 1.764 3.857 3.938V5.25h.429C11.23 5.25 12 6.035 12 7v5.25c0 .965-.769 1.75-1.714 1.75H1.714C.77 14 0 13.215 0 12.25V7c0-.965.769-1.75 1.714-1.75h.429Z"
        fill="#838895"
      />
    </g>
    <defs>
      <clipPath id="LockClosed_svg__a">
        <path fill="#fff" d="M0 0h12v14H0z" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgLockClosed);
export default ForwardRef;
