export const DEFAULT_LOCALE = 'en';
export const CHANGE_LOCALE = 'app/App/CHANGE_LOCALE';

export const GET_ACCOUNT_REQUEST = 'app/App/GET_ACCOUNT_REQUEST';
export const GET_ACCOUNT_SUCCESS = 'app/App/GET_ACCOUNT_SUCCESS';
export const GET_ACCOUNT_ERROR = 'app/App/GET_ACCOUNT_ERROR';

export const GET_PROFILE_REQUEST = 'app/App/GET_PROFILE_REQUEST';
export const GET_PROFILE_SUCCESS = 'app/App/GET_PROFILE_SUCCESS';
export const GET_PROFILE_ERROR = 'app/App/GET_PROFILE_ERROR';

export const UPDATE_PROFILE_REQUEST = 'app/App/UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'app/App/UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'app/App/UPDATE_PROFILE_ERROR';

export const CREATE_USER_SESSION = 'app/App/CREATE_USER_SESSION';
export const DESTROY_USER_SESSION = 'app/App/DESTROY_USER_SESSION';

export const USER_LOGOUT_REQUEST = 'app/App/USER_LOGOUT_REQUEST';
export const USER_LOGOUT_SUCCESS = 'app/App/USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_ERROR = 'app/App/USER_LOGOUT_ERROR';

export const GET_CYCLES_REQUEST = 'app/App/GET_CYCLES_REQUEST';
export const GET_CYCLES_SUCCESS = 'app/App/GET_CYCLES_SUCCESS';
export const GET_CYCLES_ERROR = 'app/App/GET_CYCLES_ERROR';

export const GET_CUSTOM_CYCLE_REQUEST = 'app/App/GET_CUSTOM_CYCLE_REQUEST';
export const GET_CUSTOM_CYCLE_SUCCESS = 'app/App/GET_CUSTOM_CYCLE_SUCCESS';
export const GET_CUSTOM_CYCLE_ERROR = 'app/App/GET_CUSTOM_CYCLE_ERROR';

export const GET_TEAMS_REQUEST = 'app/App/GET_TEAMS_REQUEST';
export const GET_TEAMS_SUCCESS = 'app/App/GET_TEAMS_SUCCESS ';
export const GET_TEAMS_ERROR = 'app/App/GET_TEAMS_ERROR';

export const SET_SELECTED_CYCLE = 'app/App/SET_SELECTED_CYCLE';
