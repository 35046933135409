import { defineMessages } from 'react-intl';

export default defineMessages({
  addKeyResult: {
    id: 'baukasten.DataGrid.renderers.ActionsStatusRender.addKeyResult',
    defaultMessage: 'Add Key Result',
  },
  addInitiative: {
    id: 'baukasten.DataGrid.renderers.ActionsStatusRender.addInitiative',
    defaultMessage: 'Add {initiative}',
  },
  draft: {
    id: 'baukasten.DataGrid.renderers.ActionsStatusRender.draft',
    defaultMessage: 'Draft',
  },
  moreOptions: {
    id: 'baukasten.DataGrid.renderers.ActionsStatusRender.moreOptions',
    defaultMessage: 'More options',
  },
  viewUpdates: {
    id: 'baukasten.DataGrid.renderers.ActionsStatusRender.viewUpdates',
    defaultMessage: 'Click to view updates',
  },
  talkingPoints: {
    id: 'baukasten.DataGrid.renderers.ActionsStatusRender.talkingPoints',
    defaultMessage: 'Talking Points',
  },
  notes: {
    id: 'baukasten.DataGrid.renderers.ActionsStatusRender.notes',
    defaultMessage: 'Notes',
  },
});
