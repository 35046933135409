import {
  RESEND_CONFIRMATION_REQUEST,
  RESEND_CONFIRMATION_SUCCESS,
  RESEND_CONFIRMATION_ERROR,
} from './constants';

export function resendConfirmationRequest(email) {
  return {
    type: RESEND_CONFIRMATION_REQUEST,
    email,
  };
}

export function resendConfirmationSuccess() {
  return {
    type: RESEND_CONFIRMATION_SUCCESS,
  };
}

export function resendConfirmationFailure(error) {
  return {
    type: RESEND_CONFIRMATION_ERROR,
    error,
  };
}
