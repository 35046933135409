import React from 'react';
import styled, { keyframes } from 'styled-components';
import { getColor } from '../styles/themeGetters';

type StyledSkeletonProps = {
  borderRadius?: string;
  $height?: string;
  $width?: string;
};

const fadeInAndOutKeyframes = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity:0.4;
  }
  100% {
    opacity: 1;
  }
`;

const StyledSkeleton = styled.div<StyledSkeletonProps>`
  animation: 1.5s ease-in-out 0.5s infinite normal none running
    ${fadeInAndOutKeyframes};
  background-color: ${getColor('grey-light-100')};
  border-radius: ${({ borderRadius }) => borderRadius};
  height: ${({ $height }) => $height};
  width: ${({ $width }) => $width};
`;

type HTMLTags = keyof JSX.IntrinsicElements;

type SkeletonProps = {
  borderRadius?: string;
  height?: string;
  width?: string;
  as?: HTMLTags;
  className?: string;
};

/**
 * Displays a placeholder preview before the data gets loaded to reduce load-time frustration.
 * It is a block element and stretches to 100% by default.
 *
 * You can customize the HTML element that should be rendered with the `as` prop. If you create a new styled component to extend the `Skeleton` styles (i.e. `const StyledSkeleton = styled(Skeleton)`), then you have to use `forwardedAs`.
 *
 * The `className` prop allows to extend the `Skeleton` component's styles (see this [GitHub issue](https://github.com/styled-components/styled-components/issues/1226)).
 */
const Skeleton = ({
  height = '8px',
  width = '100%',
  borderRadius = '8px',
  as = 'div',
  className,
}: SkeletonProps) => (
  <StyledSkeleton
    $height={height}
    $width={width}
    borderRadius={borderRadius}
    as={as}
    className={className}
  />
);

export { Skeleton };
