import {
  ARCHIVE_TEAM_REQUEST,
  ARCHIVE_TEAM_SUCCESS,
  ARCHIVE_TEAM_ERROR,
  SEARCH_TEAMS_REQUEST,
  SEARCH_TEAMS_SUCCESS,
  SEARCH_TEAMS_ERROR,
} from './constants';

export function archiveTeamRequest(teamId) {
  return {
    type: ARCHIVE_TEAM_REQUEST,
    teamId,
  };
}

export function archiveTeamSuccess() {
  return {
    type: ARCHIVE_TEAM_SUCCESS,
  };
}

export function archiveTeamError(error) {
  return {
    type: ARCHIVE_TEAM_ERROR,
    error,
  };
}

export function searchTeamsRequest(query, teamId) {
  return {
    type: SEARCH_TEAMS_REQUEST,
    query,
    teamId,
  };
}
export function searchTeamsSuccess(response) {
  return {
    type: SEARCH_TEAMS_SUCCESS,
    response,
  };
}
export function searchTeamsError(error) {
  return {
    type: SEARCH_TEAMS_ERROR,
    error,
  };
}
