import { defineMessages } from 'react-intl';

export default defineMessages({
  not_started: {
    id: 'baukasten.DataGrid.renderers.InitiativeStatusRender.not_started',
    defaultMessage: 'Not Started',
  },
  in_progress: {
    id: 'baukasten.DataGrid.renderers.InitiativeStatusRender.in_progress',
    defaultMessage: 'In Progress',
  },
  in_danger: {
    id: 'baukasten.DataGrid.renderers.InitiativeStatusRender.in_danger',
    defaultMessage: 'In Danger',
  },
  done: {
    id: 'baukasten.DataGrid.renderers.InitiativeStatusRender.done',
    defaultMessage: 'Done',
  },
  failed: {
    id: 'baukasten.DataGrid.renderers.InitiativeStatusRender.failed',
    defaultMessage: 'Failed',
  },
});
