import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';

import AlertLink from 'components/AlertLink';
import { selectCurrentUser } from 'containers/App/selectors';

import { resendConfirmationRequest } from './actions';
import { selectEmailSent } from './selectors';
import messages from './messages';

export class ConfirmationReminderContainer extends React.PureComponent {
  handleClick = () => {
    this.props.onResendConfirmationClick(this.props.currentUser.email);
  };

  render() {
    const { email } = this.props.currentUser;

    const link = (
      <AlertLink onClick={this.handleClick}>
        <FormattedMessage {...messages.warningLink} values={{ email }} />
      </AlertLink>
    );

    return <FormattedMessage {...messages.warning} values={{ link }} />;
  }
}

ConfirmationReminderContainer.propTypes = {
  currentUser: PropTypes.shape({
    email: PropTypes.string.isRequired,
    confirmedAt: PropTypes.string,
  }).isRequired,
  onResendConfirmationClick: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  emailSent: selectEmailSent(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onResendConfirmationClick: email => {
      const payload = { email };
      dispatch(resendConfirmationRequest(payload));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmationReminderContainer);
