import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgDone = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      d="M7.997 15c.957 0 1.858-.183 2.702-.549a7.156 7.156 0 0 0 3.752-3.747A6.733 6.733 0 0 0 15 8c0-.958-.183-1.859-.549-2.704a7.075 7.075 0 0 0-1.517-2.23A7.151 7.151 0 0 0 10.7 1.55 6.74 6.74 0 0 0 7.99 1c-.956 0-1.857.183-2.702.549A7.174 7.174 0 0 0 1.55 5.296 6.732 6.732 0 0 0 1 8c0 .958.183 1.859.549 2.704.365.845.869 1.59 1.51 2.236a7.057 7.057 0 0 0 2.235 1.511A6.723 6.723 0 0 0 7.997 15Zm-.752-3.66a.749.749 0 0 1-.352-.08 1.007 1.007 0 0 1-.305-.265l-1.66-2.02a.97.97 0 0 1-.135-.236.686.686 0 0 1-.047-.251c0-.172.058-.319.176-.44a.59.59 0 0 1 .44-.19c.108 0 .205.022.291.067a.886.886 0 0 1 .264.238l1.3 1.653 2.805-4.486c.144-.235.327-.352.548-.352.168 0 .316.054.447.162a.52.52 0 0 1 .204.427.63.63 0 0 1-.055.244 1.848 1.848 0 0 1-.122.23l-3.17 4.947a.83.83 0 0 1-.284.265.69.69 0 0 1-.345.088Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgDone);
export default ForwardRef;
