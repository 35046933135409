import { call, put, all, takeLatest } from 'redux-saga/effects';

import api from 'utils/api';

import { SEARCH_REQUEST } from './constants';
import { searchEnd, resetSearch } from './actions';

// Individual exports for testing
export function* search({ query }) {
  if (query.length === 0) {
    yield put(resetSearch());
    return;
  }

  const [usersSearch, teamsSearch, goalsSearch] = yield all([
    call(searchUsers, query),
    call(searchTeams, query),
    call(searchGoals, query),
  ]);
  yield put(searchEnd(usersSearch, teamsSearch, goalsSearch));
}

export function* searchUsers(query) {
  try {
    const response = yield call(api.searchUsers, query);
    return { users: response.users };
  } catch (error) {
    return { searchUsersError: error };
  }
}

export function* searchTeams(query) {
  try {
    const response = yield call(api.searchTeams, query);
    return { teams: response.teams };
  } catch (error) {
    return { searchTeamsError: error };
  }
}

export function* searchGoals(query) {
  try {
    const response = yield call(
      api.searchGoals,
      `confidence_levels[]=ontrack&confidence_levels[]=warning&confidence_levels[]=offtrack&confidence_levels[]=draft&kinds[]=personal&kinds[]=team&kinds[]=organization&query=${query}`
    );
    return { goals: response.goals };
  } catch (error) {
    return { searchGoalsError: error };
  }
}

/**
 * Watches for request actions and calls respective saga when one comes in.
 * By using `takeLatest` only the result of the latest API call is applied.
 */
export function* watcher() {
  yield all([takeLatest(SEARCH_REQUEST, search)]);
}

export default [watcher];
