import { defineMessages } from 'react-intl';

export default defineMessages({
  viewTeam: {
    id: 'app.containers.NestedTeamList.viewTeam',
    defaultMessage: 'View Team',
  },
  viewBusinessUnit: {
    id: 'app.containers.NestedTeamList.viewBusinessUnit',
    defaultMessage: 'View Business Unit',
  },
  archiveTeam: {
    id: 'app.containers.NestedTeamList.archiveTeam',
    defaultMessage: 'Archive Team',
  },
  toastArchivedTeamSuccessfully: {
    id: 'app.containers.NestedTeamList.toastArchivedTeamSuccessfully',
    defaultMessage: 'Team has been archived.',
  },
  emptyStateWithQuery: {
    id: 'app.containers.NestedTeamList.emptyStateWithQuery',
    defaultMessage: "We couldn't find teams matching",
  },
  emptyStateClearSearchButton: {
    id: 'app.containers.NestedTeamList.emptyStateClearSearchButton',
    defaultMessage: 'Clear search',
  },
  searchTeams: {
    id: 'app.containers.NestedTeamList.searchTeams',
    defaultMessage: 'Search Teams…',
  },
  confirmArchiveTeam: {
    id: 'app.containers.NestedTeamList.confirmArchiveTeam',
    defaultMessage:
      'Archive team {teamName}?\n\nYou can archive teams that are not needed anymore without losing their details or goals. Archived teams won’t appear in searches or statistics, but their goals will not be touched and remain visible.',
  },
});
