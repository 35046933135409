import styled from 'styled-components';

import { layers } from 'utils/style';

const Wrapper = styled.div`
  position: fixed;
  bottom: 10px;
  right: 20px;
  z-index: ${layers.toastNotification};
`;

export default Wrapper;
