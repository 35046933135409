import styled from 'styled-components';

type TDProps = {
  pl?: number | string;
  pr?: number | string;
  minWidth?: number | string;
};

export const TD = styled.td<TDProps>`
  padding: 0;
  padding-left: ${({ pl }) => (pl ? `${pl}px` : 'initial')};
  padding-right: ${({ pr }) => (pr ? `${pr}px` : 'initial')};
  min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : 'initial')};
`;
