import { Matcher } from './types';

export const matchAtRisk: Matcher = (row, props) => {
  if (row.entityType !== 'goal_grouping') {
    const { talkingPoints } = props;
    let talkingPointAtRisk;

    if (
      row.entityType !== 'initiative' &&
      row.data.confidenceLevel &&
      (row.data.confidenceLevel.value < 7 ||
        row.data.confidenceLevel.showWarning)
    ) {
      return true;
    }
    if (talkingPoints) {
      if (row.entityType === 'initiative') {
        talkingPointAtRisk = talkingPoints.find(
          tp => tp.initiativeId === row.id
        );
      }

      if (row.entityType === 'key_result') {
        talkingPointAtRisk = talkingPoints.find(
          tp => tp.keyResultId === row.id
        );
      }

      if (row.entityType === 'goal') {
        talkingPointAtRisk = talkingPoints.find(tp => tp.goalId === row.id);
      }

      return (
        (talkingPointAtRisk &&
          (talkingPointAtRisk.tagName === 'risk' ||
            talkingPointAtRisk.tagName === 'blocker')) ??
        false
      );
    }

    return false;
  }

  return false;
};
