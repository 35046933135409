import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { getColor } from '../styles/themeGetters';
import { ColorKeys } from '../styles/themes';
import { Box } from '../Box';

export type IconComponent = React.ComponentType<IconProps>;

export type IconProps = {
  color?: ColorKeys;
  size?: string;
  noPadding?: boolean;
};

/**
 * Use when you need to attach `onClick`s or a background to an Icon
 */
export const IconBox = styled(Box)`
  line-height: 0;
  cursor: ${props => props.onClick && 'pointer'};
`;

/**
 * This is a workaround to support appropriate padding of the components.
 * TODO this should be improved, as the solution is not very efficient right now.
 */
const PadIcon = styled(IconBox)`
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  grid-template-rows: 1fr 4fr 1fr;
`;

/**
 * Wrapper to apply our preferred Icon interface to the generated SVG icons.
 * @param name name of the component, will be set as displayName
 * @param WrappedComponent the Icon to wrap
 */
export const withIconProps = (name: string, WrappedComponent) => {
  const Wrapper = forwardRef<SVGSVGElement, IconProps>(
    ({ color = 'black', size = '24px', noPadding = false }, ref) => {
      return noPadding ? (
        <WrappedComponent
          color={getColor(color)}
          width={size}
          height={size}
          ref={ref}
        />
      ) : (
        <PadIcon width={size} height={size}>
          {/* place the Icon in the middle of the PadIcon grid. Not a great solution, but we can refactor without changing the Api. */}
          <IconBox gridArea="2/2">
            <WrappedComponent
              color={getColor(color)}
              width="100%"
              height="100%"
              ref={ref}
            />
          </IconBox>
        </PadIcon>
      );
    }
  );
  Wrapper.displayName = name;
  return Wrapper;
};
