import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgLearning = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      d="M4.19 16h9.24c.156 0 .29-.06.399-.18a.57.57 0 0 0 .171-.417.616.616 0 0 0-.114-.359.588.588 0 0 0-.285-.216 1.197 1.197 0 0 1-.65-.612 1.48 1.48 0 0 1-.093-.926c.067-.344.248-.672.543-.986a3.39 3.39 0 0 0 .4-.522c.132-.21.199-.5.199-.874V2.344c0-.776-.185-1.361-.556-1.754-.367-.393-.918-.59-1.656-.59H4.212C3.474 0 2.92.197 2.549.59 2.183.978 2 1.563 2 2.344v11.349c0 .767.183 1.341.55 1.725.366.388.913.582 1.64.582Zm.064-1.202c-.361 0-.637-.097-.827-.291-.186-.194-.278-.468-.278-.822 0-.333.104-.6.314-.798.214-.205.499-.306.856-.306h7.32a1.3 1.3 0 0 0 .25-.023c-.167.388-.241.777-.222 1.165.024.393.13.751.321 1.075H4.254Zm-.214-3.352a.382.382 0 0 1-.285-.127.427.427 0 0 1-.121-.306v-9.43c0-.12.04-.22.121-.299a.382.382 0 0 1 .57 0 .39.39 0 0 1 .13.299v9.43c0 .12-.044.221-.13.306a.382.382 0 0 1-.285.127Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgLearning);
export default ForwardRef;
