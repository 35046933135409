import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import {
  getFontSize,
  getLineHeight,
  getFontFamily,
  getColor,
  getFontWeight,
  getBaseUnit,
} from '../styles/themeGetters';

const truncatedCss = css`
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const linkStylingCss = css`
  > a {
    color: ${getColor('info-500')};
    text-decoration: none;

    &:hover {
      color: ${getColor('info-300')};
    }
  }
`;

const StyledText = styled.p`
  font-family: ${getFontFamily('lato')};
  color: ${({ color }) => (color === 'inherit' ? 'inherit' : getColor(color))};
  font-size: ${({ fontSize }) => {
    if (fontSize === undefined) return getFontSize(3);
    return fontSize === 'inherit' ? 'inherit' : getFontSize(fontSize);
  }};
  line-height: ${({ fontSize }) =>
    fontSize === undefined ? getLineHeight(3) : getLineHeight(fontSize)};
  margin-top: ${({ mt }) => getBaseUnit(mt)};
  margin-bottom: ${({ mb }) => getBaseUnit(mb)};
  margin-right: ${({ mr }) => getBaseUnit(mr)};
  margin-left: ${({ ml }) => getBaseUnit(ml)};
  text-align: ${({ textAlign }) => textAlign};
  display: ${({ display }) => display};
  ${({ truncated }) => truncated && truncatedCss};
  font-weight: ${({ bold }) => bold && getFontWeight('bold')};
  max-width: ${({ maxWidth }) => maxWidth};
  /* Debug style */
  /* outline: 1px solid red; */

  ${({ enableLinkStyling }) => enableLinkStyling && linkStylingCss}
`;

const Text = ({
  color,
  fontSize,
  bold,
  display,
  textAlign,
  mb,
  mr,
  ml,
  mt,
  as,
  maxWidth,
  enableLinkStyling,
  ...rest
}) => (
  <StyledText
    color={color}
    fontSize={fontSize}
    textAlign={textAlign}
    mb={mb}
    mr={mr}
    ml={ml}
    mt={mt}
    as={as}
    bold={bold}
    display={display}
    maxWidth={maxWidth}
    enableLinkStyling={enableLinkStyling}
    {...rest}
  />
);

const Heading = ({ as, fontSize, children, ...rest }) => (
  <Text as={as} fontSize={fontSize} {...rest}>
    {children}
  </Text>
);

Text.propTypes = {
  color: PropTypes.string,
  display: PropTypes.string,
  maxWidth: PropTypes.string,
  bold: PropTypes.bool,
  fontSize: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 'inherit']),
  mt: PropTypes.number,
  mb: PropTypes.number,
  mr: PropTypes.number,
  ml: PropTypes.number,
  as: PropTypes.string,
  textAlign: PropTypes.oneOf(['left', 'center', 'right']),
  enableLinkStyling: PropTypes.bool,
};

Heading.propTypes = {
  bold: PropTypes.bool,
  fontSize: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 'inherit']),
  as: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  enableLinkStyling: PropTypes.bool,
  mt: PropTypes.number,
  mb: PropTypes.number,
  mr: PropTypes.number,
  ml: PropTypes.number,
};

Text.Title = Heading;

Text.defaultProps = {
  color: 'gyscale-900',
  fontSize: 3,
  mb: 4,
  mt: 0,
  enableLinkStyling: true,
  mr: 0,
  ml: 0,
};

Heading.defaultProps = {
  fontSize: 5,
  as: 'h2',
  bold: true,
  enableLinkStyling: false,
};

export { Text };
