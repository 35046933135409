import { getPrivacyPolicyUrl } from 'utils/privacyPolicy';

export const getPrivacyPolicyLink = (currentAccount, locale) => {
  if (currentAccount?.customPrivacyLinkEn && locale === 'en') {
    return currentAccount.customPrivacyLinkEn;
  }

  if (currentAccount?.customPrivacyLinkDe && locale === 'de') {
    return currentAccount.customPrivacyLinkDe;
  }

  return getPrivacyPolicyUrl(locale);
};

export const getCustomHelpText = (currentAccount, locale) => {
  if (currentAccount?.customHelpTextEn && locale === 'en') {
    return currentAccount.customHelpTextEn;
  }

  if (currentAccount?.customHelpTextDe && locale === 'de') {
    return currentAccount.customHelpTextDe;
  }

  return null;
};
