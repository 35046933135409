import { defineMessages } from 'react-intl';

export default defineMessages({
  heading: {
    id: 'app.containers.App.ErrorState.heading',
    defaultMessage: 'Oh no…',
  },
  accountError: {
    id: 'app.containers.App.ErrorState.accountError',
    defaultMessage: 'There was an error loading your account.',
  },
  profileError: {
    id: 'app.containers.App.ErrorState.profileError',
    defaultMessage: 'There was an error loading your profile.',
  },
  javascriptError: {
    id: 'app.components.App.ErrorState.javascriptError',
    defaultMessage: "We're sorry — something's gone wrong.",
  },
  errorHelp: {
    id: 'app.components.App.ErrorState.errorHelp',
    defaultMessage: 'Please try {reloading} or contact us at {email}.',
  },
  errorHelpReloading: {
    id: 'app.containers.App.ErrorState.errorHelpReloading',
    defaultMessage: 'reloading the page',
  },
  debugInformation: {
    id: 'app.containers.App.ErrorState.debugInformation',
    defaultMessage:
      'Error Information | Account: {subdomain} | User Agent: {userAgent}',
  },
});
