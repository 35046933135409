import { defineMessages } from 'react-intl';

export default defineMessages({
  customCycleTitle: {
    id: 'app.containers.App.customCycleTitle',
    defaultMessage: 'You are now viewing cycle: {cycleTitle}',
  },
  customCycleError: {
    id: 'app.containers.App.customCycleError',
    defaultMessage:
      'This cycle id does not exist. You are now viewing the active cycle.',
  },
});
