const getLinkColor = (disabled, type) => {
  if (disabled) {
    return 'grey-dark';
  }

  if (type === 'primary') {
    return 'blue-dark';
  }

  return 'navy';
};

export { getLinkColor };
