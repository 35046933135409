import { Theme } from 'baukasten';

const colorsPrimaryGreen = {
  'pri-green-50': '#F0FBF9',
  'pri-green-100': '#DAF1ED',
  'pri-green-200': '#BEE7DF',
  'pri-green-300': '#8EE0D1',
  'pri-green-400': '#18D2AF',
  'pri-green-500': '#04C09C',
  'pri-green-600': '#11A88B',
  'pri-green-700': '#388B7B',
  'pri-green-800': '#37776B',
  'pri-green-900': '#2B5E52',
};

const colorsPrimaryYellow = {
  'pri-yellow-50': '#FEFBED',
  'pri-yellow-100': '#FBF3D3',
  'pri-yellow-200': '#FAF0C2',
  'pri-yellow-300': '#FAECAF',
  'pri-yellow-400': '#F8E285',
  'pri-yellow-500': '#FADA51',
  'pri-yellow-600': '#E8C635',
  'pri-yellow-700': '#B2951C',
  'pri-yellow-800': '#816C0F',
  'pri-yellow-900': '#614F03',
};

const colorsSecondaryOne = {
  'sec-01-100': '#F6DBE3',
  'sec-01-300': '#EFA6BC',
  'sec-01-500': '#EB6C91',
  'sec-01-700': '#9D3553',
  'sec-01-900': '#6C2439',
};

const colorsSecondaryTwo = {
  'sec-02-100': '#F4E3FB',
  'sec-02-300': '#DBB0EC',
  'sec-02-500': '#9B59B6',
  'sec-02-700': '#732B90',
  'sec-02-900': '#411C51',
};

const colorsSecondaryThree = {
  'sec-03-100': '#E5F9FF',
  'sec-03-300': '#B3E5FF',
  'sec-03-500': '#81C6FF',
  'sec-03-700': '#4175B7',
  'sec-03-900': '#18397A',
};

const colorsSecondaryFour = {
  'sec-04-100': '#E1FCF7',
  'sec-04-300': '#A2F1EE',
  'sec-04-500': '#5FC4D2',
  'sec-04-700': '#2F7A97',
  'sec-04-900': '#124064',
};

const colorsSecondaryFive = {
  'sec-05-100': '#DDECFA',
  'sec-05-300': '#95B8E3',
  'sec-05-500': '#4669A4',
  'sec-05-700': '#233B76',
  'sec-05-900': '#0D1B4E',
};

const colorsChart = {
  'chart-01': '#EB6C91',
  'chart-02': '#9B59B6',
  'chart-03': '#81C6FF',
  'chart-04': '#5FC4D2',
  'chart-05': '#4669A4',
  'chart-06': '#9CC69B',
  'chart-07': '#8B85C1',
  'chart-08': '#79AEA3',
  'chart-09': '#F7996E',
  'chart-10': '#828E82',
};

const colorsMiscGrey = {
  'gyscale-0': '#FFFFFF',
  'gyscale-50': '#FAFAFA',
  'gyscale-100': '#F2F2F2',
  'gyscale-200': '#E0E0E0',
  'gyscale-300': '#D6D6D6',
  'gyscale-400': '#CCCCCC',
  'gyscale-500': '#BFBFBF',
  'gyscale-600': '#999999',
  'gyscale-700': '#808080',
  'gyscale-800': '#666666',
  'gyscale-900': '#333333',
  'gyscale-1000': '#000000',
};

const colorsMiscRedToGreen = {
  'rtgscale-10': Theme.getColor('green'),
  'rtgscale-09': Theme.getColor('green'),
  'rtgscale-08': Theme.getColor('green'),
  'rtgscale-07': Theme.getColor('green'),
  'rtgscale-06': Theme.getColor('orange'),
  'rtgscale-05': Theme.getColor('orange'),
  'rtgscale-04': Theme.getColor('orange'),
  'rtgscale-03': Theme.getColor('red'),
  'rtgscale-02': Theme.getColor('red'),
  'rtgscale-01': Theme.getColor('red'),
};

const colorsUtilityInfo = {
  'info-50': '#EFF6FE',
  'info-100': '#DCEAFC',
  'info-300': '#89B7F3',
  'info-500': '#338BFF',
  'info-700': '#0157C9',
  'info-900': '#0A397E',
};

const colorsUtilitySuccess = {
  'suc-50': '#E8FCF1',
  'suc-100': '#C6F6DA',
  'suc-300': '#7EDFA7',
  'suc-500': '#2ECC71',
  'suc-700': '#148B47',
  'suc-900': '#0C5F2F',
};

const colorsUtilityDanger = {
  'dan-50': '#FDEEEC',
  'dan-100': '#FBDAD5',
  'dan-200': '#F9BFB6',
  'dan-300': '#EEACA1',
  'dan-400': '#F28877',
  'dan-500': '#E1472E',
  'dan-600': '#C0321A',
  'dan-700': '#9F2512',
  'dan-800': '#8B2919',
  'dan-900': '#66180B',
};

const colorsUtilityWarning = {
  'war-50': '#FEF7ED',
  'war-100': '#FDEBD2',
  'war-300': '#FBD094',
  'war-500': '#FA970D ',
  'war-700': '#BE7106',
  'war-900': '#764603',
};

const colorsAvatar = {
  'ava-100': '#04C09C',
  'ava-110': '#03A788',
  'ava-200': '#2ECC71',
  'ava-210': '#29B765',
  'ava-300': '#3498DB',
  'ava-310': '#217DBB',
  'ava-400': '#9B59B6',
  'ava-410': '#804399',
  'ava-500': '#34495E',
  'ava-510': '#222F3E',
  'ava-600': '#F1C40F',
  'ava-610': '#DAB10D',
  'ava-700': '#E67E22',
  'ava-710': '#D67118',
  'ava-800': '#E74C3C',
  'ava-810': '#D52C1A',
};

const colorsBaukasten = {
  navy: Theme.getColor('navy'),
  'navy-dark': Theme.getColor('navy-dark'),
  'grey-light-50': Theme.getColor('grey-light-50'),
  'grey-light-100': Theme.getColor('grey-light-100'),
  grey: Theme.getColor('grey'),
  'grey-dark': Theme.getColor('grey-dark'),
  red: Theme.getColor('red'),
  orange: Theme.getColor('orange'),
  green: Theme.getColor('green'),
};

const fontSizes = {
  1: '12px',
  2: '14px',
  3: '16px',
  4: '18px',
  5: '24px',
  6: '32px',
  7: '40px',
};

const lineHeights = {
  1: '16px',
  2: '20px',
  3: '24px',
  4: '24px',
  5: '32px',
  6: '44px',
  7: '60px',
};

const fontWeights = {
  regular: 400,
  bold: 700,
};

const fontFamilies = {
  lato:
    '"Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
};

const baseUnit = 4;

const transformationDurations = {
  default: '0.2s',
};

const gridSpacings = {
  0: 0,
  1: `${baseUnit * 1}px`,
  2: `${baseUnit * 2}px`,
  3: `${baseUnit * 3}px`,
  4: `${baseUnit * 4}px`,
  5: `${baseUnit * 6}px`,
  6: `${baseUnit * 8}px`,
  7: `${baseUnit * 12}px`,
};

const elevations = {
  1: '1px 1px 2px rgba(64, 64, 64, 0.2)',
  2: '2px 2px 4px rgba(64, 64, 64, 0.2)',
  3: '4px 4px 8px rgba(64, 64, 64, 0.2)',
  4: '6px 6px 12px rgba(64, 64, 64, 0.2)',
  5: '12px 12px 24px rgba(64, 64, 64, 0.2)',
};

const zIndex = {
  cookieBanner: 9999,
};

const theme = {
  name: 'default',
  colors: {
    ...colorsPrimaryGreen,
    ...colorsPrimaryYellow,
    ...colorsSecondaryOne,
    ...colorsSecondaryTwo,
    ...colorsSecondaryThree,
    ...colorsSecondaryFour,
    ...colorsSecondaryFive,
    ...colorsMiscGrey,
    ...colorsMiscRedToGreen,
    ...colorsUtilityInfo,
    ...colorsUtilitySuccess,
    ...colorsUtilityDanger,
    ...colorsUtilityWarning,
    ...colorsAvatar,
    ...colorsChart,
    ...colorsBaukasten,
  },
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  baseUnit,
  gridSpacings,
  elevations,
  transformationDurations,
  zIndex,
};

export default theme;

export {
  colorsPrimaryGreen,
  colorsPrimaryYellow,
  colorsSecondaryOne,
  colorsSecondaryTwo,
  colorsSecondaryThree,
  colorsSecondaryFour,
  colorsSecondaryFive,
  colorsMiscGrey,
  colorsMiscRedToGreen,
  colorsUtilityInfo,
  colorsUtilitySuccess,
  colorsUtilityDanger,
  colorsUtilityWarning,
  colorsAvatar,
  colorsChart,
  colorsBaukasten,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  zIndex,
};
