import { getRequest } from '../../methods';

const getJiraIssues = (teamId, startDate, endDate) =>
  getRequest(
    `jira/goals/${teamId}?&start_date=${startDate}&end_date=${endDate}`
  );

const getJiraProjects = () => getRequest('jira_projects    ');

const jira = {
  getJiraIssues,
  getJiraProjects,
};

export default jira;
