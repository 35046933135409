import React from 'react';
import styled from 'styled-components';
import { getBaseUnit, getColor } from '../styles/themeGetters';
import { Box } from '../Box';
import { Text } from '../Text';

type Size = 's' | 'l';

type Variant = 'default' | 'disabled';

type ToggleProps = {
  variant?: Variant;
  size: Size;
  label?: string;
  checked: boolean;
  onToggle: () => void;
};

const Switch = styled.div<{ size: Size }>`
  position: absolute;
  top: ${({ size }) => (size === 'l' ? '3px' : '2px')};
  left: ${({ size }) => (size === 'l' ? '3px' : '2px')};
  height: ${({ size }) => (size === 'l' ? '18px' : '12px')};
  width: ${({ size }) => (size === 'l' ? '18px' : '12px')};
  border-radius: 100%;
  background-color: ${getColor('white')};
  transition: transform 0.3s ease-in-out;
`;

const Checkbox = styled.input<{
  switchSize: Size;
}>`
  // opacity set to 0 so that this input can be focused.
  // by being focused, focus-within can be applied on its parent.
  opacity: 0;
  cursor: pointer;
  :checked + ${Switch} {
    transform: ${({ switchSize }) =>
      `translateX(${switchSize === 'l' ? 18 : 12}px)`};
  }
`;

const Label = styled.label<
  { size: Size } & {
    isToggled: boolean;
    isDisabled: boolean;
  }
>`
  cursor: pointer;
  position: relative;
  display: inline-block;
  width: ${({ size }) => (size === 'l' ? '42px' : '28px')};
  height: ${({ size }) => (size === 'l' ? '24px' : '16px')};
  border-radius: 16px;
  background-color: ${({ isDisabled, isToggled }) => {
    if (isDisabled) {
      return getColor('grey-light-100');
    }
    if (isToggled) {
      return getColor('navy');
    }
    return getColor('grey');
  }};

  :focus-within {
    box-shadow: ${({ isDisabled }) =>
      isDisabled ? 'none' : `0 0 0 2px ${getColor('blue-light')}`};
  }
`;

const Toggle = ({
  variant = 'default',
  size = 's',
  label,
  checked,
  onToggle,
}: ToggleProps) => {
  const handleChange = () => variant !== 'disabled' && onToggle();

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="flex-start"
      flexGap={getBaseUnit(3)}
    >
      <Label
        size={size}
        isToggled={checked}
        isDisabled={variant === 'disabled'}
      >
        <Checkbox
          type="checkbox"
          onChange={handleChange}
          switchSize={size}
          disabled={variant === 'disabled'}
          checked={checked}
        />
        <Switch size={size} />
      </Label>

      {label && <Text fontSize={size === 'l' ? 20 : 16}>{label}</Text>}
    </Box>
  );
};

export { Toggle };
