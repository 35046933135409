import { SET_NAVIGATION_COLLAPSE_STATE } from './constants';

export const initialState = {
  navigationCollapseState: {
    initialized: false,
    goals: false,
    checkIns: false,
    analytics: false,
  },
};

const appLayoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NAVIGATION_COLLAPSE_STATE:
      return {
        ...state,
        navigationCollapseState: {
          ...state.navigationCollapseState,
          ...action.payload,
        },
      };

    default:
      return state;
  }
};

export default appLayoutReducer;
