import React from 'react';
import styled from 'styled-components';
import { Icons } from '../../../Icons';
import { getColor } from '../../../styles/themeGetters';

type ButtonProps = {
  $align: string;
};

const Button = styled.button<ButtonProps>`
  border: none;
  border-radius: 4px;
  background: none;
  padding: 0;
  align-self: ${props => props.$align || 'baseline'};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    background-color: ${getColor('grey-light-50')};
  }
`;

type Props = {
  isOpen: boolean;
  getToggleButtonProps;
  disabled?: boolean;
  align?: string;
  name?: string;
};

const ToggleButton = ({
  getToggleButtonProps,
  isOpen,
  disabled,
  align,
  name,
}: Props) => (
  <Button
    {...getToggleButtonProps()}
    aria-label={name ? `toggle menu ${name}` : 'toggle menu'}
    type="button"
    disabled={disabled}
    $align={align}
  >
    {isOpen ? <Icons.SolidChevronUp /> : <Icons.SolidChevronDown />}
  </Button>
);

export { ToggleButton };
