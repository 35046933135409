import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgArchived = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      d="M4.202 14h7.596c.716 0 1.264-.188 1.643-.564.378-.371.568-.915.568-1.63V5.31H1.99v6.497c0 .72.19 1.265.568 1.636.38.371.927.557 1.643.557Zm1.566-6.037a.552.552 0 0 1-.409-.16.58.58 0 0 1-.152-.418V7.17c0-.171.05-.308.152-.41a.552.552 0 0 1 .41-.16h4.47a.543.543 0 0 1 .568.571v.215a.566.566 0 0 1-.16.418.552.552 0 0 1-.408.16h-4.47Zm-3.507-3.67h11.484c.416 0 .728-.116.936-.348.213-.232.319-.554.319-.967v-.662c0-.413-.106-.736-.319-.968-.208-.232-.52-.348-.935-.348H2.26c-.397 0-.707.116-.928.348C1.11 1.58 1 1.903 1 2.316v.662c0 .413.104.735.312.967.212.232.529.349.95.349Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgArchived);
export default ForwardRef;
