import React from 'react';
import styled, { css } from 'styled-components';
import { getColor } from '../../../styles/themeGetters';
import { Text } from '../../../Text';
import { Avatar } from '../../../Avatar';
import { Box } from '../../../Box';
import { Tag } from '../../../Tag';
import { Item } from '../types';
import { Truncate } from '../../../Truncate';
import { EntitySymbol } from '../../../EntitySymbol';

const SelectedItemRenderer = ({
  item,
  maxTextWidth,
}: {
  item: Item;
  maxTextWidth?: string;
}) => {
  switch (item.entityType) {
    case 'default':
      return (
        <Truncate
          maxWidth={maxTextWidth}
          text={item.title}
          tooltipPlacement="top"
        />
      );

    case 'suffix':
      return (
        <Truncate
          maxWidth={maxTextWidth}
          text={item.title}
          tooltipPlacement="top"
        />
      );

    case 'person':
      return (
        <>
          <Tag
            label={item.title}
            truncated
            avatar={
              <Avatar
                image={item.entityType === 'person' ? item.avatar?.url : null}
                fallbackText={item.avatar.fallbackText}
                type={item.entityType}
                size="xs"
              />
            }
          />
        </>
      );

    case 'team':
      return (
        <>
          <Tag
            label={item.title}
            truncated
            avatar={
              <Avatar
                fallbackText={item.title}
                type={item.entityType}
                size="xs"
              />
            }
          />
        </>
      );

    case 'businessUnit':
      return (
        <Box
          display="flex"
          flexDirection="row"
          flexGap="8px"
          alignItems="center"
        >
          <EntitySymbol variant="businessUnit" size="s" />
          <Truncate
            maxWidth={maxTextWidth}
            text={item.title}
            tooltipPlacement="top"
          />
        </Box>
      );

    case 'currency':
      return (
        <>
          <Text>
            <Text display="inline" as="span" mr={1} color="grey-dark">
              {item.suffix}
            </Text>
            {item.title}
          </Text>
        </>
      );
    default: {
      // this is to make a missing Renderer a type error. see
      // https://www.typescriptlang.org/docs/handbook/2/narrowing.html#exhaustiveness-checking
      // for how this works.
      const _exhaustiveCheck: never = item;
      return _exhaustiveCheck;
    }
  }
};

const disabledStyles = css`
  color: ${getColor('grey-light-100')};
  cursor: not-allowed;
  background: #fff;
`;

type WrapperProps = {
  $disabled: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  width: 100%;
  height: 34px;
  cursor: pointer;
  align-items: center;

  ${props => (props.$disabled ? disabledStyles : null)};
`;

type SelectedItemProps = {
  item: Item;
  isOpen: boolean;
  placeholder: string;
  openMenu: () => void;
  disabled: boolean;
  maxTextWidth?: string;
};

const SelectedItem = ({
  item,
  isOpen,
  placeholder,
  openMenu,
  disabled,
  maxTextWidth,
}: SelectedItemProps) => {
  return (
    <Wrapper
      onClick={!isOpen && !disabled ? openMenu : null}
      $disabled={disabled}
    >
      {item ? (
        <SelectedItemRenderer item={item} maxTextWidth={maxTextWidth} />
      ) : (
        <Text color="grey-dark">{placeholder}</Text>
      )}
    </Wrapper>
  );
};

export { SelectedItem };
