import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgLink = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.75 3H7v1.5H4.75a3.25 3.25 0 1 0 0 6.5H7v1.5H4.75a4.75 4.75 0 1 1 0-9.5Zm6.5 8H9v1.5h2.25a4.75 4.75 0 1 0 0-9.5H9v1.5h2.25a3.25 3.25 0 0 1 0 6.5Zm-6.5-4a.75.75 0 0 0 0 1.5h6.5a.75.75 0 0 0 0-1.5h-6.5Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgLink);
export default ForwardRef;
