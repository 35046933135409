import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgOpenSidebar = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 2.5H2a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h6v-11Zm1.5 0v11H14a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5H9.5ZM2 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2Zm8.5 3.75a.75.75 0 0 1 .75-.75h1.5a.75.75 0 0 1 0 1.5h-1.5a.75.75 0 0 1-.75-.75Zm0 3a.75.75 0 0 1 .75-.75h1.5a.75.75 0 0 1 0 1.5h-1.5a.75.75 0 0 1-.75-.75Zm.75 2.25a.75.75 0 0 0 0 1.5h1.5a.75.75 0 0 0 0-1.5h-1.5Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgOpenSidebar);
export default ForwardRef;
