import styled from 'styled-components';
import { getBaseUnit, getColor } from '../../styles/themeGetters';
import { hexToRgba } from '../../utils/hexToRgba';

export const THead = styled.thead`
  position: relative;
  background-color: ${getColor('white')};
  border-top-left-radius: ${getBaseUnit(2)};
  border-top-right-radius: ${getBaseUnit(2)};
  border-bottom: 1px solid ${getColor('grey-light-100')};
  box-shadow: 0 0 0 1px ${getColor('grey-light-100')};
  box-shadow: 0 8px 20px -4px ${hexToRgba(getColor('black'), 0.08)};

  tr {
    height: 52px;
  }

  th {
    :first-child {
      border-top-left-radius: ${getBaseUnit(2)};
    }
    :last-child {
      border-top-right-radius: ${getBaseUnit(2)};
    }
  }
`;
