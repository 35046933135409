// Docs: https://github.com/WorkpathHQ/workpath_web/wiki/Container-Saga-Docs
import { call, put, all, takeLatest } from 'redux-saga/effects';
import api from 'utils/api';
import { GET_KEY_RESULT_PROGRESS_OVER_TIME_REQUEST } from './constants';
import {
  getKeyResultProgressOverTimeSuccess,
  getKeyResultProgressOverTimeError,
} from './actions';

export function* getKeyResultProgressOverTime({ payload }) {
  try {
    const response = yield call(api.getKeyResultProgress, payload.keyResultId);

    yield put(getKeyResultProgressOverTimeSuccess(response));
  } catch (err) {
    yield put(getKeyResultProgressOverTimeError(err));
  }
}

export function* watcher() {
  yield all([
    takeLatest(
      GET_KEY_RESULT_PROGRESS_OVER_TIME_REQUEST,
      getKeyResultProgressOverTime
    ),
  ]);
}

export default [watcher];
