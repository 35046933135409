import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgAssist = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      d="M3.689 6.103c0 .105.036.196.109.273a.464.464 0 0 0 .275.13c.687.076 1.262.167 1.726.272.469.1.856.242 1.16.424.305.178.549.42.732.726.184.302.332.69.443 1.164.116.475.22 1.062.312 1.761.019.106.065.192.137.259a.388.388 0 0 0 .522 0 .436.436 0 0 0 .145-.259c.092-.7.194-1.286.305-1.76.111-.475.259-.863.442-1.165.189-.307.435-.548.74-.726.304-.182.689-.323 1.153-.424a16.395 16.395 0 0 1 1.726-.273.438.438 0 0 0 .275-.13.384.384 0 0 0 .109-.272.36.36 0 0 0-.109-.266.422.422 0 0 0-.275-.137 17.994 17.994 0 0 1-1.726-.266c-.464-.105-.849-.246-1.153-.424a2.115 2.115 0 0 1-.732-.726c-.184-.306-.334-.697-.45-1.171a18.39 18.39 0 0 1-.305-1.76.42.42 0 0 0-.137-.252A.38.38 0 0 0 8.844 1a.37.37 0 0 0-.26.1.42.42 0 0 0-.138.252 17.525 17.525 0 0 1-.32 1.747c-.11.47-.258.855-.442 1.157a2.136 2.136 0 0 1-.732.718c-.3.178-.684.321-1.153.432-.464.105-1.04.203-1.726.294a.452.452 0 0 0-.283.137.373.373 0 0 0-.101.266ZM3 12.363a.313.313 0 0 0 .261.302c.464.085.824.162 1.08.23.261.062.455.153.58.272.126.12.216.3.269.54.058.239.113.574.167 1.005a.365.365 0 0 0 .101.202.28.28 0 0 0 .203.086c.082 0 .15-.026.203-.08a.345.345 0 0 0 .109-.2c.058-.45.116-.803.174-1.057.063-.249.16-.433.29-.553.13-.125.322-.213.573-.266a13.94 13.94 0 0 1 1.044-.18.335.335 0 0 0 .196-.1.294.294 0 0 0 .007-.395.317.317 0 0 0-.196-.108c-.45-.087-.802-.16-1.058-.223-.252-.067-.443-.16-.573-.28-.126-.12-.22-.3-.283-.54-.058-.24-.116-.58-.174-1.02a.314.314 0 0 0-.109-.194.292.292 0 0 0-.203-.08.292.292 0 0 0-.203.08.37.37 0 0 0-.101.208 9.844 9.844 0 0 1-.167 1.014c-.053.24-.143.421-.268.546-.121.124-.31.22-.566.287-.256.063-.612.13-1.066.202-.193.033-.29.134-.29.301Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgAssist);
export default ForwardRef;
