import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgChevronRight = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.21 10.807a.62.62 0 0 1 0-.931L8.263 8 6.21 6.124a.62.62 0 0 1 0-.931.768.768 0 0 1 1.018 0l2.56 2.341a.62.62 0 0 1 0 .932l-2.56 2.341a.768.768 0 0 1-1.018 0Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgChevronRight);
export default ForwardRef;
