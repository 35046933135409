import React from 'react';
import styled from 'styled-components';
import { Avatar } from '../../../Avatar';
import { Icons } from '../../../Icons';
import { getColor } from '../../../styles/themeGetters';
import { MultiselectMenuItem } from '../components/MultiselectMenuItem';
import { Item, Group } from '../types';
import { Skeleton } from '../../../Skeleton';

type Props = {
  item: Item | Group;
  isSelected: boolean;
  loading?: boolean;
  liProps;
};

const Separator = styled.li`
  list-style-type: none;
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid ${getColor('grey-light-100')};
`;

const LoadingSkeletonWrapper = styled.li`
  padding: 6px 12px 6px 16px;
  list-style-type: none;
`;

const MenuItemRenderer = ({ item, isSelected, loading, liProps }: Props) => {
  switch (item.entityType) {
    case 'group':
      return (
        <MultiselectMenuItem
          label={item.title}
          fontWeight="bold"
          clickable={false}
        />
      );
    case 'lazyItem':
      return loading ? (
        <LoadingSkeletonWrapper>
          <Skeleton height="24px" width="100%" />
        </LoadingSkeletonWrapper>
      ) : (
        <MultiselectMenuItem
          label={item.title}
          clickable={false}
          disabled={item.disabled}
        />
      );
    case 'person':
      return (
        <div {...liProps}>
          <MultiselectMenuItem
            avatar={
              <Avatar
                fallbackText={item.title}
                image={item.avatar.url}
                type={item.entityType}
                size="xs"
              />
            }
            label={item.title}
            suffix={isSelected && <Icons.Check />}
            disabled={item.disabled}
          />
        </div>
      );
    case 'team':
      return (
        <div {...liProps}>
          <MultiselectMenuItem
            avatar={
              <Avatar
                fallbackText={item.title}
                type={item.entityType}
                size="xs"
              />
            }
            label={item.title}
            suffix={isSelected && <Icons.Check />}
            disabled={item.disabled}
          />
        </div>
      );
    case 'goal':
      return (
        <div {...liProps}>
          <MultiselectMenuItem
            avatar={
              <Avatar
                fallbackText={item.title}
                image={item.owner.url}
                type="person"
                size="xs"
              />
            }
            label={item.title}
            suffix={isSelected && <Icons.Check />}
            disabled={item.disabled}
          />
        </div>
      );
    case 'label':
      return (
        <div {...liProps}>
          <MultiselectMenuItem
            label={item.title}
            suffix={isSelected && <Icons.Check />}
            disabled={item.disabled}
          />
        </div>
      );
    case 'separator':
      return <Separator />;

    default: {
      // this is to make a missing Renderer a type error. see
      // https://www.typescriptlang.org/docs/handbook/2/narrowing.html#exhaustiveness-checking
      // for how this works.
      const _exhaustiveCheck: never = item;
      return _exhaustiveCheck;
    }
  }
};

export { MenuItemRenderer };
