import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';

import getWrappedDisplayName from './getWrappedDisplayName';

const withErrorBoundary = WrappedComponent => {
  class WithErrorBoundary extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        error: null,
      };
    }

    componentDidCatch(error, info) {
      this.setState({ error });
      Sentry.withScope(scope => {
        scope.setExtra(info);
        Sentry.captureException(error);
      });
    }

    render() {
      if (this.state.error) {
        return null;
      }

      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <WrappedComponent {...this.props}>
          {this.props.children}
        </WrappedComponent>
      );
    }
  }

  WithErrorBoundary.propTypes = {
    children: PropTypes.node,
  };

  WithErrorBoundary.displayName = getWrappedDisplayName(
    WrappedComponent,
    'WithErrorBoundary'
  );
  return WithErrorBoundary;
};

export default withErrorBoundary;
