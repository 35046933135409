import {
  getRequestV2,
  postRequestV2,
  patchRequestV2,
  deleteRequestV2,
} from '../../methods';

const getPastConversations = currentPage =>
  getRequestV2(`conversations/events?filter[state]=past&page=${currentPage}`);

const getUpcomingConversations = currentPage =>
  getRequestV2(
    `conversations/events?filter[state]=upcoming&page=${currentPage}`
  );

const searchGoalsAndKeyResults = (query, teamId, kind) =>
  getRequestV2(
    `conversations/goal_selector?query=${encodeURIComponent(
      query
    )}&kind=${kind}&team_id=${teamId}`
  );

const searchBusinessGoals = ({ query, businessUnitId }) =>
  getRequestV2(
    `conversations/goal_selector?query=${encodeURIComponent(
      query
    )}&kind=business_unit&business_unit_id=${businessUnitId}`
  );

const createConversation = body => postRequestV2('conversations/events', body);

const editConversation = (conversationId, body) =>
  patchRequestV2(`conversations/events/${conversationId}`, body);

const getConversation = conversationId =>
  getRequestV2(`conversations/events/${conversationId}`);

const getConversationGoals = eventId =>
  getRequestV2(`conversations/events/${eventId}/goals`);

const deactivateConversation = eventId =>
  postRequestV2(`conversations/events/${eventId}/deactivate`, {});

const deleteOneTimeConversation = eventId =>
  deleteRequestV2(`conversations/events/${eventId}`, {});

const getTalkingPoints = eventId =>
  getRequestV2(`conversations/events/${eventId}/talking_points`);

const createTalkingPoint = (eventId, goalId, payload) =>
  postRequestV2(
    `conversations/events/${eventId}/goals/${goalId}/talking_points`,
    payload
  );

const deleteTalkingPoint = (eventId, goalId, talkingPointId) =>
  deleteRequestV2(
    `conversations/events/${eventId}/goals/${goalId}/talking_points/${talkingPointId}`
  );

const resolveTalkingPoint = (eventId, talkingPointId) =>
  postRequestV2(
    `conversations/events/${eventId}/talking_points/${talkingPointId}/resolve`,
    {}
  );

const unResolveTalkingPoint = (eventId, talkingPointId) =>
  postRequestV2(
    `conversations/events/${eventId}/talking_points/${talkingPointId}/unresolve`,
    {}
  );

const editTalkingPoint = (eventId, goalId, talkingPointId, body) =>
  patchRequestV2(
    `conversations/events/${eventId}/goals/${goalId}/talking_points/${talkingPointId}`,
    body
  );

const completePreparations = conversationId =>
  postRequestV2(
    `conversations/events/${conversationId}/complete_preparations`,
    {}
  );

const skipNextConversationEvent = (conversationId, conversationEvent) =>
  postRequestV2(`conversations/events/${conversationId}/skip_instance`, {
    conversationEvent: {
      ...conversationEvent,
      currentStartsAt: conversationEvent.startsAt,
    },
  });

const conversations = {
  searchBusinessGoals,
  searchGoalsAndKeyResults,
  getPastConversations,
  getUpcomingConversations,
  getConversation,
  getConversationGoals,
  getTalkingPoints,
  createConversation,
  createTalkingPoint,
  editConversation,
  deactivateConversation,
  skipNextConversationEvent,
  deleteOneTimeConversation,
  deleteTalkingPoint,
  resolveTalkingPoint,
  unResolveTalkingPoint,
  editTalkingPoint,
  completePreparations,
};
export default conversations;
