import { getRequestV2, postRequestV2 } from '../../methods';

const getGoalTemplates = () => getRequestV2('goal_templates');
const getGoalTemplate = templateId =>
  getRequestV2(`goal_templates/${templateId}`);

const createGoalFromTemplate = (templateId, payload) =>
  postRequestV2(`goal_templates/${templateId}/goals`, payload);

const goalTemplates = {
  getGoalTemplates,
  getGoalTemplate,
  createGoalFromTemplate,
};

export default goalTemplates;
