import React, { useEffect, MouseEvent } from 'react';

// USAGE:
//   useClickOutside({
//    targetRef: ref,
//    onClickOutside: () => {

//    },
//    prioritizeAnchor: true,
//    ignoreClicksInsideElement: true,
//    elementNodeId: 'sidepanel',
// });
// ...

type UseClickOutsideParams = {
  targetRef: React.RefObject<HTMLElement>;
  onClickOutside: (e: MouseEvent) => void;
  prioritizeAnchor?: boolean;
  ignoredNodesIds?: string[];
  ignoredNodeClassName?: string;
  eventType?: 'click' | 'mousedown';
};

const useClickOutside = ({
  targetRef,
  onClickOutside,
  prioritizeAnchor = false,
  ignoredNodesIds = [],
  ignoredNodeClassName = '',
  // In some cases using the 'click' event might be problematic, e.g. if users try to select text inside the target component
  // and then release the mouse button once the cursor has been moved outside.
  // Using 'mousedown' in such cases is safer as the event will be fired only when the outside click is triggered deliberately by the user.
  eventType = 'click',
}: UseClickOutsideParams) => {
  useEffect(
    () => {
      const handleOutsideClick = e => {
        // if targetRef is null then do early return
        if (!targetRef.current) {
          return;
        }

        // ignore clicks on links
        if (prioritizeAnchor) {
          if (e.target.nodeName === 'A') {
            return;
          }
        }

        // ignore outside clicks on elements that don't exist anymore in the DOM Tree
        if (!e.target.isConnected) {
          return;
        }

        // ignore clicks inside elements with certain IDs
        if (ignoredNodesIds.length) {
          const elementNodes = ignoredNodesIds.map(ignoredNodeId =>
            document.getElementById(ignoredNodeId)
          );

          if (
            elementNodes.length &&
            elementNodes.some(el => el?.contains(e.target))
          ) {
            return;
          }
        }

        // ignore clicks inside elements with a certain class
        if (
          ignoredNodeClassName &&
          Array.from(
            document.getElementsByClassName(ignoredNodeClassName)
          ).some(el => el.contains(e.target))
        ) {
          return;
        }

        const outsideClick = !targetRef.current.contains(e.target);

        if (outsideClick) {
          onClickOutside(e);
        }
      };

      document.addEventListener(eventType, handleOutsideClick);

      return () => {
        document.removeEventListener(eventType, handleOutsideClick);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- [RFC 032]
    [
      onClickOutside,
      prioritizeAnchor,
      targetRef,
      ignoredNodesIds,
      ignoredNodeClassName,
    ]
  );
};

export { useClickOutside };
