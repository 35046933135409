import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgViewGraph = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      d="M0 14.372c0 .187.056.337.168.45a.62.62 0 0 0 .46.178H15.27c.2 0 .37-.069.512-.207a.681.681 0 0 0 .219-.517.681.681 0 0 0-.22-.517.706.706 0 0 0-.51-.207H1.636c-.131 0-.197-.069-.197-.207V1.724a.7.7 0 0 0-.212-.51A.67.67 0 0 0 .724 1a.687.687 0 0 0-.52.214.713.713 0 0 0-.204.51v12.648Zm1.316-4.034 3.128-3.25c.063-.064.13-.096.197-.096.064 0 .127.032.19.096l2.61 2.733c.166.177.336.308.511.392.176.083.356.125.541.125.356 0 .707-.172 1.053-.517L12.55 6.77l1.14 1.16c.107.108.22.162.337.162a.483.483 0 0 0 .321-.125.67.67 0 0 0 .205-.355l1.103-4.344a.5.5 0 0 0-.036-.37.552.552 0 0 0-.256-.25A.49.49 0 0 0 15 2.61l-4.29 1.108a.66.66 0 0 0-.352.207.494.494 0 0 0-.124.325.46.46 0 0 0 .16.332l1.134 1.153L8.69 8.66c-.064.07-.13.104-.198.104-.063 0-.126-.035-.19-.104l-2.61-2.726a1.656 1.656 0 0 0-.518-.391 1.227 1.227 0 0 0-1.067-.008c-.17.079-.344.212-.52.4L.293 9.31l1.024 1.027Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgViewGraph);
export default ForwardRef;
