import merge from 'lodash/merge';
import { push } from 'react-router-redux';

import {
  CREATE_TOAST,
  REMOVE_TOAST,
  CLEAR_TOASTS,
  CLEAR_ALL_TOASTS,
  CONFIRMATION_REMINDER_TOAST,
} from './constants';

const createBaseToast = (kind, message, settings) => {
  const defaults = { dismissable: true };
  const options = { ...defaults, ...settings };

  return {
    kind,
    message,
    options,
  };
};

export function createToast(kind, message, settings) {
  return {
    type: CREATE_TOAST,
    ...createBaseToast(kind, message, settings),
  };
}

export const createInfoToast = (message, settings) =>
  createToast('info', message, settings);
export const createSuccessToast = (message, settings) =>
  createToast('success', message, settings);
export const createWarningToast = (message, settings) =>
  createToast('warning', message, settings);
export const createErrorToast = (message, settings) =>
  createToast('error', message, settings);
export const createConfirmationReminderToast = () =>
  createToast('warning', undefined, {
    dismissable: false,
    customToastType: CONFIRMATION_REMINDER_TOAST,
  });

export const pushWithToast = (location, kind, message, settings) => {
  const locationToast = {
    state: { toast: createBaseToast(kind, message, settings) },
  };

  if (typeof location === 'string') {
    const locationDescriptor = { pathname: location, ...locationToast };
    return push(locationDescriptor);
  }

  return push(merge(location, locationToast));
};

export const pushWithInfoToast = (location, message, settings) =>
  pushWithToast(location, 'info', message, settings);
export const pushWithSuccessToast = (location, message, settings) =>
  pushWithToast(location, 'success', message, settings);
export const pushWithWarningToast = (location, message, settings) =>
  pushWithToast(location, 'warning', message, settings);
export const pushWithErrorToast = (location, message, settings) =>
  pushWithToast(location, 'error', message, settings);

export function removeToast(id) {
  return {
    type: REMOVE_TOAST,
    id,
  };
}

export function clearToasts() {
  return {
    type: CLEAR_TOASTS,
  };
}

export function clearAllToasts() {
  return {
    type: CLEAR_ALL_TOASTS,
  };
}
