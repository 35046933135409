import {
  RESEND_CONFIRMATION_REQUEST,
  RESEND_CONFIRMATION_SUCCESS,
  RESEND_CONFIRMATION_ERROR,
} from './constants';

export const initialState = {
  isSubmitting: false,
  error: false,
  emailSent: false,
};

function confirmationReminderContainerReducer(state = initialState, action) {
  switch (action.type) {
    case RESEND_CONFIRMATION_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        error: false,
      };
    case RESEND_CONFIRMATION_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        error: false,
        emailSent: true,
      };
    case RESEND_CONFIRMATION_ERROR:
      return {
        ...state,
        error: action.error,
        isSubmitting: false,
      };
    default:
      return state;
  }
}

export default confirmationReminderContainerReducer;
