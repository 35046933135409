import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgFormatUnderline = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      d="M7.996 14.254c1.03 0 1.92-.2 2.67-.599a4.2 4.2 0 0 0 1.725-1.663c.406-.716.609-1.542.609-2.478V1.832c0-.25-.074-.45-.223-.6-.143-.154-.338-.232-.584-.232a.745.745 0 0 0-.575.233c-.137.149-.206.348-.206.599V9.38c0 .996-.3 1.804-.901 2.424-.601.62-1.44.93-2.515.93-.716 0-1.328-.14-1.837-.42a2.915 2.915 0 0 1-1.168-1.181c-.269-.5-.403-1.085-.403-1.753V1.832c0-.25-.074-.45-.223-.6C4.222 1.079 4.027 1 3.78 1a.745.745 0 0 0-.575.233C3.069 1.382 3 1.58 3 1.832v7.682c0 .936.2 1.762.6 2.478a4.27 4.27 0 0 0 1.726 1.663c.75.4 1.64.6 2.67.6ZM3.506 17h8.979a.48.48 0 0 0 .36-.152.506.506 0 0 0 .155-.376.517.517 0 0 0-.155-.384.48.48 0 0 0-.36-.152H3.506a.498.498 0 0 0-.36.152.533.533 0 0 0-.146.384c0 .15.049.275.146.376a.498.498 0 0 0 .36.152Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgFormatUnderline);
export default ForwardRef;
