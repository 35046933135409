import React from 'react';
import { LOCATION_CHANGE } from 'react-router-redux';
import {
  CREATE_TOAST,
  REMOVE_TOAST,
  CLEAR_TOASTS,
  CLEAR_ALL_TOASTS,
} from './constants';

export const initialState = {
  notifications: [],
};

const hasToast = routePayload =>
  routePayload && routePayload.state && routePayload.state.toast;
const isValidMessageNode = elem =>
  React.isValidElement(elem) || typeof elem === 'string';
const getNextNotificationId = state =>
  state.notifications.reduce((maxId, n) => Math.max(n.id, maxId), -1) + 1;
const buildNotification = (state, action) => ({
  id: getNextNotificationId(state),
  kind: action.kind,
  message: action.message,
  options: action.options,
});

function toastNotificationReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_TOAST:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          buildNotification(state, action),
        ],
      };

    case REMOVE_TOAST: {
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.id !== action.id
        ),
      };
    }
    case CLEAR_TOASTS:
    case LOCATION_CHANGE: {
      const routePayload = action.payload;
      const notifications = state.notifications.filter(
        n => !n.options.dismissable
      );

      if (
        hasToast(routePayload) &&
        isValidMessageNode(routePayload.state.toast.message)
      ) {
        notifications.push(buildNotification(state, routePayload.state.toast));
      }

      return {
        ...state,
        notifications,
      };
    }
    case CLEAR_ALL_TOASTS: {
      return initialState;
    }
    default:
      return state;
  }
}

export default toastNotificationReducer;
