import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Box, Icons, IconBox, Theme } from 'baukasten';

import LocaleToggle from 'containers/LocaleToggle';

import Link from 'components/Link';

import { getPrivacyPolicyLink } from '../utils';
import messages from '../messages';

const FooterLink = styled(Link)`
  margin-right: 32px;
  color: ${Theme.getColor('navy')};
  font-size: 16px;

  &:first-child {
    margin-left: 0;
  }
`;

const IconWrapper = styled(IconBox)`
  display: inline-block;
  vertical-align: middle;
`;

export const Footer = ({ publicAccountData, locale }) => (
  <Box display="flex" alignItems="center" justifyContent="space-between">
    <Box>
      <FooterLink
        href="https://workpath.zendesk.com/hc/en-us/"
        target="_blank"
        aria-label="open help center"
      >
        <IconWrapper mr={1} mb={1}>
          <Icons.HelpSolid color="navy" />
        </IconWrapper>
        <FormattedMessage {...messages.help} />
      </FooterLink>
      <FooterLink
        to={getPrivacyPolicyLink(publicAccountData, locale)}
        target="_blank"
        aria-label="open privacy policy"
      >
        <IconWrapper mr={1} mb={1}>
          <Icons.InfoSolid color="navy" />
        </IconWrapper>
        <FormattedMessage {...messages.privacy} />
      </FooterLink>
    </Box>
    <LocaleToggle />
  </Box>
);

Footer.propTypes = {
  publicAccountData: PropTypes.object,
  locale: PropTypes.string,
};

export default Footer;
