import button from './button';
import colors from './colors';
import helpers from './helpers';
import layers from './layers';
import shadows from './shadows';
import spacings from './spacings';
import textStyles from './textStyles';

const pictureSizes = {
  small: '18px',
  medium: '36px',
  large: '140px',
};

export {
  button,
  colors,
  helpers,
  layers,
  pictureSizes,
  shadows,
  spacings,
  textStyles,
};
