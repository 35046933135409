import React from 'react';
import styled from 'styled-components';
import {
  getColor,
  getFontSize,
  getFontWeight,
  getFontFamily,
  getBaseUnit,
} from '../styles/themeGetters';
import { ColorKeys } from '../styles/themes';
import { IconComponent } from '../Icons/helpers';

type Variant = 'small' | 'large';

type BadgeBackgroundColor = Extract<
  ColorKeys,
  | 'blue-dark'
  | 'blue-light'
  | 'yellow'
  | 'navy'
  | 'grey-light-100'
  | 'lilac-dark'
>;

type StyledBadgeProps = {
  backgroundColor: BadgeBackgroundColor;
  variant: Variant;
};

type OptionsAllowed = { [key in BadgeBackgroundColor]: string };

const getColorFromOptions = (options: OptionsAllowed) => ({
  backgroundColor,
}: StyledBadgeProps) =>
  getColor(options[backgroundColor] as BadgeBackgroundColor);

const colorOptions = {
  'blue-dark': 'white',
  'blue-light': 'navy',
  yellow: 'navy',
  navy: 'white',
  'grey-light-100': 'navy',
  'lilac-dark': 'white',
};

const Wrapper = styled.span<StyledBadgeProps>`
  font-size: ${props => getFontSize(props.variant === 'large' ? 12 : 10)};
  font-weight: ${getFontWeight('bold')};
  font-family: ${getFontFamily('lato')};
  border-radius: ${getBaseUnit(4)};
  box-sizing: border-box;
  padding: ${getBaseUnit(1)} ${getBaseUnit(2)};
  background-color: ${({ backgroundColor }) => getColor(backgroundColor)};
  color: ${getColorFromOptions(colorOptions)};
  display: flex;
  align-items: center;
  gap: ${getBaseUnit(1)};
  width: fit-content;
`;

export type BadgeProps = {
  label: string;
  backgroundColor: BadgeBackgroundColor;
  variant?: Variant;
  startIcon?: IconComponent;
};

const Badge = ({
  label,
  backgroundColor,
  variant = 'large',
  startIcon: StartIcon,
}: BadgeProps) => {
  return (
    <Wrapper backgroundColor={backgroundColor} variant={variant}>
      {StartIcon && (
        <StartIcon
          color={colorOptions[backgroundColor] as BadgeBackgroundColor}
          size="16px"
          noPadding
        />
      )}
      {label}
    </Wrapper>
  );
};

export { Badge };
