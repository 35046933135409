import {
  CHANGE_LOCALE,
  GET_ACCOUNT_REQUEST,
  GET_ACCOUNT_SUCCESS,
  GET_ACCOUNT_ERROR,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_ERROR,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_ERROR,
  CREATE_USER_SESSION,
  DESTROY_USER_SESSION,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_ERROR,
  GET_CYCLES_REQUEST,
  GET_CYCLES_SUCCESS,
  GET_CYCLES_ERROR,
  GET_CUSTOM_CYCLE_REQUEST,
  GET_CUSTOM_CYCLE_SUCCESS,
  GET_CUSTOM_CYCLE_ERROR,
  SET_SELECTED_CYCLE,
  GET_TEAMS_REQUEST,
  GET_TEAMS_SUCCESS,
  GET_TEAMS_ERROR,
} from './constants';

// Change Locale
export function changeLocale(locale) {
  return {
    type: CHANGE_LOCALE,
    locale,
  };
}

// Get Account
export function getAccountRequest() {
  return {
    type: GET_ACCOUNT_REQUEST,
  };
}

export function getAccountSuccess(response) {
  return {
    type: GET_ACCOUNT_SUCCESS,
    response,
  };
}

export function getAccountError(error) {
  return {
    type: GET_ACCOUNT_ERROR,
    error,
  };
}

// Get Profile
export function getProfileRequest() {
  return {
    type: GET_PROFILE_REQUEST,
  };
}

export function getProfileSuccess(response) {
  return {
    type: GET_PROFILE_SUCCESS,
    response,
  };
}

export function getProfileError(error) {
  return {
    type: GET_PROFILE_ERROR,
    error,
  };
}

// Update Profile
export function updateProfileRequest(payload) {
  return {
    type: UPDATE_PROFILE_REQUEST,
    payload,
  };
}

export function updateProfileSuccess(response) {
  return {
    type: UPDATE_PROFILE_SUCCESS,
    response,
  };
}

export function updateProfileError(error) {
  return {
    type: UPDATE_PROFILE_ERROR,
    error,
  };
}

// User Session
export function createUserSession(user, token, nextPathname) {
  return {
    type: CREATE_USER_SESSION,
    user,
    token,
    nextPathname,
  };
}

export function destroyUserSession() {
  return {
    type: DESTROY_USER_SESSION,
  };
}

// User Logout
export function userLogoutRequest() {
  return {
    type: USER_LOGOUT_REQUEST,
  };
}

export function userLogoutSuccess() {
  return {
    type: USER_LOGOUT_SUCCESS,
  };
}

export function userLogoutError(error) {
  return {
    type: USER_LOGOUT_ERROR,
    error,
  };
}

export function getCyclesRequest() {
  return {
    type: GET_CYCLES_REQUEST,
  };
}

export function getCyclesSuccess(response) {
  return {
    type: GET_CYCLES_SUCCESS,
    response,
  };
}

export function getCyclesError(error) {
  return {
    type: GET_CYCLES_ERROR,
    error,
  };
}

export function getCustomCycleRequest(customCycleId) {
  return {
    type: GET_CUSTOM_CYCLE_REQUEST,
    customCycleId,
  };
}

export function getCustomCycleSuccess(response, customCycleId) {
  return {
    type: GET_CUSTOM_CYCLE_SUCCESS,
    response,
    customCycleId,
  };
}

export function getCustomCycleError(error) {
  return {
    type: GET_CUSTOM_CYCLE_ERROR,
    error,
  };
}

export function setSelectedCycle(cycle) {
  return {
    type: SET_SELECTED_CYCLE,
    cycle,
  };
}

export const getTeamsRequest = () => ({
  type: GET_TEAMS_REQUEST,
});

export const getTeamsSuccess = response => ({
  type: GET_TEAMS_SUCCESS,
  payload: response,
});

export const getTeamsError = error => ({
  type: GET_TEAMS_ERROR,
  payload: error,
  error: true,
});
