import { defineMessages } from 'react-intl';

export default defineMessages({
  warning: {
    id: 'app.containers.ConfirmationReminderContainer.warning',
    defaultMessage: 'Please confirm your account. {link}',
  },
  warningLink: {
    id: 'app.containers.ConfirmationReminderContainer.warningLink',
    defaultMessage: 'Resend confirmation email.',
  },
  resendConfirmationSuccess: {
    id:
      'app.containers.ConfirmationReminderContainer.resendConfirmationSuccess',
    defaultMessage:
      'Email sent! Check your inbox for instructions from us on how to confirm your account.',
  },
});
