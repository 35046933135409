import React, { ComponentProps } from 'react';
import styled from 'styled-components';

import { getColor, getBaseUnit } from '../styles/themeGetters';
import { ColorKeys } from '../styles/themes';
import { Box } from '../Box';
import { Button } from '../Button';
import { Text } from '../Text';
import { Badge } from '../Badge';
import { Icons } from '../Icons';

type BannerBackgroundColor = Extract<ColorKeys, 'navy' | 'white'>;

type CommonProps = {
  backgroundColor: BannerBackgroundColor;
  badgeComponent?: React.ReactElement<typeof Badge>;
  title: string;
  description: string;
  actionButtonTitle: string;
};

type SmallBannerProps = CommonProps & {
  imageUrl: string;
  onClick?: () => void;
  onClose?: () => void;
};

type StyledBannerProps = { backgroundColor: BannerBackgroundColor };

type OptionsAllowed = { [key in BannerBackgroundColor]: string };

const getColorFromOptions = (options: OptionsAllowed) => ({
  backgroundColor,
}: StyledBannerProps) => options[backgroundColor];

const colorOptions = {
  navy: getColor('white'),
  white: getColor('black'),
};

const SmallBannerWrapper = styled(Box)<StyledBannerProps>`
  background-color: ${({ backgroundColor }) => getColor(backgroundColor)};
  width: 315px;
  padding: ${getBaseUnit(6)};
  border-radius: ${getBaseUnit(2)};
  border: 1px solid ${getColor('grey-light-100')};
`;

const Title = styled(Text)`
  color: ${getColorFromOptions(colorOptions)};
  max-width: 100%;
`;

const Description = styled(Text)`
  color: ${getColorFromOptions(colorOptions)};
`;

const actionButtonVariantsOptions: {
  [k in BannerBackgroundColor]: ComponentProps<typeof Button>['variant'];
} = { navy: 'outlined', white: 'contained' };

const closeButtonVariantsOptions: {
  [k in BannerBackgroundColor]: ComponentProps<typeof Button>['variant'];
} = { navy: 'contained', white: 'outlined' };

const SmallBanner = ({
  backgroundColor,
  badgeComponent,
  title,
  imageUrl,
  onClick,
  onClose,
  description,
  actionButtonTitle,
}: SmallBannerProps) => (
  <SmallBannerWrapper backgroundColor={backgroundColor}>
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      flexGap={getBaseUnit(2)}
      mb={6}
    >
      <Title backgroundColor={backgroundColor} fontSize={20} display="flex">
        {title}
        <Box ml={2} display="flex" alignItems="center">
          {badgeComponent}
        </Box>
      </Title>
      {onClose && (
        <Button
          color="primary"
          size="s"
          onClick={onClose}
          startIcon={Icons.Close}
          variant={closeButtonVariantsOptions[backgroundColor]}
        />
      )}
    </Box>
    <Box mb={4}>
      <img src={imageUrl} alt="banner" height="42px" width="260px" />
    </Box>
    <Description backgroundColor={backgroundColor} fontSize={16} mb={6}>
      {description}
    </Description>
    <Box display="flex" flexDirection="row" justifyContent="flex-end">
      <Button
        color="primary"
        size="l"
        variant={actionButtonVariantsOptions[backgroundColor]}
        onClick={onClick}
      >
        {actionButtonTitle}
      </Button>
    </Box>
  </SmallBannerWrapper>
);

export { SmallBanner };
