import { call, put, all, takeLatest } from 'redux-saga/effects';

import api from 'utils/api';

import { SEARCH_PROFILE_REQUEST } from './constants';

import { searchProfileSuccess, searchProfileError } from './actions';

// Individual exports for testing
export function* searchProfile({ query, options }) {
  try {
    const response = yield call(api.searchCollaborators, query, options);
    yield put(searchProfileSuccess(response));
  } catch (err) {
    yield put(searchProfileError(err));
  }
}

export function* watcher() {
  yield all([takeLatest(SEARCH_PROFILE_REQUEST, searchProfile)]);
}

export default [watcher];
