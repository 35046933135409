import {
  ARCHIVE_TEAM_REQUEST,
  ARCHIVE_TEAM_SUCCESS,
  ARCHIVE_TEAM_ERROR,
  SEARCH_TEAMS_REQUEST,
  SEARCH_TEAMS_SUCCESS,
  SEARCH_TEAMS_ERROR,
} from './constants';

export const initialState = {
  searchTeamsQuery: '',
  searchTeamsPending: false,
  searchedTeams: { teams: [] },
};

function nestedTeamListReducer(state = initialState, action) {
  switch (action.type) {
    case ARCHIVE_TEAM_REQUEST:
      return { ...state, archiveTeamPending: true };
    case ARCHIVE_TEAM_SUCCESS:
      return { ...state, archiveTeamPending: false };
    case ARCHIVE_TEAM_ERROR:
      return {
        ...state,
        archiveTeamPending: false,
        archiveTeamError: action.error,
      };
    case SEARCH_TEAMS_REQUEST:
      return {
        ...state,
        searchTeamsPending: true,
        searchTeamsQuery: action.query,
      };
    case SEARCH_TEAMS_SUCCESS:
      return {
        ...state,
        searchTeamsPending: false,
        searchedTeams: action.response,
      };
    case SEARCH_TEAMS_ERROR:
      return {
        ...state,
        searchTeamsPending: false,
        searchTeamsError: action.error,
      };
    default:
      return state;
  }
}

export default nestedTeamListReducer;
