import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgExpandAll = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <rect y={3} width={8} height={1.5} rx={0.75} fill={_.color} />
    <rect y={7} width={8} height={1.5} rx={0.75} fill={_.color} />
    <rect y={11} width={8} height={1.5} rx={0.75} fill={_.color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.797 6.22c.27.293.27.767 0 1.06l-2.308 2.5a.655.655 0 0 1-.979 0l-2.307-2.5a.795.795 0 0 1 0-1.06.655.655 0 0 1 .979 0L13 8.19l1.818-1.97a.655.655 0 0 1 .98 0Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgExpandAll);
export default ForwardRef;
