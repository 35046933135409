/**
 * Create the store with asynchronously loaded reducers
 */

import { applyMiddleware } from 'redux';
import { routerMiddleware, LOCATION_CHANGE } from 'react-router-redux';
import createSagaMiddleware from 'redux-saga';
import * as Sentry from '@sentry/browser';
import { configureStore as createStore } from '@reduxjs/toolkit';

import createReducer from './reducers';

const sagaMiddleware = createSagaMiddleware({
  onError: error => {
    Sentry.withScope(scope => {
      scope.setTag('type', 'redux-saga');
      Sentry.captureException(error);
    });
  },
  logger: (level, ...args) => {
    if (level === 'error') {
      Sentry.withScope(scope => {
        scope.setTag('type', 'redux-saga');
        Sentry.captureMessage(args.join(' '));
      });
    }
  },
});

// Provide patnName and prevPathName to router
let prevPathname = '';
const pathMiddleware = () => next => action => {
  if (action.type === LOCATION_CHANGE) {
    const newAction = {
      ...action,
      payload: {
        ...action.payload,
        prevPathname,
      },
    };
    prevPathname = action.payload.pathname;
    return next(newAction);
  }
  return next(action);
};

// When navigating across pages React reuses existing DOM nodes.
// This can cause some weird behavior, i.e when selecting all text (CMD+A),
// The selection is saved in a special object which can be accessed via
// window.getSelection() and is used across all pages.
// This selection contains information about the 'anchorNode', where the selection starts.
// If the node is not removed, i.e when changing routes it appears as text is permanently selected
// This middleware is a hack to force unselect on route change,
// effectively emulating the behavior of a server side rendered app.
const resetSelectionOnRouteChangeMiddleware = () => next => action => {
  if (action.type === '@@router/LOCATION_CHANGE') {
    if (global?.getSelection()) {
      global.getSelection().removeAllRanges();
    }
    return next(action);
  }
  return next(action);
};

export default function configureStore(history) {
  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  // 3. pathMiddleware: Provides previous and current pathName to the state
  const middlewares = [
    sagaMiddleware,
    routerMiddleware(history),
    pathMiddleware,
    resetSelectionOnRouteChangeMiddleware,
  ];

  const store = createStore({
    reducer: createReducer(),
    enhancers: [applyMiddleware(...middlewares)],
    // disable serializeable check and thunk
    // https://redux-toolkit.js.org/api/getDefaultMiddleware#customizing-the-included-middleware
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
        thunk: false,
      }),
  });

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.asyncReducers = {}; // Async reducer registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createReducer(store.injectedReducers));
    });
  }

  return store;
}
