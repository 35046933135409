/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { IntlProvider, createIntl } from 'react-intl';
import Helmet from 'react-helmet';
import { selectLocale } from 'containers/App/selectors';

export class LanguageProvider extends React.PureComponent {
  render() {
    const locale = this.props.locale;
    const messages = this.props.messages[this.props.locale];

    // This is exposed as a service in app/utils/intlService.js
    window.intlService = createIntl({
      locale,
      messages,
    });

    return (
      <>
        <Helmet>
          <html lang={this.props.locale} />
        </Helmet>
        <IntlProvider locale={locale} key={locale} messages={messages}>
          {React.Children.only(this.props.children)}
        </IntlProvider>
      </>
    );
  }
}

LanguageProvider.propTypes = {
  locale: PropTypes.string,
  messages: PropTypes.object,
  children: PropTypes.element.isRequired,
};

const mapStateToProps = createStructuredSelector({
  locale: selectLocale,
});

export default connect(mapStateToProps)(LanguageProvider);
