import React, { ReactNode, useState } from 'react';
import { TabContext } from '../context/TabContext';

type TabsProps = {
  children: ReactNode;
  /**
   * Tab which should be selected initially. 0-based.
   */
  initialSelected?: number;
};

/**
 * Tabs make it easy to switch between different views.
 * The naming follows WAI-ARA specs: https://w3c.github.io/aria-practices/#tabpanel.
 * Keyboard navigation with `Arrow-Left`, `Arrow-Right` available. An `onSelectTab` can be
 * passed to `Tabs`.
 */
const Tabs = ({ children, initialSelected }: TabsProps) => {
  const [selectedTab, setSelectedTab] = useState(initialSelected);
  const [focusedTab, setFocusedTab] = useState(undefined);
  // Closure to keep track of `TabPanel` count.
  // Referenced further down within React.Children.map()
  let tabPanelCounter = 0;

  return (
    <TabContext.Provider
      value={{
        selectedTab,
        setSelectedTab,
        focusedTab,
        setFocusedTab,
      }}
    >
      {React.Children.map<ReactNode, ReactNode>(children, child => {
        if (React.isValidElement(child)) {
          // Necessary to access displayName.
          // Pattern documented here https://github.com/rmolinamir/typescript-cheatsheet#usememo
          // Variation for pattern here: https://stackoverflow.com/questions/70250412/property-docgeninfo-does-not-exist-on-type-string-for-react-child
          const RFC_Child: React.FunctionComponent = child.type as React.FunctionComponent;
          const { displayName } = RFC_Child;

          if (displayName === 'TabPanel') {
            const clonedElement = React.cloneElement(child, {
              currentIndex: tabPanelCounter,
            } as React.HTMLAttributes<HTMLElement>);

            tabPanelCounter += 1;

            return clonedElement;
          }
          return child;
        }
      })}
    </TabContext.Provider>
  );
};

export { Tabs };
