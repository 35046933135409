import account from './endpoints/account';
import profile from './endpoints/profile';
import invitations from './endpoints/invitations';
import goals from './endpoints/goals';
import keyResults from './endpoints/keyResults';
import cycles from './endpoints/cycles';
import users from './endpoints/users';
import teams from './endpoints/teams';
import labels from './endpoints/labels';
import customGoalFilters from './endpoints/customGoalFilters';
import checkIns from './endpoints/checkIns';
import teamMemberReports from './endpoints/teamMemberReports';
import search from './endpoints/search';
import settings from './endpoints/settings';
import metrics from './endpoints/metrics';
import jira from './endpoints/jira';
import recommendedActions from './endpoints/recommendedActions';
import overTimeCharts from './endpoints/overTimeCharts';
import kpis from './endpoints/kpis';
import initiatives from './endpoints/initiatives';
import currencies from './endpoints/currencies';
import goalTemplates from './endpoints/goalTemplates';
import conversations from './endpoints/conversations';
import announcement from './endpoints/announcements';
import uploads from './endpoints/uploads';
import planner from './endpoints/planner';
import businessUnits from './endpoints/businessUnits';
import notes from './endpoints/notes';
import comments from './endpoints/comments';
import qualityChecker from './endpoints/qualityChecker';

const api = {
  ...account,
  ...profile,
  ...invitations,
  ...goals,
  ...keyResults,
  ...cycles,
  ...users,
  ...teams,
  ...labels,
  ...customGoalFilters,
  ...checkIns,
  ...teamMemberReports,
  ...search,
  ...settings,
  ...metrics,
  ...jira,
  ...recommendedActions,
  ...overTimeCharts,
  ...kpis,
  ...initiatives,
  ...currencies,
  ...goalTemplates,
  ...conversations,
  ...announcement,
  ...uploads,
  ...planner,
  ...businessUnits,
  ...notes,
  ...comments,
  ...qualityChecker,
};

export default api;
