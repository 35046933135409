import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgArrowUp = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      d="M2.529 7.471a.748.748 0 0 0 1.057 1.058L7.25 4.872v8.378a.75.75 0 0 0 1.5 0V4.872l3.653 3.66a.753.753 0 1 0 1.065-1.064L8 2 2.529 7.471Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgArrowUp);
export default ForwardRef;
