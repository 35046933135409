import { call, put, fork, takeLatest } from 'redux-saga/effects';
import { intlService } from 'utils/intlService';

import api from 'utils/api';
import { errorMessage } from 'utils/form';
import {
  createSuccessToast,
  createErrorToast,
  clearAllToasts,
} from 'containers/ToastNotificationContainer/actions';

import { RESEND_CONFIRMATION_REQUEST } from './constants';
import {
  resendConfirmationSuccess,
  resendConfirmationFailure,
} from './actions';
import messages from './messages';

// Individual exports for testing
export function* resendConfirmationRequest({ email }) {
  yield put(clearAllToasts());

  try {
    const response = yield call(api.resendConfirmation, email);
    yield put(resendConfirmationSuccess(response));
    yield put(
      createSuccessToast(
        intlService().formatMessage(messages.resendConfirmationSuccess)
      )
    );
  } catch (err) {
    yield put(resendConfirmationFailure(err));
    yield put(createErrorToast(errorMessage(err)));
  }
}

/**
 * Watches for RESEND_CONFIRMATION_REQUEST actions and calls resendConfirmationRequest when one comes in.
 * By using `takeLatest` only the result of the latest API call is applied.
 */
export function* resendConfirmationRequestWatcher() {
  yield takeLatest(RESEND_CONFIRMATION_REQUEST, resendConfirmationRequest);
}

/**
 * Root saga manages watcher lifecycle
 */
export function* rootSaga() {
  yield fork(resendConfirmationRequestWatcher);
}

export default [rootSaga];
