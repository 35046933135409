import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgDecision = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.746 15.886a.819.819 0 0 1-.42.114c-.22 0-.392-.071-.512-.213-.116-.137-.173-.322-.173-.554v-2.058h-.297c-.698 0-1.297-.13-1.798-.39a2.772 2.772 0 0 1-1.149-1.137C.132 11.151 0 10.55 0 9.845V4.336c0-.705.132-1.303.397-1.796a2.707 2.707 0 0 1 1.149-1.142C2.05 1.133 2.668 1 3.395 1h9.21c.727 0 1.341.133 1.842.398.506.265.89.646 1.156 1.142.265.493.397 1.091.397 1.796v5.51c0 .704-.132 1.305-.397 1.802-.265.493-.65.871-1.156 1.136-.5.26-1.115.39-1.842.39H7.866l-2.65 2.357c-.184.166-.34.284-.47.355ZM7.827 3h.29c.468 0 .842.523.827.912l-.117 3.134c-.015.372-.38.666-.826.666-.447 0-.812-.294-.827-.666L7 3.912c-.016-.39.358-.912.826-.912ZM7 9.778c0 .556.448 1.008 1 1.008s1-.452 1-1.008C9 9.22 8.552 8.77 8 8.77s-1 .45-1 1.008Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgDecision);
export default ForwardRef;
