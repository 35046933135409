import React from 'react';
import styled from 'styled-components';
import { IconComponent } from '../../../Icons/helpers';

type WrapperProps = { $disabled: boolean };

const Wrapper = styled.span<WrapperProps>`
  margin-right: 8px;
  align-self: center;
  cursor: ${props => (props.$disabled ? 'not-allowed' : 'initial')};
`;

type Props = {
  disabled?: boolean;
  icon: IconComponent;
};

const InputFieldStartIcon = ({ disabled = false, icon: Icon }: Props) => (
  <Wrapper $disabled={disabled}>
    <Icon color="navy" />
  </Wrapper>
);

export { InputFieldStartIcon };
