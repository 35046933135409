import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgCheck = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      d="M6.63 14c.396 0 .7-.166.914-.5l6.236-9.906c.076-.119.131-.233.167-.342A1.06 1.06 0 0 0 14 2.924c0-.27-.087-.49-.26-.664a.902.902 0 0 0-.666-.26.867.867 0 0 0-.467.116c-.124.073-.244.201-.36.383l-5.643 9.182-2.892-3.763c-.208-.27-.466-.404-.773-.404A.912.912 0 0 0 2 8.458c0 .124.02.242.06.356.044.114.115.23.213.35l3.452 4.364c.248.315.55.472.906.472Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCheck);
export default ForwardRef;
