import { css } from 'styled-components';

const small = css`
  height: 1.7rem;
  padding: 0.2rem 0.525rem;
  font-size: 0.75rem;
  line-height: 1.2rem;
`;

const regular = css`
  height: 2.75rem;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  line-height: 1.125rem;
`;

const sizes = {
  small,
  regular,
};

export default sizes;
