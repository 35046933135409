import { getRequestV2 } from '../../methods';

const getAccountV2 = () => getRequestV2('account');
const getPublicAccount = () => getRequestV2('public/account');

const account = {
  getAccountV2,
  getPublicAccount,
};

export default account;
