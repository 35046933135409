import {
  SEARCH_PROFILE_REQUEST,
  SEARCH_PROFILE_SUCCESS,
  SEARCH_PROFILE_ERROR,
  RESET_STATE,
} from './constants';

export function searchProfileRequest(query, options) {
  return {
    type: SEARCH_PROFILE_REQUEST,
    query,
    options,
  };
}

export function searchProfileSuccess(response) {
  return {
    type: SEARCH_PROFILE_SUCCESS,
    response,
  };
}

export function searchProfileError(error) {
  return {
    type: SEARCH_PROFILE_ERROR,
    error,
  };
}

export function resetState() {
  return {
    type: RESET_STATE,
  };
}
