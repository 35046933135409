import { getS3UploadChecksum } from 'utils/checksum';
import { postRequestV2 } from '../../methods';

const buildPayload = async file => {
  const checksum = await getS3UploadChecksum(file);

  return {
    blob: {
      filename: file.name,
      byteSize: file.size,
      checksum,
      contentType: file.type,
    },
  };
};

const uploadToS3Bucket = (upload, file) => {
  const { url, headers, signedId } = upload;

  return fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Disposition': headers.contentDisposition,
      'Content-MD5': headers.contentMD5,
      'Content-Type': headers.contentType,
    },
    body: file,
  }).then(() => signedId);
};

const getSignedUrl = (serviceName, payload) =>
  postRequestV2(`direct_uploads/${serviceName}`, payload);

// https://aws.amazon.com/blogs/compute/uploading-to-amazon-s3-directly-from-a-web-or-mobile-application/
const directUpload = async (serviceName, file) => {
  const payload = await buildPayload(file);

  const response = await getSignedUrl(serviceName, payload);
  return uploadToS3Bucket(response.upload, file);
};

const directUploadAvatar = file => directUpload('avatars', file);

const uploads = {
  directUpload,
  directUploadAvatar,
};

export default uploads;
