import { getRequest, getRequestV2 } from '../../methods';

const getCyclesRequest = () => getRequest('cycles');
const getPhasesRequest = () => getRequestV2('cycles/phases');

const cycles = {
  getCyclesRequest,
  getPhasesRequest,
};

export default cycles;
