import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgSolidChevronRight = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m6.037 11.873 4.723-3.422c.276-.199.32-.563.1-.812a.615.615 0 0 0-.1-.09L6.037 4.127a.684.684 0 0 0-.897.09.545.545 0 0 0-.14.361v6.844c0 .32.286.578.638.578a.681.681 0 0 0 .4-.127Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgSolidChevronRight);
export default ForwardRef;
