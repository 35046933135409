import React from 'react';

import { Avatar } from '../Avatar';

export type BusinessUnitAvatarProps = {
  businessUnit: {
    name: string;
  };
  size: 'xs' | 's' | 'm' | 'l' | 'xl';
  fallbackText?: string;
  onClick?: () => void;
};

/**
 * This component is a wrapper around Avatar.
 * It is used to display a Business Unit avatar.
 * This component centralizes the logic for displaying a Business Unit avatar.
 * @param businessUnit The Business Unit to display the avatar for.
 * @param size The size of the avatar.
 * @param fallbackText Text to display in the tooltip.
 * @param onClick The function to call when the avatar is clicked.
 * @returns The rendered component.
 * @example
 * <BusinessUnitAvatar businessUnit={businessUnit} size="l" onClick={handleClick} />
 */

const BusinessUnitAvatar = ({
  businessUnit,
  size,
  fallbackText,
  onClick,
}: BusinessUnitAvatarProps) => (
  <Avatar
    type="businessUnit"
    fallbackText={fallbackText || businessUnit.name}
    size={size}
    onClick={onClick}
  />
);

export { BusinessUnitAvatar };
