import { decamelizeKeys } from 'humps';

export default function body(payload) {
  return JSON.stringify(decamelizeKeys(payload)).replace(
    /[\u007F-\uFFFF]/g,
    c =>
      // necessary for ie edge
      // https://stackoverflow.com/questions/39419250/fetch-api-doesnt-work-with-non-english-characters-in-microsoft-edge
      // eslint-disable-next-line prefer-template
      '\\u' + ('0000' + c.charCodeAt(0).toString(16)).substr(-4)
  );
}
