import { createSelector } from 'reselect';

/**
 * Direct selector to the confirmationReminderContainer state domain
 */
const selectConfirmationReminderContainerDomain = state =>
  state.confirmationReminder;

/**
 * Other specific selectors
 */
const selectIsSubmitting = () =>
  createSelector(
    selectConfirmationReminderContainerDomain,
    substate => substate.isSubmitting
  );

const selectError = () =>
  createSelector(
    selectConfirmationReminderContainerDomain,
    substate => substate.error
  );

const selectEmailSent = () =>
  createSelector(
    selectConfirmationReminderContainerDomain,
    substate => substate.emailSent
  );

export {
  selectConfirmationReminderContainerDomain,
  selectIsSubmitting,
  selectError,
  selectEmailSent,
};
