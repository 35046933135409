import { CurrentUser } from 'types/CurrentUser';
import { UserFeatureFlagName } from 'types/UserFeatureFlags';
import { CurrentAccount } from 'types/CurrentAccount';
import { AccountFeatureFlagName } from 'types/AccountFeatureFlags';
import {
  accessAccountFeatureFlagMap,
  accessUserFeatureFlags,
} from 'utils/hooks/globalState/util';

/**
 * For a given user, checks if the user is a viewer.
 * Use this only if working in a legacy (class-based) component where you can't
 * use hooks. Otherwise, use the hook `useHasUserFeature`.
 *
 * @deprecated
 * @param user
 */
export const legacyIsViewer = (user: CurrentUser): boolean =>
  user ? user.viewer : false;

/**
 * For a given user, checks if the user has a certain feature flag.
 * Use this only if working in a legacy (class-based) component where you can't
 * use hooks. Otherwise, use the hook `useHasUserFeature`.
 *
 * @deprecated
 * @param user
 * @param ff
 */
export const legacyHasUserFeatureFlag = (
  user: CurrentUser,
  ff: UserFeatureFlagName
): boolean => (user ? accessUserFeatureFlags(user).includes(ff) : false);

/**
 * For a given account, checks if the account has a certain feature flag active.
 * Use this only if working in a legacy (class-based) component where you can't
 * use hooks. Otherwise, use the hook `useHasAccountFeatureFlagActive`.
 *
 * @deprecated
 * @param account
 * @param ff
 */
export const legacyHasAccountFeatureFlagActive = (
  account: CurrentAccount,
  ff: AccountFeatureFlagName
): boolean =>
  account ? accessAccountFeatureFlagMap(account)[ff] === 'active' : false;
