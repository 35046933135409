import { css } from 'styled-components';

import sizes from './sizes';
import variants from './variants';
import variantsOutline from './variantsOutline';

const generalStyle = css`
  display: inline-block;
  width: ${props => (props.block ? '100%' : 'auto')};
  margin: 0;

  border-radius: 0.25rem;
  text-align: center;
  vertical-align: bottom;
  white-space: nowrap;

  user-select: none;
  cursor: pointer;
  outline: 0;

  &:disabled,
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const button = {
  generalStyle,
  sizes,
  variants,
  variantsOutline,
};

export default button;
