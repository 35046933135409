import { Matcher } from './types';

export const matchAllTalkingPoints: Matcher = row => {
  if (row.entityType !== 'goal_grouping' && row.data.actions) {
    if (row.data.actions.entityType === 'conversation_initiative_actions') {
      return row.data.actions.talkingPointsCount > 0;
    }
    if (row.data.actions.entityType === 'conversation_key_result_actions') {
      return row.data.actions.talkingPointsCount > 0;
    }
    if (row.data.actions.entityType === 'conversation_goal_actions') {
      return row.data.actions.talkingPointsCount > 0;
    }
    if (row.data.actions.entityType === 'conversation_aligned_goal_actions') {
      return row.data.actions.talkingPointsCount > 0;
    }
  }

  return false;
};
