import { deleteRequestV2, postRequestV2, patchRequestV2 } from '../../methods';

const createComment = payload => postRequestV2('comments', payload);

const editComment = (commentId, payload) =>
  patchRequestV2(`comments/${commentId}`, payload);

const deleteComment = commentId => deleteRequestV2(`comments/${commentId}`);

const comments = {
  createComment,
  editComment,
  deleteComment,
};

export default comments;
