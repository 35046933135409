import React from 'react';
import { InputControl } from '../components/InputControl';
import { CommonProps } from '../types';
import { InputOption, CircleOption } from '../components/InputOption';

type Option = {
  value: string;
  label: string;
  helpText: string;
};

const RadioInput = (props: { options: Option[] } & CommonProps) => {
  const {
    name,
    error,
    disabled,
    helpText,
    label,
    options,
    condensed,
    validations = {},
    formHooks: { register, watch },
  } = props;

  const value = watch(name);

  return (
    <InputControl
      condensed={condensed}
      label={label}
      error={error}
      helpText={helpText}
    >
      {options.map((option, key) => (
        <InputOption
          disabled={disabled}
          label={label}
          helpText={option.helpText}
          key={key}
        >
          <CircleOption
            disabled={disabled}
            hasError={Boolean(error)}
            isSelected={value === option.value}
            value={option.value}
            register={register}
            name={name}
            validations={validations}
          />
        </InputOption>
      ))}
    </InputControl>
  );
};

export { RadioInput };
