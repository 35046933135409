import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgClose = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.717 12.778a.752.752 0 0 0 1.061 0 .748.748 0 0 0 0-1.06L9.05 7.998l3.728-3.719a.748.748 0 0 0 0-1.06.752.752 0 0 0-1.061 0L7.989 6.94 4.28 3.24a.752.752 0 0 0-1.062 0 .748.748 0 0 0 0 1.06l3.708 3.7-3.708 3.7a.748.748 0 0 0 0 1.06.752.752 0 0 0 1.062 0l3.708-3.7 3.728 3.719Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgClose);
export default ForwardRef;
