import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgAlignedGoal = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 4.333H6.514a3.342 3.342 0 0 1-2.123 2.162 6 6 0 0 0 5.114 5.114A3.335 3.335 0 0 1 16 12.667a3.333 3.333 0 0 1-6.528.953A8 8 0 0 1 2.38 6.528 3.335 3.335 0 0 1 3.333 0C4.826 0 6.09.981 6.514 2.333H16v2Zm-2 8.334a1.333 1.333 0 1 0-2.667 0 1.333 1.333 0 0 0 2.667 0ZM4.667 3.333a1.333 1.333 0 1 0-2.667 0 1.333 1.333 0 0 0 2.667 0Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgAlignedGoal);
export default ForwardRef;
