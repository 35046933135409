import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgWand = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <g clipPath="url(#Wand_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.358 12.704a.632.632 0 0 0-.188.466c0 .18.06.333.18.458.125.125.28.188.466.188a.608.608 0 0 0 .465-.18l1.637-1.637a.599.599 0 0 0 .188-.45.632.632 0 0 0-.188-.466.608.608 0 0 0-.458-.188.632.632 0 0 0-.465.188l-1.637 1.621ZM.653 7.344a.627.627 0 0 0-.458.195.627.627 0 0 0-.195.458c0 .18.065.335.195.465a.64.64 0 0 0 .458.188h2.328a.64.64 0 0 0 .458-.188.636.636 0 0 0 .195-.465.627.627 0 0 0-.195-.458.627.627 0 0 0-.458-.196H.653Zm3.342-2.418a.623.623 0 0 0 .458.187c.185 0 .34-.062.465-.187a.623.623 0 0 0 .188-.458.632.632 0 0 0-.188-.466l-1.63-1.637a.623.623 0 0 0-.457-.188.632.632 0 0 0-.653.646c0 .18.062.333.187.458l1.63 1.645ZM8.657.653a.611.611 0 0 0-.195-.458A.636.636 0 0 0 7.997 0a.627.627 0 0 0-.458.195.611.611 0 0 0-.196.458v2.313c0 .18.065.335.196.465.13.13.282.196.458.196.18 0 .335-.065.465-.196a.636.636 0 0 0 .195-.465V.653Zm2.418 3.349a.632.632 0 0 0-.188.466c0 .18.063.332.188.458a.623.623 0 0 0 .458.187.658.658 0 0 0 .473-.187l1.63-1.637a.632.632 0 0 0 .187-.466.632.632 0 0 0-.188-.465.623.623 0 0 0-.457-.188.623.623 0 0 0-.459.188l-1.644 1.644Zm2.989 11.293c.16.16.355.24.585.24.235 0 .428-.08.578-.24a.8.8 0 0 0 .226-.586c0-.22-.075-.41-.226-.57l-7.14-7.164a.781.781 0 0 0-.586-.24c-.23 0-.42.08-.57.24a.827.827 0 0 0-.233.586c0 .225.077.418.232.578l7.133 7.156Zm-5.602-2.726c.13.13.195.286.195.466v2.313c0 .18-.065.332-.195.458a.636.636 0 0 1-.466.195.627.627 0 0 1-.458-.195.611.611 0 0 1-.195-.458v-2.313c0-.18.065-.335.195-.466a.627.627 0 0 1 .458-.195c.18 0 .335.065.466.195ZM15.34 8.65c.18 0 .335-.063.465-.188a.635.635 0 0 0 .196-.465.627.627 0 0 0-.196-.458.635.635 0 0 0-.465-.195h-2.313a.627.627 0 0 0-.458.195.627.627 0 0 0-.195.458c0 .18.065.335.195.465a.64.64 0 0 0 .458.188h2.313Z"
        fill={_.color}
      />
    </g>
    <defs>
      <clipPath id="Wand_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgWand);
export default ForwardRef;
