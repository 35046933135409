import { Matcher } from './types';

export const matchAchievements: Matcher = (row, props) => {
  if (row.entityType !== 'goal_grouping') {
    const { talkingPoints } = props;
    let talkingPoint;

    if (talkingPoints) {
      if (row.entityType === 'initiative') {
        talkingPoint = talkingPoints.find(tp => tp.initiativeId === row.id);
      }
      if (row.entityType === 'key_result') {
        talkingPoint = talkingPoints.find(tp => tp.keyResultId === row.id);
      }
      if (row.entityType === 'goal') {
        talkingPoint = talkingPoints.find(tp => tp.goalId === row.id);
      }

      return (
        (talkingPoint &&
          (talkingPoint.tagName === 'kudos' ||
            talkingPoint.tagName === 'celebration')) ??
        false
      );
    }

    return false;
  }

  return false;
};
