import React from 'react';

import WorkpathLogoNavy from './workpath-logo-navy.svg';
import WorkpathLogoWhite from './workpath-logo-white.svg';
import WorkpathLogoYellow from './workpath-logo-yellow.svg';

type Color = 'white' | 'navy';

type Props = Partial<{
  color: Color;
  height: number;
}>;

const getLogoByColor = (color: Color) => {
  switch (color) {
    case 'white':
      return WorkpathLogoWhite;
    case 'navy':
      return WorkpathLogoNavy;
    default:
      return WorkpathLogoYellow;
  }
};

const WorkpathLogo = ({ color = 'navy', height = 24 }: Props) => (
  <img src={getLogoByColor(color)} height={height} alt="Workpath logo" />
);

export default WorkpathLogo;
