import React from 'react';
import { FieldError } from 'react-hook-form';

import { InputControl } from '../components/InputControl';
import { SegmentedControlProps } from './types';
import { RawSegmentedControl } from './RawSegmentedControl';

type Props = {
  label?: string;
  helpText?: string;
  error?: FieldError;
  condensed?: boolean;
} & SegmentedControlProps;

export const SegmentedControl = ({
  label,
  helpText,
  error,
  onChange,
  disabled,
  items,
  initialSelectedId,
  condensed,
}: Props) => (
  <InputControl
    condensed={condensed}
    error={error}
    helpText={helpText}
    label={label}
  >
    <RawSegmentedControl
      initialSelectedId={initialSelectedId}
      items={items}
      onChange={onChange}
      disabled={disabled}
    />
  </InputControl>
);
