import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgTalkingPoint = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <g clipPath="url(#clip0_6985_2444)">
      <path
        d="M1.08929 11.0625C0.762723 11.0625 0.5 10.8117 0.5 10.5C0.5 10.1883 0.762723 9.9375 1.08929 9.9375H6.98214C7.30871 9.9375 7.57143 10.1883 7.57143 10.5C7.57143 10.8117 7.30871 11.0625 6.98214 11.0625H1.08929ZM1.08929 8.0625C0.762723 8.0625 0.5 7.81172 0.5 7.5C0.5 7.18828 0.762723 6.9375 1.08929 6.9375H10.9107C11.2373 6.9375 11.5 7.18828 11.5 7.5C11.5 7.81172 11.2373 8.0625 10.9107 8.0625H1.08929ZM0.5 4.5C0.5 4.18828 0.762723 3.9375 1.08929 3.9375H6.98214C7.30871 3.9375 7.57143 4.18828 7.57143 4.5C7.57143 4.81172 7.30871 5.0625 6.98214 5.0625H1.08929C0.762723 5.0625 0.5 4.81172 0.5 4.5ZM1.08929 2.0625C0.762723 2.0625 0.5 1.81172 0.5 1.5C0.5 1.18828 0.762723 0.9375 1.08929 0.9375H10.9107C11.2373 0.9375 11.5 1.18828 11.5 1.5C11.5 1.81172 11.2373 2.0625 10.9107 2.0625H1.08929Z"
        fill={_.color}
      />
    </g>
    <defs>
      <clipPath id="clip0_6985_2444">
        <rect
          width="11"
          height="12"
          fill="white"
          transform="matrix(1 0 0 -1 0.5 12)"
        />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgTalkingPoint);
export default ForwardRef;
