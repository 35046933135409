import React from 'react';
import PropTypes from 'prop-types';

import Wrapper from './Wrapper';

const Alert = ({ kind, className, children }) => (
  <Wrapper role="alert" kind={kind} className={className}>
    {children}
  </Wrapper>
);

Alert.propTypes = {
  kind: PropTypes.oneOf(['info', 'success', 'warning', 'danger']),
  className: PropTypes.string,

  children: PropTypes.node,
};

Alert.defaultProps = {
  kind: 'info',
};

export default Alert;
