import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgArrowRight = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      d="M8.529 2.529a.748.748 0 0 0-1.058 1.057l3.656 3.664H2.75a.75.75 0 0 0 0 1.5h8.377l-3.659 3.653a.753.753 0 1 0 1.064 1.065L14 8 8.529 2.529Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgArrowRight);
export default ForwardRef;
