import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgCapacity = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.83 5.874c-.114.084-.27.126-.47.126H.641c-.2 0-.356-.042-.47-.126A.4.4 0 0 1 0 5.529c0-.21.069-.434.206-.67.14-.237.34-.457.6-.661.263-.206.579-.373.948-.501A3.71 3.71 0 0 1 2.996 3.5c.462 0 .877.065 1.247.196.369.128.684.295.944.501.263.204.464.424.604.66.14.237.209.46.209.67a.4.4 0 0 1-.17.346ZM3.714 2.718c-.218.13-.456.196-.714.196-.258 0-.496-.066-.714-.196a1.52 1.52 0 0 1-.522-.528 1.445 1.445 0 0 1-.195-.746c0-.27.065-.514.196-.73A1.49 1.49 0 0 1 2.29.191C2.508.064 2.744 0 3 0a1.435 1.435 0 0 1 1.236.707c.132.217.198.46.198.73a1.48 1.48 0 0 1-.72 1.281ZM5.83 15.874c-.114.084-.27.126-.47.126H.641c-.2 0-.356-.042-.47-.126a.4.4 0 0 1-.17-.345c0-.21.069-.434.206-.67.14-.237.34-.457.6-.661.263-.206.579-.373.948-.501.37-.13.783-.196 1.242-.196.462 0 .877.065 1.247.196.369.128.684.295.944.501.263.204.464.424.604.66.14.237.209.46.209.67a.4.4 0 0 1-.17.346Zm-2.116-3.156c-.218.13-.456.196-.714.196-.258 0-.496-.066-.714-.196a1.52 1.52 0 0 1-.522-.528 1.445 1.445 0 0 1-.195-.746c0-.27.065-.514.196-.73a1.49 1.49 0 0 1 .525-.521c.218-.129.454-.193.71-.193a1.434 1.434 0 0 1 1.236.707c.132.217.198.46.198.73a1.48 1.48 0 0 1-.72 1.281ZM8 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H8Zm6 2h-3v1h3V3Zm-7 9a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-3Zm2 1h5v1H9v-1Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCapacity);
export default ForwardRef;
