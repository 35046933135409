import {
  SEARCH_REQUEST,
  SEARCH_END,
  RESET_SEARCH,
  RESET_STATE,
} from './constants';

export function searchRequest(query) {
  return {
    type: SEARCH_REQUEST,
    query,
  };
}

export function searchEnd(usersSearch, teamsSearch, goalsSearch) {
  return {
    type: SEARCH_END,
    ...usersSearch,
    ...teamsSearch,
    ...goalsSearch,
  };
}

export function resetSearch() {
  return {
    type: RESET_SEARCH,
  };
}

export function resetState() {
  return {
    type: RESET_STATE,
  };
}
