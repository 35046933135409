import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { getBaseUnit, getColor } from '../../styles/themeGetters';
import { hexToRgba } from '../../utils/hexToRgba';
import { RowSummaryEntity } from '../types';

import { Avatar } from '../../Avatar';
import { Box } from '../../Box';
import { ConfidenceLevelIndicator } from '../../ConfidenceLevelIndicator';
import { ProgressIndicator } from '../../ProgressIndicator';
import { Text } from '../../Text';
import { Tooltip } from '../../Tooltip';

import { HoverBox } from '../common/HoverBox';
import { OwnerAvatar } from '../renderers/OwnerRenderer';
import { LinkRenderer } from '../common/LinkRenderer';

import messages from '../renderers/DynamicHeaderRenderer/messages';
import { transformSelectedRow } from './transformSelectedRow';

type RowSummaryProps = {
  row: RowSummaryEntity;
};

const Dash = () => (
  <Box
    height="56px"
    width="40px"
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <Text fontSize={14} color="grey-dark">
      -
    </Text>
  </Box>
);

const Wrapper = styled(Box)`
  background-color: ${hexToRgba(getColor('grey-light-50'), 0.5)};
`;

const RowSummary = ({ row }: RowSummaryProps) => {
  const { formatMessage } = useIntl();

  const transformedRow = transformSelectedRow(row);

  const { entityType } = transformedRow;
  const { owner, progress, team, confidenceLevel } = transformedRow.data;

  return (
    <Wrapper
      width="100%"
      height="56px"
      backgroundColor="white"
      borderRadius={2}
      display="flex"
      justifyContent="space-between"
      pl={7}
      pr={7}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        aria-label="cell owner"
        flexGap={getBaseUnit(3)}
      >
        <OwnerAvatar owner={owner} />
        <Text>
          {owner.entityType === 'owner' &&
            `${owner.firstname} ${owner.lastname}`}
          {owner.entityType === 'owner_team' && owner.name}
        </Text>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        aria-label="cell owner"
        flexGap={getBaseUnit(7)}
      >
        {entityType === 'goal' && team && (
          <LinkRenderer url={team.url}>
            <Avatar fallbackText={team.name} size="s" type="team" />
          </LinkRenderer>
        )}
        {progress &&
          (progress.entityType === 'goal_progress' ||
            progress.entityType === 'key_result_progress') && (
            <Tooltip
              maxWidth="280px"
              tooltipContent={formatMessage(messages.progress)}
            >
              <HoverBox
                applyHoverEffect
                display="flex"
                alignItems="center"
                pl={4}
                pr={4}
                cursor="default"
              >
                <ProgressIndicator
                  value={progress.value}
                  fontSize={14}
                  disabled={progress.disabled}
                />
              </HoverBox>
            </Tooltip>
          )}
        {confidenceLevel &&
          (confidenceLevel.entityType === 'initiative_confidence_level' ? (
            <Dash />
          ) : (
            <Tooltip
              maxWidth="280px"
              tooltipContent={formatMessage(messages.confidenceLevelTooltip)}
            >
              <HoverBox applyHoverEffect cursor="default">
                <ConfidenceLevelIndicator value={confidenceLevel.value} />
              </HoverBox>
            </Tooltip>
          ))}
      </Box>
    </Wrapper>
  );
};

export { RowSummary };
