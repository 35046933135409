const spacings = {
  zero: '0',
  // xxs: '.5625rem', // 9px
  xs: '.875rem', // 14px
  s: '1rem', // 16px
  m: '1.25rem', // 20px
  l: '1.75rem', // 28px
  // xl: '2.5rem', // 40px
};

export default spacings;
