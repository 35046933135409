import { Matcher } from './types';

export const matchUnresolvedNotes: Matcher = row => {
  if (row.entityType !== 'goal_grouping' && row.data.actions) {
    if ('notesCount' in row.data.actions) {
      return row.data.actions.notesCount > 0;
    }
  }

  return false;
};
