import { postRequestV2, getRequestV2, patchRequestV2 } from '../../methods';

const addMemberToBusinessUnit = (id, payload) =>
  patchRequestV2(`bu/${id}/add_user`, payload);

const removeMemberFromBusinessUnit = (id, payload) =>
  patchRequestV2(`bu/${id}/remove_user`, payload);

const createBusinessUnit = payload => postRequestV2('bu', payload);

const getBusinessUnits = () => getRequestV2('bu');

const getBusinessUnit = id => getRequestV2(`bu/${id}`);

const editBusinessUnit = (id, payload) => patchRequestV2(`bu/${id}`, payload);

const getBusinessUnitsMemberships = () => getRequestV2('bu/memberships');

const getBusinessUnitTeams = id => getRequestV2(`bu/${id}/teams`);

const getBusinessUnitRootTeams = ({ businessUnitId, cycleId }) =>
  getRequestV2(`bu/${businessUnitId}/teams/root?cycle_id=${cycleId}`);

const getBusinessUnitSubTeams = ({ businessUnitId, cycleId, parentTeamId }) =>
  getRequestV2(
    `bu/${businessUnitId}/teams/sub_teams?team_id=${parentTeamId}&cycle_id=${cycleId}`
  );

const saveBusinessUnitGoals = (businessUnitId, payload) =>
  postRequestV2(`bu/${businessUnitId}/goals/batch_update`, payload);

const businessUnits = {
  addMemberToBusinessUnit,
  removeMemberFromBusinessUnit,
  getBusinessUnit,
  editBusinessUnit,
  createBusinessUnit,
  getBusinessUnits,
  getBusinessUnitsMemberships,
  getBusinessUnitTeams,
  getBusinessUnitSubTeams,
  getBusinessUnitRootTeams,
  saveBusinessUnitGoals,
};

export default businessUnits;
