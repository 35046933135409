import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgFailed = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      d="M7.997 15c.957 0 1.858-.183 2.702-.549a7.156 7.156 0 0 0 3.752-3.747A6.733 6.733 0 0 0 15 8c0-.958-.183-1.859-.549-2.704a7.075 7.075 0 0 0-1.517-2.23A7.151 7.151 0 0 0 10.7 1.55 6.74 6.74 0 0 0 7.99 1c-.956 0-1.857.183-2.702.549A7.174 7.174 0 0 0 1.55 5.296 6.732 6.732 0 0 0 1 8c0 .958.183 1.859.549 2.704.365.845.869 1.59 1.51 2.236a7.057 7.057 0 0 0 2.235 1.511A6.723 6.723 0 0 0 7.997 15Zm-2.222-4.134a.63.63 0 0 1-.454-.183.631.631 0 0 1-.183-.454c0-.176.064-.325.19-.447l1.768-1.775-1.768-1.762a.627.627 0 0 1-.19-.447c0-.186.061-.337.183-.454a.63.63 0 0 1 .454-.183c.176 0 .327.058.454.176l1.774 1.769 1.782-1.77a.619.619 0 0 1 .454-.189c.176 0 .325.063.446.19.127.122.19.27.19.447a.634.634 0 0 1-.19.454L8.912 8.007l1.768 1.768c.126.118.19.27.19.454a.62.62 0 0 1-.19.454.616.616 0 0 1-.454.183.624.624 0 0 1-.46-.183L8.002 8.915 6.25 10.683a.67.67 0 0 1-.474.183Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgFailed);
export default ForwardRef;
