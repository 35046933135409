import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgNotify = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      d="M2.04 13h10.92c.326 0 .58-.076.764-.227a.746.746 0 0 0 .276-.607c0-.23-.067-.452-.2-.668a3.667 3.667 0 0 0-.503-.634l-.592-.62a1.629 1.629 0 0 1-.358-.613 4.802 4.802 0 0 1-.186-.806c-.042-.285-.072-.56-.09-.827-.014-.881-.11-1.928-.29-2.616-.174-.69-.447-1.268-.82-1.737-.371-.468-.863-.812-1.473-1.033a2.406 2.406 0 0 0-.71-1.144C8.433 1.156 8.006 1 7.497 1c-.506 0-.93.156-1.275.468a2.406 2.406 0 0 0-.71 1.144c-.61.22-1.104.565-1.48 1.033-.373.469-.646 1.048-.82 1.737-.175.688-.27 1.735-.283 2.616-.018.267-.048.542-.09.827a4.4 4.4 0 0 1-.192.806c-.083.253-.2.457-.352.613-.197.207-.397.414-.6.62a3.846 3.846 0 0 0-.495.634c-.133.216-.2.439-.2.668 0 .253.092.455.276.607.183.151.438.227.764.227Zm5.46 2.625c.39 0 .74-.085 1.047-.255.308-.17.554-.393.737-.668 0 0 .35-.38.383-.702H5.444c.027.322.083.426.272.702a2.134 2.134 0 0 0 1.784.923Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgNotify);
export default ForwardRef;
