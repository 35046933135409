import FileSaver from 'file-saver';
import { camelizeKeys } from 'humps';
import contentDisposition from 'content-disposition';

import auth from 'utils/auth';

import urlForEndpoint, { urlForEndpointV2 } from './url';

/* istanbul ignore next */
function checkStatus(response) {
  if (response.ok) {
    return Promise.resolve(response);
  }

  if (response.status === 401) {
    auth.removeToken();
    window.location.replace('/');
  }

  return response.json().then(body => {
    const error = new Error(response.statusText);
    const errorBody = camelizeKeys(body);
    return Promise.reject(Object.assign(error, { body: errorBody, response }));
  });
}

function parseJSON(response) {
  if (response.status === 204) {
    return {};
  }

  return response.json();
}

/* istanbul ignore next */
function parseBlob(response) {
  return response.blob().then(data => {
    const parsedContentDisposition = contentDisposition.parse(
      response.headers.get('content-disposition')
    );

    return {
      data,
      filename: parsedContentDisposition.parameters.filename,
    };
  });
}

/* istanbul ignore next */
function downloadFile(response) {
  const blob = response.data;
  const name = response.filename;
  const options = {
    type: blob.type,
  };

  FileSaver.saveAs(blob, name, options);
}

/* istanbul ignore next */
function camelizeResponse(response) {
  return camelizeKeys(response);
}

export default function request(endpoint, options) {
  const url = urlForEndpoint(endpoint);

  return fetch(url, options)
    .then(checkStatus)
    .then(parseJSON)
    .then(camelizeResponse);
}

/* istanbul ignore next */
export function requestV2(endpoint, options) {
  const url = urlForEndpointV2(endpoint);
  return fetch(url, options)
    .then(checkStatus)
    .then(parseJSON)
    .then(camelizeResponse);
}

/* istanbul ignore next */
export function requestFile(endpoint, options) {
  const url = urlForEndpoint(endpoint);

  return fetch(url, options)
    .then(checkStatus)
    .then(parseBlob)
    .then(downloadFile);
}

/* istanbul ignore next */
// stoplightMockRequest is for calling api from stoplight mock server without adding localhost to the url
export function stoplightMockRequest(endpoint, options) {
  return fetch(endpoint, options)
    .then(checkStatus)
    .then(parseJSON)
    .then(camelizeResponse);
}
