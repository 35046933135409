import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgChevronDown = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.193 6.21a.62.62 0 0 1 .931 0L8 8.263 9.876 6.21a.62.62 0 0 1 .931 0 .768.768 0 0 1 0 1.018L8.466 9.79a.62.62 0 0 1-.932 0L5.193 7.23a.768.768 0 0 1 0-1.018Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgChevronDown);
export default ForwardRef;
