/* istanbul ignore file */
import React from 'react';
import { selectLocale } from 'containers/App/selectors';
import { useSelector } from 'react-redux';

const withData = WrappedComponent => {
  const NonAuthenticatedAppLayoutWithData = ({ ...rest }) => {
    const locale = useSelector(selectLocale);

    return <WrappedComponent {...rest} locale={locale} />;
  };

  return NonAuthenticatedAppLayoutWithData;
};

export { withData };
