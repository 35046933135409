const layers = {};

layers.subNavBar = 10;
layers.navBarHoverMenu = 20;
layers.toolTip = 30;
layers.dropDown = 40;
layers.sideBarKeyResultDetails = 50;
layers.toastNotification = 60;
layers.overlayNavBar = 100;

export default layers;
