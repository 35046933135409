import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgLogout = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.171 16A2.834 2.834 0 0 0 16 13.161V2.84A2.834 2.834 0 0 0 13.171 0H7v1.548h6.171c.71 0 1.286.578 1.286 1.29v10.323c0 .713-.575 1.29-1.286 1.29H7V16h6.171ZM.745 8.715h6.536l-2.097 2.2a.727.727 0 0 0 1.052 1.003L10 7.97 6.236 4.022a.727.727 0 0 0-1.052 1.003l2.097 2.2H.745a.745.745 0 0 0 0 1.49Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgLogout);
export default ForwardRef;
