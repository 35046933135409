import React, { Ref, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import {
  getColor,
  getBaseUnit,
  getFontSize,
  getFontWeight,
} from '../styles/themeGetters';

import { ButtonLoadingIndicator } from './ButtonLoadingIndicator';
import { IconBox, IconComponent } from '../Icons/helpers';
import { ColorKeys } from '../styles/themes';

type ColorOptions = {
  primary: string;
  danger: string;
};

type Color = keyof ColorOptions;

type StyledButtonProps = {
  color: Color;
  variant: 'contained' | 'outlined' | 'text';
  size: 's' | 'l';
  $loading?: boolean;
  iconOnly?: boolean;
};

const getColorOptions = (options: ColorOptions) => ({ color }) =>
  options[color];

const variantContained = css<StyledButtonProps>`
  color: ${getColor('white')};
  background-color: ${getColorOptions({
    primary: getColor('navy'),
    danger: getColor('red'),
  })};

  &:hover:enabled {
    background-color: ${getColorOptions({
      primary: getColor('navy-dark'),
      danger: getColor('red-dark'),
    })};
  }
  &:focus {
    background-color: ${getColorOptions({
      primary: getColor('navy'),
      danger: getColor('red'),
    })};
    border-color: ${getColorOptions({
      primary: getColor('blue-light'),
      danger: getColor('red-light'),
    })};
  }
  &:active:enabled {
    background-color: ${getColorOptions({
      primary: getColor('navy-dark'),
      danger: getColor('red-dark'),
    })};
  }
  &:disabled {
    color: ${getColorOptions({
      primary: getColor('grey-dark'),
      danger: getColor('red-light'),
    })};
  }
`;

const variantOutlined = css<StyledButtonProps>`
  color: ${getColorOptions({
    primary: getColor('navy'),
    danger: getColor('red'),
  })};
  border: 1px solid
    ${getColorOptions({
      primary: getColor('grey-light-100'),
      danger: getColor('red'),
    })};
  background-color: ${getColor('white')};

  &:hover:enabled {
    background-color: ${getColorOptions({
      primary: getColor('grey-light-100'),
      danger: getColor('red-light'),
    })};
  }
  &:focus {
    border: 1px solid
      ${getColorOptions({
        primary: getColor('blue-light'),
        danger: getColor('red-light'),
      })};
  }
  &:active:enabled {
    border: 1px solid
      ${getColorOptions({
        primary: getColor('grey-light-100'),
        danger: getColor('red'),
      })};
    background-color: ${getColorOptions({
      primary: getColor('grey-light-50'),
      danger: getColor('red-light'),
    })};
  }
  &:disabled {
    color: ${getColorOptions({
      primary: getColor('grey-dark'),
      danger: getColor('red-light'),
    })};
    border: 1px solid
      ${getColorOptions({
        primary: getColor('grey-light-100'),
        danger: getColor('red-light'),
      })};

    border-color: ${({ $loading }) =>
      $loading &&
      getColorOptions({
        primary: getColor('grey-light-100'),
        danger: getColor('red'),
      })};
  }
`;

const variantText = css<StyledButtonProps>`
  color: ${getColorOptions({
    primary: getColor('navy'),
    danger: getColor('red'),
  })};

  &:hover:enabled {
    background-color: ${getColorOptions({
      primary: getColor('grey-light-100'),
      danger: getColor('red-light'),
    })};
  }
  &:focus {
    border: 1px solid
      ${getColorOptions({
        primary: getColor('blue-light'),
        danger: getColor('red-light'),
      })};
  }
  &:active:enabled {
    border: 1px solid
      ${getColorOptions({
        primary: getColor('grey-light-100'),
        danger: getColor('red-light'),
      })};
    background-color: ${getColorOptions({
      primary: getColor('grey-light-50'),
      danger: getColor('red-light'),
    })};
  }
  &:disabled {
    color: ${getColorOptions({
      primary: getColor('grey-dark'),
      danger: getColor('red-light'),
    })};

    /* SVG fill color when button is disabled */
    svg {
      fill: ${getColorOptions({
        primary: getColor('grey-dark'),
        danger: getColor('red-light'),
      })};
    }
  }

  /* SVG fill color for outlined variant */
  svg {
    fill: ${getColorOptions({
      primary: getColor('navy'),
      danger: getColor('red'),
    })};
  }
`;

const variants = {
  contained: variantContained,
  outlined: variantOutlined,
  text: variantText,
};

const sizeLargeStyle = css`
  padding-top: ${getBaseUnit(2)};
  padding-bottom: ${getBaseUnit(2)};
`;

const sizeSmallStyle = css`
  padding-top: ${getBaseUnit(1)};
  padding-bottom: ${getBaseUnit(1)};
`;

const sizeStyles = {
  l: sizeLargeStyle,
  s: sizeSmallStyle,
};

const StyledButton = styled.button<StyledButtonProps>`
  all: unset;

  /* Generic styles for the button */

  font-size: ${getFontSize(16)};
  font-weight: ${getFontWeight('semibold')};

  padding-left: ${({ iconOnly }) =>
    iconOnly ? getBaseUnit(2) : getBaseUnit(4)};
  padding-right: ${({ iconOnly }) =>
    iconOnly ? getBaseUnit(2) : getBaseUnit(4)};

  border-radius: ${getBaseUnit(1)};

  &:hover:enabled {
    cursor: pointer;
  }

  border: 1px solid transparent;

  ${props => variants[props.variant]};
  ${props => sizeStyles[props.size]};

  position: relative;

  /* SVG Icons size */
  svg {
    font-size: ${getFontSize(24)};
  }
`;

const ContentWrapper = styled.div<{ $loading: boolean }>`
  visibility: ${({ $loading }) => $loading && 'hidden'};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconWrapper = styled(IconBox)<{ applySpacing: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ applySpacing }) => applySpacing && getBaseUnit(2)};
`;

type ButtonProps = {
  color?: Color;
  variant?: 'contained' | 'outlined' | 'text';
  size?: 's' | 'l';
  loading?: boolean;
  startIcon?: IconComponent;
  disabled?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const getIconColor: (props: ButtonProps) => ColorKeys = ({
  variant,
  disabled,
  color,
}) => {
  if (disabled) {
    return getColorOptions({
      primary: 'grey-dark',
      danger: 'red-light',
    })({ color });
  }
  switch (variant) {
    case 'outlined':
    case 'text':
      return getColorOptions({
        primary: 'navy',
        danger: 'red',
      })({ color });
    case 'contained':
      return 'white';
  }
};

const InnerButton: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  ButtonProps
> = (
  {
    children,
    color = 'primary',
    variant = 'contained',
    size = 'l',
    loading,
    startIcon: StartIcon,
    disabled,
    ...rest
  }: ButtonProps,
  ref: Ref<HTMLButtonElement>
) => {
  return (
    <StyledButton
      {...rest}
      color={color}
      variant={variant}
      size={size}
      disabled={disabled || loading}
      $loading={loading}
      iconOnly={!children}
      ref={ref}
    >
      <ContentWrapper $loading={loading}>
        {StartIcon && (
          <IconWrapper applySpacing={!!children}>
            <StartIcon color={getIconColor({ color, variant, disabled })} />
          </IconWrapper>
        )}
        {children}
      </ContentWrapper>
      {loading && (
        <ButtonLoadingIndicator color={color} variant={variant} size={size} />
      )}
    </StyledButton>
  );
};

const Button = forwardRef(InnerButton);

export { Button };
