import {
  getRequestV2,
  postRequest,
  patchRequest,
  patchRequestV2,
  deleteRequest,
} from '../../methods';

const registerUser = payload => postRequest('register', payload);
const loginUser = payload => postRequest('login', payload);
const logoutUser = () => deleteRequest('logout');
const tokenLogin = token => postRequest('login', { user: { token } });

const forgotPassword = payload => postRequest('forgot_password', payload);
const resetPassword = payload => postRequest('reset_password', payload);
const resendConfirmation = payload =>
  postRequest('resend_confirmation', payload);

const getProfile = () => getRequestV2('profile');
const updateProfile = payload => patchRequestV2('profile', payload);

const updateNotificationSettings = payload =>
  patchRequest('profile/notification_settings', payload);

const profile = {
  registerUser,
  loginUser,
  logoutUser,
  forgotPassword,
  resetPassword,
  resendConfirmation,
  tokenLogin,

  getProfile,
  updateProfile,

  updateNotificationSettings,
};

export default profile;
