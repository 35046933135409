import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgBlocker = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      d="M2 10.746c0 .829.128 1.567.384 2.216a4.634 4.634 0 0 0 1.076 1.646 4.593 4.593 0 0 0 1.634 1.033 5.8 5.8 0 0 0 2.07.359c1.195 0 2.181-.25 2.959-.748.778-.5 1.413-1.248 1.905-2.245.145-.28.283-.572.414-.876.13-.304.256-.621.376-.95.12-.335.239-.681.354-1.04a163.866 163.866 0 0 0 .557-1.774c.075-.235.138-.452.188-.651.055-.2.083-.36.083-.48a.63.63 0 0 0-.203-.486.72.72 0 0 0-.52-.187.836.836 0 0 0-.602.24c-.16.16-.329.416-.504.77L11.124 9.76a.659.659 0 0 1-.165.254.284.284 0 0 1-.181.075.266.266 0 0 1-.196-.075c-.045-.055-.068-.147-.068-.277v-7.85a.75.75 0 0 0-.24-.561.76.76 0 0 0-.565-.24c-.22 0-.412.08-.572.24a.762.762 0 0 0-.234.56V7.5a3.494 3.494 0 0 0-.67-.18V.809a.759.759 0 0 0-.24-.57A.76.76 0 0 0 7.427 0c-.226 0-.42.08-.58.24a.771.771 0 0 0-.233.568v6.429a9.514 9.514 0 0 0-.708.09V1.443a.75.75 0 0 0-.24-.56.76.76 0 0 0-.565-.24c-.221 0-.412.08-.573.24a.762.762 0 0 0-.233.56v6.391c-.125.06-.246.123-.361.187-.11.065-.216.13-.316.195V3.772a.75.75 0 0 0-.241-.562.76.76 0 0 0-.565-.239c-.226 0-.419.08-.58.24A.762.762 0 0 0 2 3.77v6.975Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgBlocker);
export default ForwardRef;
