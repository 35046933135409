import React from 'react';
import { Box } from '../Box';
import { Text } from '../Text';
import { getBaseUnit } from '../styles/themeGetters';

type EmptyStateProps = {
  title?: string;
  description: string | JSX.Element;
  visual?: React.ReactNode;
  buttons?: React.ReactNode;
  variant?: 'content' | 'page';
  minHeight?: string;
};

const EmptyState = ({
  visual,
  title,
  description,
  variant = 'content',
  buttons,
  minHeight,
}: EmptyStateProps) => {
  const descriptionContent =
    typeof description === 'string' ? (
      /* This magic number (maxWidth) comes from the design to break description to multiline in certain width */
      <Text maxWidth="450px" textAlign="center">
        {description}
      </Text>
    ) : (
      description
    );

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      borderColor={variant === 'content' ? 'grey-light-50' : null}
      borderPosition={variant === 'content' ? ['all'] : null}
      pb={8}
      pt={8}
      backgroundColor={variant === 'content' ? 'white' : undefined}
      borderRadius={variant === 'content' && 2}
      minHeight={minHeight}
    >
      {visual && <Box mb={6}>{visual}</Box>}
      {title && (
        <Text
          mb={4}
          fontWeight="semibold"
          fontSize={24}
          maxWidth="552px"
          textAlign="center"
        >
          {title}
        </Text>
      )}
      {descriptionContent}
      {buttons && (
        <Box mt={6} display="flex" flexGap={getBaseUnit(4)}>
          {buttons}
        </Box>
      )}
    </Box>
  );
};

export { EmptyState };
