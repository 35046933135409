// This function will compare the git revision the app was built on with the
// git revision that the server was built on.
// If they differ, we can assume the user's app to be outdated.
/* istanbul ignore next */
const checkIfServerReleaseDiffersFromLocal = async () => {
  /* global GIT_REV */
  const response = await fetch('/release.txt');
  const serverRelease = await response.text();
  return serverRelease !== GIT_REV;
};

// This function is used to wrap dynamic imports in routes.js.
// (https://webpack.js.org/guides/code-splitting/#dynamic-imports)
//
// We use this to adress an issue where dynamic loading would fail if a module
// that is to be imported has been changed server side by a new app release
// after the user opened the app. Given that It's file name contains a hash for
// cache busting, the file does not exist anymmore under the name it had when
// the user first initialiized their session.
// See https://workpathhq.atlassian.net/wiki/spaces/CORE/pages/6603178055 for
// details and inline comments for an explanation of the mechanism.

export const handleAppUpdate = (
  importFunction,
  checkServerReleaseMock // optional, used for testing
) => {
  return new Promise((resolve, reject) => {
    // try the dynamic import
    importFunction()
      // on success, pass handling back to routes.js
      .then(resolve)
      // on error
      .catch(error => {
        // check if the user's app is outdated
        (checkServerReleaseMock || checkIfServerReleaseDiffersFromLocal)()
          .then(newVersionAvailable => {
            // this block leaves the promise pending, so we can't unittest it.
            /* istanbul ignore if */
            if (newVersionAvailable && error.name === 'ChunkLoadError') {
              // if it is, and the error was a ChunkLoadError, trigger the page
              // to reload.
              // note that we do not reject here, we want this Promise to be
              // pending until the reload finishes.

              // we actually want this log to get the data in sentry
              /* eslint-disable no-console */
              console.log(`new app version available - reloading`);
              window.location.reload();
            } else {
              // if it isn't, something else is going on (maybe a regular
              // network error?, so handle it as before)
              reject(error);
            }
          })
          // if the version check fails (e.g. a network error), handle it as an
          // error of the dynamic import
          .catch(reject);
      });
  });
};
