import React, { useLayoutEffect, Ref, forwardRef } from 'react';
import styled from 'styled-components';
import {
  useFloating,
  useInteractions,
  useDismiss,
  offset,
  shift,
  Placement,
  size,
  flip,
  autoUpdate,
} from '@floating-ui/react-dom-interactions';

import { Box } from '../Box';
import { IconBox, Icons } from '../Icons';
import { Text } from '../Text';
import { getBaseUnit, getColor } from '../styles/themeGetters';
import { ColorKeys } from '../styles/themes';
import { IconComponent } from '../Icons/helpers';

type MenuProps = {
  anchorEl: React.RefObject<HTMLElement>;
  open: boolean;
  onClose?: (open: boolean) => void;
  children: React.ReactNode;
  menuOffset?: number;
  placement?: Placement;
  fullWidth?: boolean;
  sizeEvaluatorValue?: number;
  maxHeight?: string;
};

type MenuBodyProps = {
  position?: 'absolute' | 'fixed';
  top?: number;
  left?: number;
};

const MenuBodyContent = styled(Box).attrs({
  borderPosition: ['all'],
  borderColor: 'grey-light-100',
  pt: 2,
  pb: 2,
  round: true,
})`
  background-color: ${getColor('white')};
  box-shadow: 0 0 0 rgba(16, 16, 16, 0.04), 0 8px 32px rgba(32, 32, 32, 0.08); // we may need to add it to themes.ts or use a theme getter in the future
  margin-bottom: ${({ mb }) => getBaseUnit(mb)};
  z-index: 2;
`;

const MenuBody = styled.div.attrs({
  'aria-label': 'menu',
})<MenuBodyProps>`
  position: ${({ position }) => position || 'absolute'};
  top: ${({ top }) => (top ? `${top}px` : 0)};
  left: ${({ left }) => (left ? `${left}px` : 0)};
  z-index: 2;
`;

type StyledMenuItemProps = {
  $disabled?: boolean;
};

const Content = styled.div`
  display: flex;
  gap: getBaseUnit(2);
`;

const StyledMenuItem = styled(Box)<StyledMenuItemProps>`
  min-width: 184px;
  transition: background-color 0.2s ease-in-out;
  display: flex;
  justify-content: space-between;

  &:hover {
    background-color: ${getColor('grey-light-50')};
    cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  }
`;

type MenuItemProps = {
  children: React.ReactNode;
  color?: 'primary' | 'danger' | 'disabled';
  onSelect?: (event: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  isSelected?: boolean;
  prefixIcon?: IconComponent;
};

const menuColorMap = {
  primary: 'black',
  danger: 'red',
  disabled: 'grey',
};

const InnerMenuItem: React.ForwardRefRenderFunction<
  HTMLDivElement,
  MenuItemProps
> = (
  {
    children,
    color = 'primary',
    onSelect,
    disabled,
    isSelected,
    prefixIcon: PrefixIcon,
    ...props
  }: MenuItemProps,
  ref: Ref<HTMLDivElement>
) => {
  const handleClick = e => {
    if (!disabled) {
      onSelect(e);
    }
  };

  return (
    <StyledMenuItem
      {...props}
      pl={4}
      pr={4}
      pt={1}
      pb={1}
      onClick={handleClick}
      $disabled={disabled}
      ref={ref}
    >
      <Content>
        {PrefixIcon && (
          <IconBox mr={1}>
            <PrefixIcon size="24px" />
          </IconBox>
        )}
        <Text color={menuColorMap[color] as ColorKeys}>{children}</Text>
      </Content>
      {isSelected && (
        <Box height={getBaseUnit(6)} width={getBaseUnit(6)}>
          <Icons.Check />
        </Box>
      )}
    </StyledMenuItem>
  );
};

const Menu = ({
  anchorEl,
  open,
  children,
  onClose,
  menuOffset = 10,
  placement = 'bottom',
  fullWidth,
  sizeEvaluatorValue,
  maxHeight,
}: MenuProps) => {
  const middleware = [offset(menuOffset), flip(), shift({ padding: 5 })];

  if (fullWidth)
    middleware.push(
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
          });
        },
      })
    );

  if (maxHeight)
    middleware.push(
      size({
        apply({ elements }) {
          /* element.floating will return MenuBody,
           * as we only want to apply the scroll to MenuBodyContent we look for the firstChild of MenuBody which will always be the MenuBodyContent
           */
          const menuBodyContent = elements.floating.firstChild as HTMLElement;
          Object.assign(menuBodyContent.style, {
            maxHeight,
            overflow: 'auto',
          });
        },
      })
    );

  const { x, y, reference, floating, strategy, context } = useFloating({
    open,
    onOpenChange: onClose,
    middleware,
    placement,
    whileElementsMounted: autoUpdate,
  });

  const { getFloatingProps } = useInteractions([
    useDismiss(context, {
      escapeKey: true,
    }),
  ]);

  useLayoutEffect(
    () => {
      reference(anchorEl.current);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- [RFC 032]
    [anchorEl, sizeEvaluatorValue]
  );

  if (!open) {
    return null;
  }

  return (
    <MenuBody
      position={strategy}
      top={y}
      left={x}
      {...getFloatingProps({ ref: floating })}
    >
      <MenuBodyContent>{children}</MenuBodyContent>
    </MenuBody>
  );
};

const MenuItem = forwardRef(InnerMenuItem);

Menu.Item = MenuItem;

export { Menu, MenuBody, MenuBodyContent };
