import { defineMessages } from 'react-intl';

export default defineMessages({
  goal: {
    id: 'baukasten.DataGrid.renderers.TitleRenderer.goal',
    defaultMessage: 'Objective',
  },
  keyResult: {
    id: 'baukasten.DataGrid.renderers.TitleRenderer.keyResult',
    defaultMessage: 'Key Result',
  },
  initiative: {
    id: 'baukasten.DataGrid.renderers.TitleRenderer.initiative',
    defaultMessage: '{initiative}',
  },
  alignedGoal: {
    id: 'baukasten.DataGrid.renderers.TitleRenderer.alignedGoal',
    defaultMessage: 'Aligned Objective',
  },
});
