// Docs: https://github.com/WorkpathHQ/workpath_web/wiki/Container-Reducer-Docs
import {
  GET_KEY_RESULT_PROGRESS_OVER_TIME_REQUEST,
  GET_KEY_RESULT_PROGRESS_OVER_TIME_SUCCESS,
  GET_KEY_RESULT_PROGRESS_OVER_TIME_ERROR,
} from './constants';

export const initialState = {
  getKeyResultProgressOverTimePending: true,
  keyResultProgressOverTime: {},
  getKeyResultProgressOverTimeError: undefined,
  graphThresholds: {},
  keyResultId: undefined,
  goal: { id: undefined, permissions: { updateProgress: false } },
  keyResult: { title: '' },
  confidenceLevel: [],
  progress: [],
  feedEntries: [],
};

const keyResultProgressOverTimeModalReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GET_KEY_RESULT_PROGRESS_OVER_TIME_REQUEST:
      return {
        ...state,
        getKeyResultProgressOverTimePending: true,
        keyResultId: action.payload.keyResultId,
      };
    case GET_KEY_RESULT_PROGRESS_OVER_TIME_SUCCESS:
      return {
        ...state,
        getKeyResultProgressOverTimePending: false,
        progress: action.payload.progress,
        confidenceLevel: action.payload.confidenceLevel,
        keyResult: action.payload.keyResult,
        goal: action.payload.goal,
        feedEntries: action.payload.feedEntries,
      };
    case GET_KEY_RESULT_PROGRESS_OVER_TIME_ERROR:
      return {
        ...state,
        getKeyResultProgressOverTimePending: false,
        getKeyResultProgressOverTimeError: action.payload.error,
      };

    default:
      return state;
  }
};

export default keyResultProgressOverTimeModalReducer;
