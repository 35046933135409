import queryString from 'query-string';
import {
  getRequest,
  getRequestV2,
  postRequest,
  patchRequest,
  deleteRequest,
  postRequestV2,
  patchRequestV2,
  deleteRequestV2,
} from '../../methods';

const getTeams = () => getRequest('teams');
const getTeam = teamId => getRequest(`teams/${teamId}`);
const createTeam = payload => postRequest('teams', payload);
const editTeam = (teamId, payload) => patchRequest(`teams/${teamId}`, payload);
const deleteTeam = teamId => deleteRequest(`teams/${teamId}`);
const createTeamCoach = (teamId, payload) =>
  postRequest(`teams/${teamId}/coaches`, payload);
const deleteTeamCoach = (teamId, userId) =>
  deleteRequest(`teams/${teamId}/coaches/${userId}`);
const getTimeline = (teamId, startDate, endDate) =>
  getRequest(
    `/teams/${teamId}/timeline?start_date=${startDate}&end_date=${endDate}`
  );

const requestJoinTeam = teamId => postRequestV2(`teams/${teamId}/join`, {});

const getUserMeTimeline = ({ cycleId }) =>
  getRequestV2(`users/me/initiative_timelines?cycle_id=${cycleId}`);

const getOrganizationTeams = ({ cycleId }) =>
  getRequestV2(`teams/root?cycle_id=${cycleId}`);

const getSubTeamsV2 = ({ teamId, cycleId }) =>
  getRequestV2(`teams/${teamId}/sub_teams?cycle_id=${cycleId}`);

const createTeamMembership = (teamId, payload) =>
  postRequest(`teams/${teamId}/memberships`, payload);
const deleteTeamMembership = (teamId, userId) =>
  deleteRequest(`teams/${teamId}/memberships/${userId}`);

const getSubTeams = ({ teamId, cycleStartDate, cycleEndDate }) =>
  getRequest(
    `teams/${teamId}/sub_teams?start_date=${cycleStartDate}&end_date=${cycleEndDate}`
  );

const getGoalSummary = ({
  teamId,
  cycleId,
  showChart,
  includeSubTeamsInChart,
}) =>
  getRequest(
    `teams/${teamId}/goal_summary?cycle_id=${cycleId}&show_chart=${showChart}&include_sub_teams=${includeSubTeamsInChart}`
  );

const createContributionRequest = (teamId, payload) =>
  postRequestV2(`teams/${teamId}/contribution_requests`, payload);

const getContributionRequest = contributionRequestId =>
  getRequestV2(`teams/contribution_requests/${contributionRequestId}`);

const getContributionRequests = ({ teamId, cycleId, payload }) =>
  getRequestV2(
    `teams/${teamId}/contribution_requests?${queryString.stringify(
      {
        cycle_id: cycleId,
        [payload.isRequesting ? 'outgoing' : 'incoming']: true,
        statuses: payload.statuses,
      },
      { arrayFormat: 'bracket' }
    )}`
  );

const acceptContributionRequest = (contributionRequestId, payload) =>
  patchRequestV2(
    `teams/contribution_requests/${contributionRequestId}/accept`,
    payload
  );

const declineContributionRequest = (contributionRequestId, payload) =>
  patchRequestV2(
    `teams/contribution_requests/${contributionRequestId}/decline`,
    payload
  );

const deleteContributionRequest = contributionRequestId =>
  deleteRequestV2(`teams/contribution_requests/${contributionRequestId}`);

const getCapacity = (teamId, cycleId) =>
  getRequestV2(`teams/${teamId}/estimation?cycle_id=${cycleId}`);

const createCapacity = (teamId, payload) =>
  postRequestV2(`teams/${teamId}/estimation`, payload);

const createCapacityAllocationHunches = (estimationId, teamId, payload) =>
  postRequestV2(`teams/${teamId}/estimations/${estimationId}/hunches`, payload);

const updateCapacityAllocations = (teamId, estimationId, payload) =>
  postRequestV2(
    `teams/${teamId}/estimations/${estimationId}/allocations`,
    payload
  );

const saveTeamDraftGoals = (teamId, payload) =>
  postRequestV2(`teams/${teamId}/draft_goals`, payload);

const getCapacityAllocationHunches = (teamId, estimationId) =>
  getRequestV2(`teams/${teamId}/estimations/${estimationId}/hunches`);

const teams = {
  getTeams,
  getTeam,
  createTeam,
  editTeam,
  deleteTeam,
  createTeamCoach,
  deleteTeamCoach,
  getTimeline,
  getUserMeTimeline,
  requestJoinTeam,

  createTeamMembership,
  deleteTeamMembership,

  getSubTeams,
  getSubTeamsV2,
  getOrganizationTeams,

  getGoalSummary,

  createContributionRequest,
  getContributionRequest,
  getContributionRequests,
  acceptContributionRequest,
  declineContributionRequest,
  deleteContributionRequest,

  getCapacity,
  createCapacity,
  createCapacityAllocationHunches,
  updateCapacityAllocations,
  getCapacityAllocationHunches,

  saveTeamDraftGoals,
};

export default teams;
