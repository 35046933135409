import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgLightning = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      d="M3 8.886a.48.48 0 0 0 .142.351c.095.09.218.136.369.136h4.01l-2.115 5.803c-.071.186-.085.346-.043.48a.485.485 0 0 0 .61.322.72.72 0 0 0 .391-.272l6.452-8.138c.122-.158.184-.31.184-.458a.456.456 0 0 0-.142-.344.497.497 0 0 0-.369-.144h-4.01L10.594.82c.071-.182.083-.34.036-.473a.46.46 0 0 0-.235-.286.502.502 0 0 0-.376-.043c-.137.033-.265.126-.383.279l-6.451 8.13c-.123.163-.185.316-.185.46Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgLightning);
export default ForwardRef;
