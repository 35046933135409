export const isIE11 = () =>
  !!window.MSInputMethodContext && !!document.documentMode;

export const isBrowserSafari = () => {
  // Detect Safari
  const isChrome = navigator.userAgent.indexOf('Chrome') > -1;
  let isSafari = navigator.userAgent.indexOf('Safari') > -1;

  // Discard Safari since it also matches Chrome
  if (isChrome && isSafari) {
    isSafari = false;
  }
  return isSafari;
};

export const getSubdomain = () => {
  // Examples:
  //  Production: company.workpath.com
  //  Staging: hooli.wrkpth.dev
  //  QA: pl-1234-branchname-example.hooli.workpath.dev
  //  Localhost: hooli.localhost
  const domain = window.location.hostname;

  // Examples
  //  Production: ["company","workpath","com"]
  //  Staging: ["hooli","wrkpth","dev"]
  //  QA: ["pl-1234-branchname-example","hooli","workpath","dev"]
  //  Localhost: ["hooli","localhost"]
  const parts = domain.split('.');

  if (parts.length >= 3 && parts[3] === 'dev') {
    // QA environment
    return parts[1];
  }

  // Production, Staging & Localhost environment
  return parts[0];
};

const browser = {
  isIE11,
  isBrowserSafari,
};

export default browser;
