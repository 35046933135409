import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgLinkOff = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.47.53a.75.75 0 0 1 1.06 0l2.521 2.521L5.5 4.5l6.429 6.429A3.251 3.251 0 0 0 11.25 4.5H9V3h2.25a4.75 4.75 0 0 1 1.868 9.118l1.494 1.494a.75.75 0 0 1-1.06 1.06l-2.175-2.174a4.87 4.87 0 0 1-.127.002H9V11h.879l-2.5-2.5H4.75a.75.75 0 0 1 0-1.5h1.129L3.59 4.713A3.251 3.251 0 0 0 4.75 11H7v1.5H4.75a4.75 4.75 0 0 1-2.286-8.915L.47 1.591a.75.75 0 0 1 0-1.06Zm11.043 7.923A.75.75 0 0 0 11.25 7h-1.19l1.453 1.453Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgLinkOff);
export default ForwardRef;
