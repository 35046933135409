import React from 'react';
import { InputField } from '../components/InputField';
import { InputFieldClearButton } from '../components/InputFieldClearButton';
import { InputControl } from '../components/InputControl';
import { InputFieldBody } from '../components/InputFieldBody';
import {
  CommonProps,
  ClearButtonFeature,
  CharacterCounterFeature,
} from '../types';
import { Box } from '../../Box';

type TextareaProps = CommonProps &
  ClearButtonFeature &
  CharacterCounterFeature & { height?: string };

const Textarea = (props: TextareaProps) => {
  const {
    name,
    placeholder,
    error,
    height,
    disabled,
    helpText,
    label,
    hasCharacterCounter,
    hasClearButton,
    maxLength,
    validations = {},
    condensed,
    formHooks: { register, watch, resetField },
  } = props;
  const value = watch && watch(name);
  const showClearButton = Boolean(hasClearButton && value);
  const hasLengthError = Number(value?.length) > maxLength;
  if (!validations?.maxLength) validations.maxLength = maxLength;

  return (
    <InputControl
      error={error}
      helpText={helpText}
      value={value}
      hasCharacterCounter={hasCharacterCounter}
      maxLength={maxLength}
      label={label}
      htmlFor={name}
      condensed={condensed}
    >
      <InputField
        disabled={disabled}
        hasError={Boolean(error) || hasLengthError}
      >
        <InputFieldBody
          height={height}
          disabled={disabled}
          placeholder={placeholder}
          register={register}
          name={name}
          validations={validations}
          as="textarea"
        />
        {showClearButton && (
          <Box pt={1} display="flex">
            <InputFieldClearButton
              onClick={() => resetField(name)}
              disabled={disabled}
              tooltipContent="Clear"
            />
          </Box>
        )}
      </InputField>
    </InputControl>
  );
};

export { Textarea };
