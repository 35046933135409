import { createContext } from 'react';

type TabContextInterface = {
  selectedTab: number;
  setSelectedTab: (tab: number) => void;
  focusedTab: number;
  setFocusedTab: (tab: number) => void;
};

const TabContext = createContext<TabContextInterface>(undefined);

export { TabContext };
