import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgSolidChevronDown = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m4.127 6.037 3.422 4.723c.199.276.563.32.812.1a.615.615 0 0 0 .09-.1l3.422-4.723a.684.684 0 0 0-.09-.897.545.545 0 0 0-.361-.14H4.578C4.258 5 4 5.286 4 5.638c0 .145.045.286.127.4Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgSolidChevronDown);
export default ForwardRef;
