import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgArrowDown = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      d="M13.471 8.529a.748.748 0 0 0-1.057-1.058L8.75 11.127V2.75a.75.75 0 0 0-1.5 0v8.377L3.597 7.468a.753.753 0 1 0-1.065 1.064L8 14l5.471-5.471Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgArrowDown);
export default ForwardRef;
