import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { isBefore } from 'date-fns';

import { Box } from 'baukasten';

import Alert from 'components/Alert';
import AlertLink from 'components/AlertLink';

import messages from '../messages';

class AccountNotifications extends React.Component {
  getIsSubscriptionExpired = () =>
    this.props.account.subscriptionEndDate &&
    isBefore(new Date(this.props.account.subscriptionEndDate), new Date());

  getIsTrialExpired = () =>
    this.props.account.trialEndDate &&
    isBefore(new Date(this.props.account.trialEndDate), new Date());

  render() {
    if (!this.props.account) {
      return null;
    }

    if (this.getIsTrialExpired()) {
      return (
        <Box mb={8}>
          <Alert kind="warning">
            <FormattedMessage
              {...messages.trialExpiredNotice}
              values={{
                email: (
                  <AlertLink href="mailto:upgrade@workpath.com">
                    upgrade@workpath.com
                  </AlertLink>
                ),
              }}
            />
          </Alert>
        </Box>
      );
    }

    if (this.getIsSubscriptionExpired()) {
      return (
        <Box mb={8}>
          <Alert kind="warning">
            <FormattedMessage
              {...messages.subscriptionExpiredNotice}
              values={{
                email: (
                  <AlertLink href="mailto:support@workpath.com">
                    support@workpath.com
                  </AlertLink>
                ),
              }}
            />
          </Alert>
        </Box>
      );
    }

    return null;
  }
}

AccountNotifications.propTypes = {
  account: PropTypes.shape({
    subscriptionEndDate: PropTypes.string,
    trialEndDate: PropTypes.string,
  }),
};

export default AccountNotifications;
