import {
  SEARCH_REQUEST,
  SEARCH_END,
  RESET_SEARCH,
  RESET_STATE,
} from './constants';

export const initialState = {
  searchPending: false,
  searchUsersError: undefined,
  searchTeamsError: undefined,
  searchGoalsError: undefined,
  users: undefined,
  teams: undefined,
  goals: undefined,
};

function navBarReducer(state = initialState, action) {
  switch (action.type) {
    case SEARCH_REQUEST:
      return {
        ...state,
        searchPending: true,
      };
    case SEARCH_END: {
      const {
        users,
        teams,
        goals,
        searchUsersError,
        searchTeamsError,
        searchGoalsError,
      } = action;

      return {
        ...state,
        searchPending: false,
        ...(users && { users: users.slice(0, 5) }),
        ...(teams && { teams: teams.slice(0, 5) }),
        ...(goals && { goals: goals.slice(0, 5) }),
        ...(searchUsersError && { searchUsersError }),
        ...(searchTeamsError && { searchTeamsError }),
        ...(searchGoalsError && { searchGoalsError }),
      };
    }

    case RESET_SEARCH:
      return initialState;

    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

export default navBarReducer;
