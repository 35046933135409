import { defineMessages } from 'react-intl';

export default defineMessages({
  en: {
    id: 'app.containers.LocaleToggle.en',
    defaultMessage: 'English',
  },
  de: {
    id: 'app.containers.LocaleToggle.de',
    defaultMessage: 'Deutsch',
  },
});
