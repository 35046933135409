import { css, createGlobalStyle } from 'styled-components';

import { colors, shadows } from 'utils/style';

const type = backgroundColor => css`
  background-color: ${backgroundColor};

  &.place-top {
    &::after {
      border-top-color: ${backgroundColor};
      border-top-style: solid;
      border-top-width: 6px;
    }
  }

  &.place-bottom {
    &::after {
      border-bottom-color: ${backgroundColor};
      border-bottom-style: solid;
      border-bottom-width: 6px;
    }
  }

  &.place-left {
    &::after {
      border-left-color: ${backgroundColor};
      border-left-style: solid;
      border-left-width: 6px;
    }
  }

  &.place-right {
    &::after {
      border-right-color: ${backgroundColor};
      border-right-style: solid;
      border-right-width: 6px;
    }
  }
`;

const GlobalStyles = createGlobalStyle`/* stylelint-disable-line max-empty-lines */
  .__react_component_tooltip_hoverable {
    /* allow react-tooltip tooltips to stay open when hovering */
    pointer-events: auto !important;

    &:hover {
      visibility: visible !important;
      opacity: 1 !important;
    }
  }

  .__react_component_tooltip_shadow {
    box-shadow: ${shadows.toolTip};
  }

  .__react_component_tooltip_opaque {
    opacity: 1 !important;
  }

  .__react_component_tooltip_size_medium {
    max-width: 300px;
  }

  .__react_component_tooltip_size_large {
    max-width: 500px;
  }

  .__react_component_tooltip {
    font-weight: normal;
  }

  .__react_component_tooltip.type-success {
    color: #fff;
    ${type(colors.buttonSuccess)};
  }

  .__react_component_tooltip.type-error {
    color: #fff;
    ${type(colors.buttonDanger)};
  }
`;

export default GlobalStyles;
