import React, {
  ReactNode,
  useContext,
  useRef,
  useEffect,
  AriaAttributes,
} from 'react';
import styled, { css } from 'styled-components';
import {
  getColor,
  getFontFamily,
  getFontSize,
  getFontWeight,
} from '../../styles/themeGetters';
import { TabContext } from '../context/TabContext';

type TabButtonProp = {
  isSelected: boolean;
};

// From here on magic numbers for height, border-radius, padding are intentional
// There are no equivalent in the tokens for them which is fine in that case
const SelectedButtonStyle = css`
  font-weight: ${getFontWeight('bold')};
  color: ${getColor('navy')};
  cursor: default;

  :after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${getColor('navy-dark')};
    height: 3px;
    border-radius: 4px;
  }
`;

const StyledTabButton = styled.button<TabButtonProp>`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: ${getFontFamily('lato')};
  font-size: ${getFontSize(16)};
  color: ${getColor('navy')};
  padding: 0 0 5px 0;
  outline: none;
  background: transparent;
  border: 1px solid transparent;
  position: relative;
  cursor: pointer;
  ${({ isSelected }) => isSelected && SelectedButtonStyle};
  display: inline-block;
  align-items: center;

  :hover {
    font-weight: ${getFontWeight('bold')};
  }

  :focus:not(:focus-visible)  {
    outline: 0;
    box-shadow: none;
  }

  :focus-visible {
    outline: 0;
    border: 1px solid ${getColor('blue')};
  }
  }
`;

/**
 * Rendering this (invisible) HoverBuffer solves a problem where the UI would
 * jump when we're bold-ening the text on hover / on select.
 *
 * It renders a bold but invisible version of the text to pre-set the Tab's width.
 * This way we're guaranteed to have enough space for the bold text.
 *
 * See https://stackoverflow.com/a/20249560
 */
const HoverBuffer = styled.span`
  display: block;
  font-weight: bold;
  height: 1px;
  color: transparent;
  overflow: hidden;
  visibility: hidden;
`;

// currentIndex is necessary for Tab but it's passed implicitely
// via cloneElement from Tablist.tsx. In order to prevent TS errors
// because of missing explicit prop I set currentIndex to optional
type TabProps = {
  children: ReactNode;
  currentIndex?: number;
  onSelectTab?: () => void;
};

const Tab = ({
  children,
  currentIndex,
  onSelectTab,
  ...props
}: TabProps & AriaAttributes) => {
  const ref = useRef(null);

  const { selectedTab, setSelectedTab, focusedTab, setFocusedTab } = useContext(
    TabContext
  );
  const isSelected = currentIndex === selectedTab;
  const isFocused = currentIndex === focusedTab;

  useEffect(() => {
    if (isFocused) {
      ref.current.focus();
    }
  }, [isFocused]);

  return (
    <StyledTabButton
      ref={ref}
      aria-selected={isSelected}
      isSelected={isSelected}
      role="tab"
      aria-controls={`tabpanel-${currentIndex}`}
      tabIndex={isSelected ? 0 : -1}
      type="button"
      onClick={() => {
        setSelectedTab(currentIndex);
        setFocusedTab(currentIndex);
        typeof onSelectTab === 'function' && onSelectTab();
      }}
      {...props}
    >
      {children}
      <HoverBuffer>{children}</HoverBuffer>
    </StyledTabButton>
  );
};

export { Tab };
