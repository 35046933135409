/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import { Router, browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import FontFaceObserver from 'fontfaceobserver';
import 'font-awesome/css/font-awesome.css';
import '@fortawesome/fontawesome-pro/css/all.css';
import 'draft-js/dist/Draft.css';
import 'draft-js-mention-plugin/lib/plugin.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BaukastenContext } from 'baukasten';

import theme from 'styles/theme';
// Import root app
import App from 'containers/App';

// Import selector for `syncHistoryWithStore`
import { selectLocationState } from 'containers/App/selectors';

// Import Language Provider
import LanguageProvider from 'containers/LanguageProvider';

// Import GraphQLClientProvider
import { GraphQLClientProvider } from 'utils/datagrid/client/GraphQLClientProvider';

// Load the favicon
/* eslint-disable-next-line import/no-unresolved, import/extensions */
import '!file-loader?name=[name].[ext]!./favicon.png';

import configureStore from './store';

// Import i18n messages
import { translationMessages } from './i18n';

// Import CSS reset and Global Styles
import GlobalStyles from './global-styles';
import './components/ToolTip/globalStyles';
import './components/InputDayPicker/globalStyles';

// Import root routes
import createRoutes from './routes';

// Import sentry.io error tracker

if (
  process.env.NODE_ENV === 'production' &&
  process.env.SENTRY_URL !== 'review'
) {
  require('utils/sentry'); // eslint-disable-line global-require
}

// Observe loading of Lato (to remove lato, remove the <link> tag in
// the index.html file and this observer)
const latoObserver = new FontFaceObserver('Lato', {});

// When Open Sans is loaded, add a font-family using Open Sans to the body
latoObserver.load().then(
  () => {
    document.body.classList.add('fontLoaded');
  },
  () => {
    document.body.classList.remove('fontLoaded');
  }
);

// Create redux store with history
// this uses the singleton browserHistory provided by react-router
// Optionally, this could be changed to leverage a created history
// e.g. `const browserHistory = useRouterHistory(createBrowserHistory)();`
const store = configureStore(browserHistory);

// Sync history and store, as the react-router-redux reducer
// is under the non-default key ("routing"), selectLocationState
// must be provided for resolving how to retrieve the "route" in the state
const history = syncHistoryWithStore(browserHistory, store, {
  selectLocationState: selectLocationState(),
});

history.listen(location => {
  if (window.swUpdate) {
    window.location = location.pathname + location.search;
  }
});

const MOUNT_NODE = document.getElementById('app');

// Set up the router, wrapping all Routes in the App component
const routes = {
  component: App,
  childRoutes: createRoutes(store),
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        if (error?.response?.status === 403) {
          return false;
        }

        if (failureCount === 2) {
          return false;
        }

        return true;
      },
    },
  },
});

const render = messages => {
  ReactDOM.render(
    <Provider store={store}>
      <GlobalStyles />
      <ThemeProvider theme={theme}>
        <LanguageProvider messages={messages}>
          <QueryClientProvider client={queryClient}>
            <GraphQLClientProvider>
              <BaukastenContext.Provider value={{ navigateTo: history.push }}>
                <Router history={history} routes={routes} />
                {process.env.NODE_ENV === 'development' && !window.Cypress && (
                  <ReactQueryDevtools
                    initialIsOpen={false}
                    toggleButtonProps={{ style: { maxWidth: 24 } }}
                    position="bottom-right"
                  />
                )}
              </BaukastenContext.Provider>
            </GraphQLClientProvider>
          </QueryClientProvider>
        </LanguageProvider>
      </ThemeProvider>
    </Provider>,
    MOUNT_NODE
  );
};

// Hot reloadable translation json files
if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', 'containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

render(translationMessages);
