import { CurrentAccount } from 'types/CurrentAccount';
import { CurrentUser } from 'types/CurrentUser';

export type Awaitable<TData> =
  | { loading: true }
  | { loading: false; data: TData };

/**
 * Convert a nullable value into an awaitable.
 * This is intended as a helper to combine WaitForGlobalState with
 * @param data
 */
export const toAwaitable: <TData>(
  data: TData | undefined
) => Awaitable<TData> = data =>
  data ? { loading: false, data } : { loading: true };

// this is now the only place where we access account.features. This is a good thing.
export const accessAccountFeatureFlagMap = (account: CurrentAccount) =>
  account.features;

// this is now the only place where we access user.features. This is a good thing.
export const accessUserFeatureFlags = (user: CurrentUser) => user.features;
