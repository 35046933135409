import React, { ComponentProps, PropsWithChildren } from 'react';
import LibMarkDown from 'markdown-to-jsx';
import styled from 'styled-components';

import { getColor } from '../styles/themeGetters';
import { Text } from '../Text';
import { ExternalLink } from '../Link';

const MarkdownText = styled(Text)<{ fontWeight?: 'bold' }>`
  font-size: 14px;
  color: ${getColor('navy')};
  line-height: 1.4;
  font-weight: ${({ fontWeight }) => fontWeight ?? null};
`;

const MarkdownWrapper = styled(MarkdownText).attrs({ as: 'div' })``;

const MarkdownLink = ({
  href,
  children,
}: PropsWithChildren<{ href: string }>) => {
  return (
    <ExternalLink fontSize={14} showExternalIcon={false} url={href}>
      {children}
    </ExternalLink>
  );
};

const markDownOptions: ComponentProps<typeof LibMarkDown>['options'] = {
  wrapper: MarkdownWrapper,
  forceWrapper: true,
  disableParsingRawHTML: true,
  overrides: {
    a: MarkdownLink,
    h1: {
      component: MarkdownText,
      props: {
        as: 'h1',
        fontWeight: 'bold',
      },
    },
  },
};

type MarkdownProps = {
  mdText: string;
};

export const Markdown = ({ mdText }: MarkdownProps) => {
  return <LibMarkDown options={markDownOptions}>{mdText}</LibMarkDown>;
};
