import styled, { css } from 'styled-components';
import { getBaseUnit, getColor } from '../../styles/themeGetters';
import { hexToRgba } from '../../utils/hexToRgba';

type TRProps = {
  disableHover?: boolean;
  isSelected?: boolean;
};

const selectedAndHoverStyles = css`
  outline: 4px solid ${getColor('yellow')};
  border-radius: ${getBaseUnit(2)};
  z-index: 1;
`;

export const TR = styled.tr<TRProps>`
  box-sizing: border-box;
  position: relative;

  :last-child td:first-child {
    border-bottom-left-radius: ${getBaseUnit(2)};
  }
  :last-child td:last-child {
    border-bottom-right-radius: ${getBaseUnit(2)};
  }
  :nth-child(even) {
    background-color: ${hexToRgba(getColor('grey-light-50'), 0.5)};
  }

  ${props => props.isSelected && selectedAndHoverStyles} :hover {
    ${({ disableHover }) => !disableHover && selectedAndHoverStyles}
  }
`;

TR.defaultProps = {
  disableHover: false,
  isSelected: false,
};
