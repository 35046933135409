import React, { useContext, useRef, useState } from 'react';

import styled from 'styled-components';

import { AvatarGroupProps, AvatarWithId } from './types';
import { BaukastenContext } from '../BaukastenContext';
import { Avatar } from '../Avatar';
import { getBaseUnit, getColor } from '../styles/themeGetters';
import { Box } from '../Box';
import { Text } from '../Text';
import { Menu } from '../Menu';

const AvatarBox = styled.span`
  // as the avatars get rendered in reverse order, every one but
  // the last (a.k.a leftmost) needs to shift 4px to the left to
  // achieve the overlap
  &:not(:last-child) {
    margin-left: -${getBaseUnit(1)};
  }
`;

const PlusN = styled(Box)`
  width: ${getBaseUnit(9)};
  height: ${getBaseUnit(9)};
  display: grid;
  background-color: ${getColor('grey-light-100')};
  border-radius: 50%;
  cursor: pointer;
  p {
    // to make text render in the middle
    margin: auto;
  }
`;

const AvatarOverflow = ({ avatars }: { avatars: AvatarWithId[] }) => {
  const { navigateTo } = useContext(BaukastenContext);

  const [listingOpen, setListingOpen] = useState(false);
  const ref = useRef(null);
  const num = avatars.length;

  return (
    <AvatarBox>
      <PlusN onClick={() => setListingOpen(!listingOpen)} ref={ref}>
        <Text fontSize={10}>+{num}</Text>
      </PlusN>
      <Menu
        anchorEl={ref}
        open={listingOpen}
        onClose={() => setListingOpen(false)}
        maxHeight="376px"
      >
        {avatars.map((avatar, idx) => (
          <Menu.Item
            onSelect={navigateTo && (() => navigateTo(`/users/${avatar.id}`))}
            key={idx}
          >
            <Avatar {...avatar} />
            <Text as="span" ml={2}>
              {avatar.fallbackText}
            </Text>
          </Menu.Item>
        ))}
      </Menu>
    </AvatarBox>
  );
};

export const HorizontalOverlappingAvatars = ({
  avatars,
  truncateAfter = 5,
}: {
  avatars: AvatarWithId[];
} & Pick<AvatarGroupProps, 'truncateAfter'>) => {
  const { navigateTo } = useContext(BaukastenContext);

  const avatarsToRender = avatars.slice(0, truncateAfter);
  const overflowAvatars = avatars.slice(truncateAfter);

  return (
    <Box display="flex">
      <Box display="flex" flexDirection="row-reverse">
        {/* render right to left, so the first element will appear in the back! */}
        {overflowAvatars.length > 0 && (
          <AvatarOverflow avatars={overflowAvatars} />
        )}
        {/* reverse order of avatars, so that the first will show up top and most left */}
        {avatarsToRender.reverse().map((avatar, i) => (
          <AvatarBox key={i}>
            <Avatar
              {...avatar}
              onClick={navigateTo && (() => navigateTo(`/users/${avatar.id}`))}
            />
          </AvatarBox>
        ))}
      </Box>
    </Box>
  );
};
