import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgDeEscalate = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <g clipPath="url(#De-escalate_svg__a)">
      <path
        d="M2.794 16c.211 0 .489-.056.832-.169a9.27 9.27 0 0 0 1.108-.453c.4-.189.792-.399 1.178-.629.39-.225.736-.455 1.037-.69a.743.743 0 0 1 .17-.1.402.402 0 0 1 .147-.03c.047.004.094.007.141.007.047.005.092.007.134.007 1.016 0 1.96-.117 2.83-.353a8.72 8.72 0 0 0 2.341-.997 7.388 7.388 0 0 0 1.778-1.512 6.487 6.487 0 0 0 1.122-1.918A6.224 6.224 0 0 0 16 6.976c0-.967-.207-1.873-.62-2.717a6.874 6.874 0 0 0-1.715-2.218A8.054 8.054 0 0 0 11.118.545 8.81 8.81 0 0 0 8 0a8.81 8.81 0 0 0-3.118.545A8.141 8.141 0 0 0 2.328 2.04 7.007 7.007 0 0 0 .614 4.26 6.146 6.146 0 0 0 0 6.976c0 .818.146 1.596.437 2.332A6.922 6.922 0 0 0 1.7 11.334a8.165 8.165 0 0 0 1.954 1.573c.052.031.085.075.1.13a.196.196 0 0 1-.015.162 7.941 7.941 0 0 1-.466.73c-.178.25-.352.48-.522.69-.164.215-.291.386-.38.514-.137.194-.17.386-.1.575.076.195.25.292.523.292Zm5.22-12.984c.16 0 .29.056.388.169a.61.61 0 0 1 .155.43V7.55L8.501 9.24l.734-.959.853-.944a.482.482 0 0 1 .388-.176c.15 0 .278.056.381.169a.563.563 0 0 1 .155.406c0 .17-.049.307-.148.415l-2.427 2.632c-.136.148-.277.222-.423.222-.146 0-.287-.074-.423-.222L5.164 8.15a.571.571 0 0 1-.148-.415c0-.163.05-.299.148-.406a.497.497 0 0 1 .381-.17c.15 0 .28.06.388.177l.86.944.727.952-.056-1.68V3.613a.61.61 0 0 1 .155-.43.513.513 0 0 1 .395-.168Z"
        fill={_.color}
      />
    </g>
    <defs>
      <clipPath id="De-escalate_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgDeEscalate);
export default ForwardRef;
