import { getRequestV2, postRequestV2 } from 'utils/api/methods';

const getCriteria = () => getRequestV2(`quality_check/criteria`);

const getQualityCheckerResult = (goalIds: string) =>
  getRequestV2(`quality_check?${goalIds}`);

const runQualityChecker = (payload: { goalIds: number[] }) =>
  postRequestV2(`quality_check`, payload);

const qualityChecker = {
  getCriteria,
  getQualityCheckerResult,
  runQualityChecker,
};

export default qualityChecker;
