import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgBusinessUnit = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      d="M8.003 7.834c.813 0 1.607-.046 2.383-.137a15.33 15.33 0 0 0 2.313-.47A14.492 14.492 0 0 0 15 6.353v-.585c0-.742-.161-1.293-.484-1.654-.323-.366-.809-.55-1.459-.55H11.19v-.57c0-.68-.153-1.18-.458-1.503C10.43 1.164 9.995 1 9.43 1H6.57c-.537 0-.964.164-1.283.491-.314.323-.471.824-.471 1.503v.57H2.943c-.646 0-1.132.184-1.459.55-.323.36-.484.912-.484 1.654v.585a14.805 14.805 0 0 0 4.608 1.344c.78.091 1.578.137 2.395.137ZM5.79 3.564v-.65c0-.274.067-.486.201-.635.135-.15.323-.224.566-.224h2.886c.247 0 .438.074.572.224.134.149.201.36.201.635v.65H5.79ZM2.943 15h10.114c.65 0 1.136-.183 1.459-.549.323-.366.484-.918.484-1.654v-5.26A17.213 17.213 0 0 1 11.423 8.6c-.583.1-1.165.175-1.748.224v.585c0 .525-.232.787-.697.787H7.022c-.46 0-.691-.262-.691-.787v-.585A19.983 19.983 0 0 1 4.57 8.6a17.533 17.533 0 0 1-1.767-.42A17.457 17.457 0 0 1 1 7.539v5.259c0 .736.161 1.288.484 1.654.327.366.813.549 1.459.549Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgBusinessUnit);
export default ForwardRef;
