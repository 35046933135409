import styled from 'styled-components';

import { getColor } from '../../styles/themeGetters';

const segmentControlPadding = 4;
const segmentControlGap = 10;

// Hide the radio buttons and switch segments by clicking on the radio labels
export const SegmentedRadioControl = styled.input`
  height: 0;
  width: 0;
  display: block;
  position: absolute;
`;

export const SegmentedControlSlide = styled.div`
  flex: 1;
  height: 28px;
  background: ${getColor('white')};
  transform: ${({ position }: { position: number }) =>
    // The formula "position * 100 + segmentControlGap" helps us move the slider to the correct label
    // based on the selected label ID. The term "position * 100" provides an equal step to switch between labels.
    // The "segmentControlGap" is also calculated because there is a gap between labels.
    `translateX(${position * 100}%) translateX(${position *
      segmentControlGap}px)`};
  position: absolute;
  transition: transform 0.4s ease;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba(66, 68, 90, 0.2);
  top: -${segmentControlPadding}px;
  width: -webkit-fill-available;
  z-index: 1;
`;

export const SegmentedControlContainer = styled.div`
  position: relative;
  background: ${getColor('grey-light-50')};
  padding: ${segmentControlPadding}px;
  border-radius: 4px;
  display: flex;
  align-content: center;
  height: 28px;
  align-items: center;
  width: 100%;
`;

export const SegmentedControlLabel = styled.label`
  position: relative;
  display: block;
  height: 20px;
  text-align: center;
  user-select: none;
  z-index: 2;
  cursor: pointer;

  // move all SegmentedControl labels above the SegmentedControlSlide
  & span {
    position: relative;
    z-index: 2;
  }
`;

export const SegmentedControlGroup = styled.fieldset`
  display: flex;
  gap: ${segmentControlGap}px;
  border: none;
  padding: 0;
  width: 100%;

  & div {
    // make all SegmentedControl labels with the same size
    flex: 1;
  }
`;
