import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgFormatBold = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      d="M4.242 15h4.423c.892 0 1.662-.163 2.31-.488a3.51 3.51 0 0 0 1.495-1.375c.353-.599.53-1.301.53-2.108 0-.637-.127-1.203-.382-1.697a3.068 3.068 0 0 0-2.598-1.708v-.078c.47-.078.883-.26 1.242-.546.36-.286.64-.644.843-1.073.203-.436.304-.91.304-1.425 0-.715-.156-1.333-.469-1.853a3.134 3.134 0 0 0-1.33-1.22C10.038 1.143 9.358 1 8.57 1H4.241c-.393 0-.7.127-.92.38C3.106 1.628 3 1.98 3 2.434v11.142c0 .448.107.8.321 1.053.22.247.528.371.921.371Zm1.208-2.02V8.737h2.433c.851 0 1.503.178 1.955.536.451.358.677.882.677 1.57 0 .69-.22 1.22-.66 1.591-.434.364-1.06.546-1.877.546H5.45Zm0-6.078V3.05h2.424c.666 0 1.185.162 1.555.488.377.325.565.783.565 1.375 0 .625-.211 1.112-.634 1.464-.417.35-1.002.526-1.755.526H5.45Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgFormatBold);
export default ForwardRef;
