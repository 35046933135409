import React from 'react';
import { useIntl } from 'react-intl';

import { ConfidenceLevelIndicator } from '../../../ConfidenceLevelIndicator';
import { Box } from '../../../Box';
import { Text } from '../../../Text';
import { Tooltip } from '../../../Tooltip';
import { RendererProps } from '../RendererProps';
import { Skeleton } from '../../../Skeleton';

import messages from '../../common/messages';
import { HoverBox } from '../../common/HoverBox';
import { TD } from '../../../Table';

type WrapperProps = {
  children: React.ReactNode;
};

const Wrapper = ({ children }: WrapperProps) => (
  <Box height="56px" display="flex" alignItems="center" justifyContent="center">
    {children}
  </Box>
);

const Dash = () => (
  <Wrapper>
    <Text fontSize={14} color="grey-dark">
      -
    </Text>
  </Wrapper>
);

export const ConfidenceLevelRenderer = (
  props: RendererProps<'confidenceLevel'>
) => {
  if (props.loadingState === 'loading') {
    return (
      <TD height="56" width="40" pl={16}>
        <Skeleton />
      </TD>
    );
  }

  const { formatMessage } = useIntl();

  switch (props.cell.entityType) {
    case 'draft_goal_confidence_level':
    case 'draft_key_result_confidence_level':
    case 'initiative_confidence_level':
      return (
        <TD width="56" aria-label="cell confidence level">
          <Dash />
        </TD>
      );

    case 'goal_confidence_level':
    case 'key_result_confidence_level': {
      const { value, showWarning, permissions, entityType } = props.cell;
      const onClick = props.config.onClickCell;

      const applyHoverEffect = entityType === 'key_result_confidence_level';
      const isClickable = applyHoverEffect && permissions.canEdit && value;

      // If the cell is for a goal, we show messages.aggregatedProgressAndCL.
      // if not, we show either a warning text, or prompt a 'click to update tooltip'
      const tooltipContent = applyHoverEffect
        ? showWarning
          ? formatMessage(messages.showWarning)
          : formatMessage(messages.clickToUpdate)
        : formatMessage(messages.aggregatedProgressAndCL);

      return (
        <TD minWidth="56" aria-label="cell confidence level">
          <Wrapper>
            <Tooltip
              maxWidth="280px"
              tooltipContent={tooltipContent}
              show={isClickable ? undefined : false}
            >
              <HoverBox
                applyHoverEffect={isClickable}
                cursor={isClickable ? 'pointer' : undefined}
                onClick={() => isClickable && onClick && onClick(props.cell)}
                aria-label="update"
              >
                <ConfidenceLevelIndicator value={value} />
              </HoverBox>
            </Tooltip>
          </Wrapper>
        </TD>
      );
    }
  }
};
