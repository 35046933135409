import React from 'react';
import ApexCharts from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

type TimeSeriesChartProps = {
  timeSeries: ApexAxisChartSeries;
  options: ApexOptions;
};

export const TimeSeriesChart = ({
  timeSeries,
  options,
}: TimeSeriesChartProps) => (
  <ApexCharts options={options} series={timeSeries} />
);
