import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgValue = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      d="M.503 7.224a.722.722 0 0 0 1.032-.009l2.617-2.719c.063-.064.129-.096.197-.096.063 0 .127.032.19.096L7.15 7.23c.165.177.336.307.511.391.176.084.356.126.541.126.356 0 .707-.173 1.053-.517l3.004-3.052 1.14 1.16c.107.109.22.163.336.163a.482.482 0 0 0 .322-.126.67.67 0 0 0 .204-.354L15.364.677a.5.5 0 0 0-.037-.37.552.552 0 0 0-.255-.251.49.49 0 0 0-.366-.037l-4.29 1.108a.66.66 0 0 0-.351.207.494.494 0 0 0-.124.325.46.46 0 0 0 .16.333l1.133 1.152L8.398 6.07c-.063.069-.129.103-.197.103-.063 0-.127-.034-.19-.103l-2.61-2.726a1.657 1.657 0 0 0-.518-.392 1.228 1.228 0 0 0-1.068-.007c-.17.078-.343.211-.519.399L.498 6.21a.722.722 0 0 0 .005 1.014Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgValue);
export default ForwardRef;
