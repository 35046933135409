import { getRequestV2, postRequestV2 } from '../../methods';

const getAnnouncements = () => getRequestV2('announcements');

const createAnnouncement = payload => postRequestV2('announcements', payload);

const announcement = {
  getAnnouncements,
  createAnnouncement,
};

export default announcement;
