import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgOverflow = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <circle cx={8.333} cy={4.333} fill={_.color} r={1.333} />
    <circle cx={8.333} cy={8.333} r={1.333} fill={_.color} />
    <circle cx={8.333} cy={12.333} fill={_.color} r={1.333} />
  </svg>
);
const ForwardRef = forwardRef(SvgOverflow);
export default ForwardRef;
