import React from 'react';
import styled from 'styled-components';
import { getColor } from '../styles/themeGetters';

type ProgressBarOuterProps = {
  width: string;
};

type ProgressBarInnerProps = {
  value: number;
};

type ProgressBarProps = {
  value?: number;
  width?: string;
};

const ProgressBarOuter = styled.div<ProgressBarOuterProps>`
  background-color: ${getColor('grey-light-100')};
  border-radius: 20px;
  width: ${({ width }) => width};
  height: 6px;
`;

const ProgressBarInner = styled.div<ProgressBarInnerProps>`
  background-color: ${getColor('navy')};
  height: 6px;
  width: ${({ value }) => `${value * 100}%`};
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-right-radius: ${({ value }) => (value > 0.96 ? '3px' : 0)};
  border-bottom-right-radius: ${({ value }) => (value > 0.96 ? '3px' : 0)};
`;

const ProgressBar = ({ value = 0, width = '60px' }: ProgressBarProps) => {
  // The progress bar value must be a number between 0 (no progress) and 1 (100% progress).
  // cappedValue is a safe value that ensures any number outside the valid range will be
  // set accordingly to the lowest or the highest point of the range.
  const cappedValue = Math.min(Math.max(value, 0), 1);

  return (
    <ProgressBarOuter width={width}>
      <ProgressBarInner value={cappedValue} />
    </ProgressBarOuter>
  );
};

export { ProgressBar };
