import { getRequest, patchRequest } from '../../methods';

const updateTeamMemberReport = (teamMemberReportId, payload) =>
  patchRequest(`team_member_reports/${teamMemberReportId}`, payload);

const getKeyResultReferences = teamMemberReportId =>
  getRequest(`team_member_reports/${teamMemberReportId}/key_result_references`);

const teamMemberReports = {
  updateTeamMemberReport,

  getKeyResultReferences,
};

export default teamMemberReports;
