// Docs: https://github.com/WorkpathHQ/workpath_web/wiki/Container-Actions-Docs
import {
  GET_KEY_RESULT_PROGRESS_OVER_TIME_REQUEST,
  GET_KEY_RESULT_PROGRESS_OVER_TIME_SUCCESS,
  GET_KEY_RESULT_PROGRESS_OVER_TIME_ERROR,
} from './constants';

export function getKeyResultProgressOverTimeRequest(keyResultId) {
  return {
    type: GET_KEY_RESULT_PROGRESS_OVER_TIME_REQUEST,
    payload: {
      keyResultId,
    },
  };
}

export function getKeyResultProgressOverTimeSuccess(response) {
  return {
    type: GET_KEY_RESULT_PROGRESS_OVER_TIME_SUCCESS,
    payload: response,
  };
}
export function getKeyResultProgressOverTimeError(error) {
  return {
    type: GET_KEY_RESULT_PROGRESS_OVER_TIME_ERROR,
    payload: error,
    error: true,
  };
}
