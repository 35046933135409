import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'baukasten';
import styled from 'styled-components';

import ToastNotificationContainer from 'containers/ToastNotificationContainer';

import PrimaryContent from './components/PrimaryContent';
import SecondaryContent from './components/SecondaryContent';
import Footer from './components/Footer';
import { withData } from './withData';

const PrimaryContentContainer = styled(Box)`
  // full-size primary content on mobile devices
  @media only screen and (max-width: 940px) {
    width: 100%;
  }
`;

const SecondaryContentContainer = styled(Box)`
  overflow: hidden;

  // hide secondary content on mobile devices
  @media only screen and (max-width: 940px) {
    display: none;
  }
`;

export const NonAuthenticatedAppLayout = ({
  header,
  children,
  publicAccountData,
  locale,
  hideSecondaryContent = false,
}) => (
  <Box height="100%">
    <ToastNotificationContainer />

    {header}

    <Box display="flex" alignItems="stretch" height="100vh">
      {!hideSecondaryContent && (
        <SecondaryContentContainer
          width="40%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          backgroundColor="navy"
        >
          <SecondaryContent />
        </SecondaryContentContainer>
      )}

      <PrimaryContentContainer
        width={hideSecondaryContent ? '100%' : '60%'}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        pl={8}
        pr={8}
      >
        <PrimaryContent publicAccountData={publicAccountData} locale={locale}>
          {children}
        </PrimaryContent>

        <Footer publicAccountData={publicAccountData} locale={locale} />
      </PrimaryContentContainer>
    </Box>
  </Box>
);

NonAuthenticatedAppLayout.propTypes = {
  header: PropTypes.node,
  children: PropTypes.node,
  publicAccountData: PropTypes.object,
  locale: PropTypes.string,
  hideSecondaryContent: PropTypes.bool,
};

export default withData(NonAuthenticatedAppLayout);
