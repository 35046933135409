import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgTalkingPoint = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      d="M3.184 16c.959 0 2.89-.982 4.374-2.118C12.395 13.948 16 10.92 16 6.948 16 3.1 12.442 0 8 0S0 3.1 0 6.948c0 2.412 1.367 4.574 3.483 5.724a18.744 18.744 0 0 1-1.054 1.767c-.483.689-.13 1.561.755 1.561Zm.884-1.693c-.102.044-.143-.007-.068-.11.32-.432.823-1.136 1.088-1.664.211-.418.123-.784-.32-1.011-2.026-1.034-3.224-2.69-3.224-4.574 0-2.91 2.864-5.284 6.456-5.284 3.585 0 6.449 2.374 6.449 5.284 0 2.917-2.864 5.285-6.449 5.285-.116 0-.293 0-.503-.008-.3 0-.517.088-.796.33-.81.652-2.034 1.495-2.633 1.752Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgTalkingPoint);
export default ForwardRef;
