/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot reloadable.
 */

import { combineReducers } from 'redux';
import { LOCATION_CHANGE } from 'react-router-redux';

import {
  generalReducer,
  accountReducer,
  userReducer,
  cyclesReducer,
} from 'containers/App/reducer';
import confirmationReminderContainerReducer from 'containers/ConfirmationReminderContainer/reducer';
import toastNotificationReducer from 'containers/ToastNotificationContainer/reducer';
import profileInputReducer from 'containers/ProfileInput/reducer';
import navBarReducer from 'containers/NavBarContainer/reducer';
import nestedTeamListReducer from 'containers/NestedTeamList/reducer';
import goalProgressOverTimeModalReducer from 'containers/GoalProgressOverTimeModal/reducer';
import keyResultProgressOverTimeModalReducer from 'containers/KeyResultProgressOverTimeModal/reducer';
import appLayoutReducer from 'containers/AppLayout/reducer';

/*
 * routeReducer
 *
 * The reducer merges route location changes into our state.
 * The change is necessitated by moving to react-router-redux@4
 *
 */

// Initial routing state
const routeInitialState = {
  locationBeforeTransitions: null,
};

/**
 * Merge route into the global application state
 */
function routeReducer(state = routeInitialState, action) {
  switch (action.type) {
    /* istanbul ignore next */
    case LOCATION_CHANGE:
      return {
        ...state,
        locationBeforeTransitions: action.payload,
      };

    default:
      return state;
  }
}

/**
 * Creates the main reducer with the asynchronously loaded ones
 */
export default function createReducer(asyncReducers) {
  return combineReducers({
    route: routeReducer,

    toastNotification: toastNotificationReducer,
    confirmationReminder: confirmationReminderContainerReducer,
    profileInput: profileInputReducer,
    navBar: navBarReducer,
    nestedTeamList: nestedTeamListReducer,
    general: generalReducer,
    goalProgressOverTimeModal: goalProgressOverTimeModalReducer,
    keyResultProgressOverTimeModal: keyResultProgressOverTimeModalReducer,
    appLayout: appLayoutReducer,

    app: combineReducers({
      account: accountReducer,
      user: userReducer,
      cycles: cyclesReducer,
    }),

    ...asyncReducers,
  });
}
