import { Matcher } from './types';

// show every item that at least one of these characteristics:
// * Row’s owner is one of the user’s teams
// * Row’s team is one of the user’s teams
// * Row’s owner is the user

export const matchTeam: Matcher = (row, props) => {
  if (row.entityType === 'goal_grouping') {
    return false;
  }
  const { owner, team } = row.data;
  if (owner.entityType !== 'owner_unassigned') {
    if (owner.id === props.user.id) {
      return true;
    }
    if (props.teams.isMember.includes(owner.id)) {
      return true;
    }
  }
  if (team && team.entityType === 'team') {
    if (props.teams.isMember.includes(team.id)) {
      return true;
    }
  }
  return false;
};
