import { css } from 'styled-components';

const textStyles = {};

textStyles.uppercase = css`
  letter-spacing: 1px;
  font-weight: bold;
  text-transform: uppercase;
`;

export default textStyles;
