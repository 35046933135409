import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgInProgress = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14Zm.65-10.5a.65.65 0 0 0-1.3 0v4.312l2.744 2.196a.65.65 0 0 0 .812-1.016L8.65 8.188V4.5Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgInProgress);
export default ForwardRef;
