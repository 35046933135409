/**
 *
 * App.react.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as Sentry from '@sentry/browser';
import { pendoService } from 'utils/pendoService';

import auth from 'utils/auth';
import { getSubdomain } from 'utils/browser';

import AppLoadingState from 'components/AppLoadingState';

import { legacyHasAccountFeatureFlagActive } from 'utils/hooks/globalState';
import ErrorState from './components/ErrorState';
import { getPendoTrackingUrl } from './utils';

import {
  getAccountRequest,
  getCustomCycleRequest,
  getCyclesRequest,
  getProfileRequest,
  getTeamsRequest,
} from './actions';
import {
  selectCurrentAccount,
  selectCurrentUser,
  selectCycles,
  selectGetAccountError,
  selectGetCyclesPending,
  selectGetProfileError,
  selectIsLead,
  selectIsMember,
} from './selectors';

export { default as ErrorState } from './components/ErrorState';

export class App extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      javascriptError: null,
      isPendoInitialized: false,
    };
  }

  componentDidMount() {
    const { getAccountError, getProfileError, cycles } = this.props;

    if (getAccountError || getProfileError) {
      return;
    }

    // fetch data (when logged in)
    if (this.isLoggedInAndMissingData()) {
      this.props.getAccount();
      this.props.getProfile();
      this.props.getTeams();

      if (!cycles) {
        const customCycleId = Number(this.props.location?.query?.cycleId);

        if (customCycleId) {
          this.props.getCustomCycle(customCycleId);
        } else {
          this.props.getCycles();
        }
      }
    }

    pendoService().initialize({
      excludeAllText: true,
      visitor: {},
      account: {},
    });

    this.setState({ isPendoInitialized: true });
  }

  componentDidUpdate() {
    const { currentUser, currentAccount, isMember, isLead } = this.props;
    const isAnonymisePendoActive = legacyHasAccountFeatureFlagActive(
      currentAccount,
      'anonymisePendo'
    );
    const visitorEmail = isAnonymisePendoActive ? null : currentUser?.email;

    if (currentUser) {
      // Sentry
      // Redundantly set just like in app/containers/App/sagas.js
      const subdomain = getSubdomain();
      Sentry.setUser({ id: `${subdomain}_${currentUser.id}` });

      // Pendo
      if (
        this.state.isPendoInitialized &&
        currentAccount &&
        isMember &&
        isLead
      ) {
        pendoService().identify({
          visitor: {
            id: `${currentAccount.id}#${currentUser.id}`,
            email: visitorEmail,
            language_workpath: currentUser.locale,
            is_coach: currentUser.coach,
            is_super_admin: currentUser.superAdmin,
            is_program_lead: currentUser.programLead,
            is_lead: !!isLead.length,
            is_member: !!isMember.length,
            is_viewer: currentUser.viewer,
            is_analyst: currentUser.analyst,
            leader: currentUser.leader,
          },

          account: {
            id: currentAccount.id,
            name: currentAccount.name,
            subdomain: currentAccount.subdomain,
            subscription_end_date: currentAccount.subscriptionEndDate,
            trial_end_date: currentAccount.trialEndDate,
            demo: currentAccount.demo,
            sst: currentAccount.sst,
            ct: currentAccount.ct,
            p: currentAccount.p,
          },
        });

        // Changing the goalroom URL for Pendo
        // The condition is present because without it we cannot prevent potential issues when pendoService().location is undefined
        // This is needed for example for the E2E tests to pass
        if (pendoService()?.location) {
          pendoService().location.setUrl(
            getPendoTrackingUrl(window.location.href)
          );
        }
      }
    }
  }

  componentDidCatch(error, info) {
    this.setState({ javascriptError: error });
    Sentry.withScope(scope => {
      scope.setExtra(info);
      Sentry.captureException(error);
    });
  }

  isLoggedInAndMissingData() {
    const { currentAccount, currentUser, cycles } = this.props;
    return auth.isLoggedIn() && (!currentUser || !currentAccount || !cycles);
  }

  render() {
    const { getAccountError, getProfileError } = this.props;
    const { javascriptError } = this.state;

    if (getAccountError || getProfileError || javascriptError) {
      return (
        <ErrorState
          getAccountError={getAccountError}
          getProfileError={getProfileError}
          javascriptError={javascriptError}
        />
      );
    }

    if (this.isLoggedInAndMissingData()) {
      return <AppLoadingState />;
    }

    return this.props.children;
  }
}

App.propTypes = {
  // mapDispatchToProps
  getAccount: PropTypes.func.isRequired,
  getProfile: PropTypes.func.isRequired,
  getCycles: PropTypes.func.isRequired,
  getCustomCycle: PropTypes.func.isRequired,
  getTeams: PropTypes.func.isRequired,

  // mapStateToProps
  currentUser: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    locale: PropTypes.string,
    coach: PropTypes.bool,
    superAdmin: PropTypes.bool,
    programLead: PropTypes.bool,
    viewer: PropTypes.bool,
    leader: PropTypes.bool,
    analyst: PropTypes.bool,
  }),
  currentAccount: PropTypes.shape({
    id: PropTypes.number,
    subdomain: PropTypes.string,
    name: PropTypes.string,
    subscriptionEndDate: PropTypes.string,
    trialEndDate: PropTypes.string,
    demo: PropTypes.bool,
    sst: PropTypes.string,
    ct: PropTypes.string,
    p: PropTypes.string,
    features: PropTypes.shape({
      anonymisePendo: PropTypes.string,
    }),
  }),
  getAccountError: PropTypes.object,
  getProfileError: PropTypes.object,
  cycles: PropTypes.array,
  isMember: PropTypes.array,
  isLead: PropTypes.array,

  // router
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    query: PropTypes.shape({
      cycleId: PropTypes.string,
    }),
  }).isRequired,

  // children
  children: PropTypes.node.isRequired,
};

const mapStateToProps = createStructuredSelector({
  currentAccount: selectCurrentAccount,
  getAccountError: selectGetAccountError,

  currentUser: selectCurrentUser,
  getProfileError: selectGetProfileError(),

  cycles: selectCycles,
  getCyclesPending: selectGetCyclesPending,
  isMember: selectIsMember,
  isLead: selectIsLead,
});

export function mapDispatchToProps(dispatch) {
  return {
    getAccount: () => dispatch(getAccountRequest()),
    getProfile: () => dispatch(getProfileRequest()),
    getCycles: () => dispatch(getCyclesRequest()),
    getCustomCycle: customCycleId =>
      dispatch(getCustomCycleRequest(customCycleId)),
    getTeams: () => dispatch(getTeamsRequest()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
