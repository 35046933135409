import { call, put, all, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import * as Sentry from '@sentry/browser';
import { intlService } from 'utils/intlService';
import { getSubdomain } from 'utils/browser';

import api from 'utils/api';
import auth from 'utils/auth';

import {
  createConfirmationReminderToast,
  createErrorToast,
  createSuccessToast,
  clearAllToasts,
} from 'containers/ToastNotificationContainer/actions';
import messages from './messages';

import {
  GET_ACCOUNT_REQUEST,
  GET_PROFILE_REQUEST,
  UPDATE_PROFILE_REQUEST,
  CREATE_USER_SESSION,
  DESTROY_USER_SESSION,
  USER_LOGOUT_REQUEST,
  GET_CYCLES_REQUEST,
  GET_CUSTOM_CYCLE_REQUEST,
  GET_TEAMS_REQUEST,
} from './constants';
import {
  getAccountRequest,
  getAccountSuccess,
  getAccountError,
  getProfileSuccess,
  getProfileError,
  updateProfileSuccess,
  updateProfileError,
  destroyUserSession as destroyUserSessionAction,
  userLogoutSuccess,
  userLogoutError,
  getCyclesRequest,
  getCyclesSuccess,
  getCustomCycleSuccess,
  getCustomCycleError,
  getCyclesError,
  getTeamsRequest,
  getTeamsSuccess,
  getTeamsError,
} from './actions';

// Individual exports for testing
export function* getAccount() {
  try {
    const response = yield call(api.getAccountV2);
    yield put(getAccountSuccess(response.account));
  } catch (err) {
    yield put(getAccountError(err));
  }
}

export function* getProfile() {
  try {
    const response = yield call(api.getProfile);
    yield put(getProfileSuccess(response));
    if (!response.confirmedAt) {
      yield put(createConfirmationReminderToast());
    }
  } catch (err) {
    yield put(getProfileError(err));
  }
}

export function* updateProfile({ payload }) {
  try {
    const response = yield call(api.updateProfile, { user: payload });
    yield put(updateProfileSuccess(response));
  } catch (err) {
    yield put(updateProfileError(err));
  }
}

export function* createUserSession({ user, token, nextPathname }) {
  auth.storeToken(token);

  yield put(getAccountRequest());
  yield put(getCyclesRequest());
  yield put(getTeamsRequest());
  yield put(clearAllToasts());
  yield put(push(nextPathname || '/'));

  if (!user.confirmedAt) {
    yield put(createConfirmationReminderToast());
  }

  const subdomain = getSubdomain();

  // Redundantly set also in App/index.js
  Sentry.setUser({ id: `${subdomain}_${user.id}` });
}

export function* destroyUserSession() {
  auth.removeToken();
  window.location.replace(`${window.location.origin}/login`);
  Sentry.configureScope(scope => scope.setUser(null));
}

export function* logoutUser() {
  try {
    const response = yield call(api.logoutUser);
    yield put(userLogoutSuccess(response));
    yield put(destroyUserSessionAction());
  } catch (err) {
    yield put(userLogoutError(err));
    yield put(createErrorToast(err.message));
  }
}

export function* getCycles() {
  try {
    const response = yield call(api.getCyclesRequest);
    yield put(getCyclesSuccess(response));
  } catch (err) {
    yield put(getCyclesError(err));
    yield put(createErrorToast(err.message));
  }
}

export function* getCustomCycle({ customCycleId }) {
  try {
    const response = yield call(api.getCyclesRequest);
    const customCycle = response.cycles.find(
      cycle => cycle.id === customCycleId
    );

    if (customCycle) {
      yield put(getCustomCycleSuccess(response, customCycleId));
      yield put(
        createSuccessToast(
          intlService().formatMessage(messages.customCycleTitle, {
            cycleTitle: customCycle.title,
          })
        )
      );
    } else {
      const currentCycle = response.cycles.find(cycle => cycle.current);
      yield put(getCustomCycleSuccess(response, currentCycle.id));
      yield put(
        createErrorToast(intlService().formatMessage(messages.customCycleError))
      );
    }
  } catch (err) {
    yield put(getCustomCycleError(err));
    yield put(createErrorToast(err.message));
  }
}

export function* getTeams() {
  try {
    const response = yield call(api.getTeams);
    yield put(getTeamsSuccess(response));
  } catch (err) {
    yield put(getTeamsError(err));
  }
}

export function* watcher() {
  yield all([
    takeLatest(GET_ACCOUNT_REQUEST, getAccount),
    takeLatest(GET_PROFILE_REQUEST, getProfile),
    takeLatest(UPDATE_PROFILE_REQUEST, updateProfile),

    takeLatest(CREATE_USER_SESSION, createUserSession),
    takeLatest(DESTROY_USER_SESSION, destroyUserSession),

    takeLatest(USER_LOGOUT_REQUEST, logoutUser),

    takeLatest(GET_CYCLES_REQUEST, getCycles),
    takeLatest(GET_CUSTOM_CYCLE_REQUEST, getCustomCycle),
    takeLatest(GET_TEAMS_REQUEST, getTeams),
  ]);
}

export default [watcher];
