const shadows = {};

shadows.menuBox =
  '0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)';
shadows.box = '0 1px 4px 0 rgba(0, 0, 0, 0.13)';
shadows.toolTip = '0 2px 14px 4px rgba(0, 0, 0, 0.07)';
shadows.container = '0 0 .125rem .0625rem rgba(0, 0, 0, .02)';
shadows.containerDark = '0 0 .125rem .0625rem rgba(0, 0, 0, .05)';
shadows.input = '0 0 7px rgba(39, 128, 248, .15)';
shadows.inputCheckBox = '0 0 5px rgba(56, 169, 240, 0.75)';
shadows.teamMemberReportStatus = '0 2px 4px 0 rgba(0, 0, 0, 0.13)';
shadows.teamMemberReportAnswerFocus = '0 2px 10px 0 rgba(0, 0, 0, 0.13)';

export default shadows;
