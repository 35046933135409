import React from 'react';
import styled from 'styled-components';

import { getColor, getBaseUnit, getElevations } from '../styles/themeGetters';
import { ColorKeys, ElevationKeys } from '../styles/themes';

const getBorderRadius = (round, borderRadius) => {
  if (round) return getBaseUnit(1);

  return getBaseUnit(borderRadius);
};

const Box = styled.div<BoxProps>`
  box-sizing: border-box;
  margin: 0;
  margin-top: ${({ mt }) => getBaseUnit(mt)};
  margin-bottom: ${({ mb }) => getBaseUnit(mb)};
  margin-right: ${({ mr }) => getBaseUnit(mr)};
  margin-left: ${({ ml }) => getBaseUnit(ml)};
  position: ${({ position }) => position};

  /* Style */
  background-color: ${({ backgroundColor }) => getColor(backgroundColor)};
  border-top: ${({ borderPosition }) =>
    borderPosition && borderPosition.includes('top') && '1px solid'};
  border-bottom: ${({ borderPosition }) =>
    borderPosition && borderPosition.includes('bottom') && '1px solid'};
  border-left: ${({ borderPosition }) =>
    borderPosition && borderPosition.includes('left') && '1px solid'};
  border-right: ${({ borderPosition }) =>
    borderPosition && borderPosition.includes('right') && '1px solid'};
  border: ${({ borderPosition }) =>
    borderPosition && borderPosition.includes('all') && '1px solid'};

  border-color: ${({ borderPosition, borderColor }) =>
    borderPosition && getColor(borderColor)};

  border-radius: ${({ borderRadius, round }) =>
    getBorderRadius(round, borderRadius)};

  /* Dimensions */
  height: ${({ height }) => height};
  min-height: ${({ minHeight }) => minHeight};

  width: ${({ width }) => width};
  min-width: ${({ minWidth }) => minWidth};
  /* max-width: 100%; */

  /* Flex Layout  */
  display: ${({ display }) => display};
  flex-direction: ${({ flexDirection }) => flexDirection};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  gap: ${({ flexGap }) => flexGap};
  flex-basis: ${({ flexBasis }) => flexBasis};

  /* Multiline Behavior */
  flex-wrap: ${({ flexWrap }) => flexWrap};
  align-content: ${({ flexAlignContent }) => flexAlignContent};

  /* Children Flex Layout */
  flex: ${({ flex }) => flex};
  align-self: ${({ alignSelf }) => alignSelf};

  /* Elevations */
  box-shadow: ${({ elevation }) => getElevations(elevation)};

  padding-top: ${({ pt }) => getBaseUnit(pt)};
  padding-right: ${({ pr }) => getBaseUnit(pr)};
  padding-bottom: ${({ pb }) => getBaseUnit(pb)};
  padding-left: ${({ pl }) => getBaseUnit(pl)};

  /* Grid */
  grid-area: ${({ gridArea }) => gridArea};

  /* Cursor */
  cursor: ${({ cursor }) => cursor};

  /* Overflow */
  overflow: ${({ overflow }) => overflow};
`;
type BorderPos = 'top' | 'right' | 'left' | 'bottom' | 'all';
type BoxProps = Partial<{
  alignItems: string;
  alignSelf: string;
  as: string;
  borderColor: ColorKeys;
  borderRadius: number;
  children: React.ReactNode;
  backgroundColor: ColorKeys;
  display: string;
  elevation: ElevationKeys;
  flex: string;
  flexAlignContent: string;
  flexDirection: string;
  flexWrap: string;
  flexGap: string;
  flexBasis: string;
  height: string;
  minHeight: string;
  justifyContent: string;
  mt: number;
  mb: number;
  mr: number;
  ml: number;
  pt: number;
  pr: number;
  pb: number;
  pl: number;
  round: boolean;
  width: string;
  minWidth: string;
  gridArea: string;
  borderPosition: BorderPos[];
  cursor: string;
  overflow?: string;
  position?: string;
}>;

Box.defaultProps = {
  mt: 0,
  mr: 0,
  mb: 0,
  ml: 0,
  pt: 0,
  pr: 0,
  pb: 0,
  pl: 0,
  borderRadius: 0,
  overflow: 'visible',
  position: 'initial',
};

export { Box };
