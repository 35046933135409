import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import messages from './messages';
import { InitiativeStatusIndicator } from '../../../InitiativeStatusIndicator';
import { Box } from '../../../Box';
import { EntityRendererProps } from '../RendererProps';
import {
  InitiativeProgressData,
  DraftInitiativeProgressData,
} from '../../types';
import { Skeleton } from '../../../Skeleton';
import { Tooltip } from '../../../Tooltip';
import { TD } from '../../../Table';
import { getColor } from '../../../styles/themeGetters';

import commonMessages from '../../common/messages';
import { HoverBox } from '../../common/HoverBox';

const DraftStatusBar = styled.div`
  background-color: ${getColor('grey-light-100')};
  border-radius: 20px;
  width: 60px;
  height: 6px;
`;

export const InitiativeStatusRenderer = (
  props: EntityRendererProps<
    'progress',
    InitiativeProgressData | DraftInitiativeProgressData
  >
) => {
  if (props.loadingState === 'loading') {
    return (
      <TD width={128} pl={16} pr={16}>
        <Skeleton />
      </TD>
    );
  }
  const { formatMessage } = useIntl();
  const { value, permissions, entityType } = props.cell;
  const onClick = props.config.onClickCell;

  switch (entityType) {
    case 'draft_initiative_progress':
      return (
        <TD width={128} pl={16} pr={16}>
          <Box height="28px" display="flex" alignItems="center">
            <DraftStatusBar />
          </Box>
        </TD>
      );

    case 'initiative_progress':
      return value ? (
        <TD
          width={128}
          pl={permissions.canEdit ? 0 : 16}
          pr={permissions.canEdit ? 0 : 16}
        >
          <Box height="28px" display="flex" alignItems="center">
            {permissions.canEdit ? (
              <Tooltip
                tooltipContent={formatMessage(commonMessages.clickToUpdate)}
              >
                <HoverBox
                  applyHoverEffect
                  cursor={onClick && 'pointer'}
                  onClick={() => onClick && onClick(props.cell)}
                >
                  <InitiativeStatusIndicator
                    value={value}
                    showLabel
                    label={formatMessage(messages[value])}
                  />
                </HoverBox>
              </Tooltip>
            ) : (
              <InitiativeStatusIndicator
                value={value}
                showLabel
                label={formatMessage(messages[value])}
              />
            )}
          </Box>
        </TD>
      ) : null;
  }
};
