import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgProcessing = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      d="M12.049 18.699c-1.081 0-2.068-.223-2.96-.67a6.268 6.268 0 0 1-2.222-1.87c-.59-.801-.975-1.727-1.156-2.778H4.509c-.265 0-.427-.098-.488-.295-.053-.204-.007-.408.136-.612l1.815-2.551c.158-.227.351-.336.578-.329.227.008.415.117.567.329l1.848 2.551c.143.204.185.408.124.612-.06.197-.222.295-.487.295h-1.19a4.49 4.49 0 0 0 .918 1.814c.446.53.986.949 1.621 1.259.643.31 1.342.465 2.098.465.544 0 1.032-.084 1.462-.25a5.18 5.18 0 0 0 1.27-.737c.22-.166.45-.257.692-.272.25-.015.472.083.669.295.196.211.264.45.204.714-.053.265-.215.507-.488.726a5.568 5.568 0 0 1-1.746.963c-.672.227-1.36.34-2.063.34ZM12.026 6c1.073 0 2.056.227 2.948.68a6.116 6.116 0 0 1 2.222 1.871c.59.794.98 1.716 1.168 2.767h1.134c.264 0 .423.102.476.306.06.196.015.396-.136.6l-1.814 2.563c-.159.227-.352.336-.578.329-.227-.015-.416-.125-.567-.329l-1.837-2.562c-.144-.204-.189-.405-.136-.601.06-.204.223-.306.487-.306h1.259a4.398 4.398 0 0 0-.918-1.803A4.692 4.692 0 0 0 14.1 8.256a4.666 4.666 0 0 0-2.075-.465c-.552 0-1.043.084-1.474.25-.43.166-.85.408-1.258.726-.227.166-.462.26-.703.283-.242.015-.465-.087-.67-.306a.763.763 0 0 1-.203-.703c.053-.272.215-.514.487-.726a5.568 5.568 0 0 1 1.746-.964A6.264 6.264 0 0 1 12.026 6Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgProcessing);
export default ForwardRef;
